import { styled, textAlign } from "@mui/system";

export const DialogCommanStyle = styled("div")(({ theme }) => ({
  ".dialogMain": {
    "&.es": {
      ".fieldValuesTableMain .newFieldAdd": {
        width: "130px",
      },
    },
    ".searchSimilarTitle": {
      backgroundColor: theme.palette.whiteColor.main,
      textAlign: "left",
    },
    ".reassignDiv": {
      ".userRoleText": {
        padding: "15px",
        "p#alert-dialog-description": {
          paddingTop: 0,
        },
      },
    },
    ".reassignDiv.addNewDivSelect": {
      width: "100%",
      maxWidth: "100%",
      ".flexCenter": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "12px",
        fontSize: "18px",
        span: {
          whiteSpace: " pre",
        },
        ".modalSelect": {
          position: "relative",
          top: "-2px",
          width: "100%",
          maxWidth: "350px",
        },
      },
    },
    button: {
      backgroundColor: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      color: theme.palette.white.main,
      boxShadow: "unset",
      fontWeight: "400",
      ":hover": {
        backgroundColor: theme.palette.white.main,
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
      },
      ":is(.signOutButton)": {
        textTransform: "unset!important",
      },
    },
    ".request-common": {
      ".MuiSelect-select": {
        paddingRight: "32px",
        padding: "8.5px 32px 8.5px 15px",
      },
    },
    "&:is(.fr, .it, .es, .de)": {
      ".Csv_main": {
        "&:is(.addEditCategory)": {
          ".commanCsvBlock": {
            ".categoryImageInput": {
              p: { gridRow: "1/4", width: "214px" },
              ".chooseFile": {
                'input[type="file"]': {
                  paddingLeft: "40px",
                },
              },
            },
            ".categoryImageName": { p: { width: "214px" } },
          },
        },
      },
      ".popup-main": {
        "&:is(.AddUserDetails)": {
          ".request-common": {
            "&:is(.fieldValueTable)": {
              ".request-common": {
                gridTemplateColumns: "repeat(1,1fr)",

                ".newFieldAdd": {
                  width: "auto",
                },
              },
            },
          },
        },
      },
    },

    "&:is(.fr, .de, .es, .it)": {
      ".Csv_main": {
        ".commanCsvBlock": {
          ".chooseFile": {
            label: {
              maxWidth: "calc(100% - 112px)",
              paddingLeft: "20px",
            },
          },
        },
        "&:is(.addEditCategory)": {
          ".commanCsvBlock": {
            ".chooseFile": {
              label: {
                top: "8px",
                paddingLeft: "25px",
              },
            },
          },
        },
      },
    },
  },
  ".MuiDialogContent-root": {
    overflowX: "hidden",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  h2: {
    padding: "15px",
    background: "#eaedf4",
    minHeight: "50px",
    ".popupTitle": {
      display: "block",
      whiteSpace: "nowrap",
      width: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      margin: "0 auto",
      maxWidth: "490px",
      color: "#505050",
      lineHeight: "normal",
    },
    div: {
      textAlign: "center",
      fontSize: "24px",
      lineHeight: "1",
      margin: "0",
      padding: "0",
      fontWeight: "bold",
      color: theme.palette.white.main,
      ".popup-icon": {
        position: "absolute",
        right: "4px",
        top: "7px",

        button: {
          background: "transparent",
          svg: {
            path: {
              fill: theme.palette.primary.main,
            },
          },
          ":hover": {
            backgroundColor: theme.palette.white.main,
            borderColor: theme.palette.primary.main,
            color: theme.palette.white.main,
            svg: {
              path: {
                fill: theme.palette.primary.main,
              },
            },
          },
        },
      },
    },
  },

  p: {
    textAlign: "center",
    padding: "30px 0",
    color: theme.palette.black.main,
    fontSize: "18px",
  },
  ".popup-button-outer": {
    justifyContent: "center",
  },
  ".crpeText": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  " .cropBtn": {
    textAlign: "center",
    marginTop: "25px",
  },
  ".dialog_close": {
    zIndex: 2,
    right: "15px",
    position: "absolute",
    top: "15px",
  },
}));
