import { createSlice } from "@reduxjs/toolkit";
import {
  getCuratedGalleryInfoApi,
  updateCuratedGalleryInfoApi,
} from "./curatedgalleryActions";

const initialState = {
  data: null,
  loading: false,
  error: null,
};

const curatedGallerySlice = createSlice({
  name: "curatedGallery",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle fetching curated gallery info
    builder
      .addCase(getCuratedGalleryInfoApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCuratedGalleryInfoApi.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          state.data = action.payload.data;
        } else {
          state.error = action.payload.error;
        }
      })
      .addCase(getCuratedGalleryInfoApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

    // Handle updating curated gallery info
    builder
      .addCase(updateCuratedGalleryInfoApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCuratedGalleryInfoApi.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.success) {
          state.data = action.payload.data;
        } else {
          state.error = action.payload.error;
        }
      })
      .addCase(updateCuratedGalleryInfoApi.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Selector to access curated gallery data from the store
export const curatedGallerySelector = (state) => state.curatedGallery;

// Export the reducer to be used in the store
export default curatedGallerySlice.reducer;
