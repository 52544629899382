import React, { useState } from "react";
import { t } from "i18next";
import { ClickAwayListener } from "@mui/material";
import { StyledContributePopupSaveButton } from "components/Button/ContributePopupSaveButton";
import {
  buttonVariants,
  MainButtonWithChildren,
} from "components/Button/MainButton";
import ArrowUpIcon from "components/Icons/ArrowIcons/ArrowUpIcon";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";
import HexTick from "components/Icons/TickIcon/HexTick";
import ClockIcon from "components/Icons/ClockIcon/ClockIcon";
import TickIcon from "components/Icons/TickIcon/TickIcon";

export const saveButtonIds = {
  default: "default",
  publish: "publish",
  draft: "draft",
};

const buttonTitleOptions = [
  {
    name: "selectAction",
    id: saveButtonIds.default,
    state: "default",
    body: () => (
      <>
        <HexTick /> <p>{t("selectAnAction")}</p>
      </>
    ),
  },
  {
    name: "",
    id: saveButtonIds.publish,
    state: "publish",
    body: () => (
      <>
        <TickIcon /> <p>{t("publish")}</p>
      </>
    ),
  },
  {
    name: "saveForDraft",
    id: saveButtonIds.draft,
    state: "draft",
    body: () => (
      <>
        <ClockIcon /> <p>{t("scheduleToPublishLater")}</p>
      </>
    ),
  },
];

const UploadSaveButton = ({
  disableSave,
  props,
  onButtonClick,
  setPublishConfirmPopup,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [buttonTitle, setButtonTitle] = useState(buttonTitleOptions[0]);

  const handleSaveButtonClick = (e) => {
    e.preventDefault();

    if (buttonTitle?.id === saveButtonIds?.default) {
      setOpenOptions(true);
      return;
    }

    if (buttonTitle?.id === saveButtonIds?.publish) {
      setPublishConfirmPopup(true);
      return;
    }

    onButtonClick(buttonTitle);
  };

  return (
    <StyledContributePopupSaveButton
      className={`contributeScreenSave ${disableSave ? "disableSave" : ""}`}
    >
      {openOptions && (
        <ClickAwayListener
          onClickAway={() => {
            setOpenOptions(false);
          }}
        >
          <div className="contributeSaveButtonOptions">
            {buttonTitleOptions?.map(
              (data, index) =>
                data.id !== saveButtonIds?.default && (
                  <div
                    className="contributeSaveOption"
                    onClick={() => {
                      setButtonTitle(data);
                      setOpenOptions(false);
                    }}
                    key={index}
                  >
                    {data.body()}
                  </div>
                ),
            )}
          </div>
        </ClickAwayListener>
      )}
      <div className="createProjectBtn">
        <MainButtonWithChildren
          disabled={disableSave}
          variant={buttonVariants.blue}
          onClick={handleSaveButtonClick}
          {...props}
        >
          {buttonTitle?.body()}
        </MainButtonWithChildren>
        <button
          onClick={(e) => {
            e.preventDefault();
            setOpenOptions((prev) => !prev);
          }}
          className={"contributeSaveButtonArrow"}
          disabled={disableSave}
        >
          {openOptions ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </button>
      </div>
    </StyledContributePopupSaveButton>
  );
};

export default UploadSaveButton;
