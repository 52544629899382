import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const getCuratedGalleryInfoApi = createAsyncThunk(
  "curatedGallery/getCuratedGalleryInfo",
  async () => {
    try {
      const response = await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getCuratedGalleryInfo),
      );
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error.response?.data || error.message };
    }
  },
);

export const updateCuratedGalleryInfoApi = createAsyncThunk(
  "curatedGallery/updateCuratedGalleryInfo",
  async (body) => {
    try {
      const response = await performApiRequest(() =>
        axiosConfig.put(apiRoutes.updateCuratedGalleryInfo, body),
      );
      return { success: true, data: response.data };
    } catch (error) {
      return { success: false, error: error.response?.data || error.message };
    }
  },
);
