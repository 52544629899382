import React, { Fragment, isValidElement, useEffect, useState } from "react";
import { ImageList, ImageListItem, Modal, Box, Popover } from "@mui/material";
// import { useDropzone } from "react-dropzone";
import NoImageSvg from "components/Icons/NoImageIcon/noImageSvg";
import UploadImageSvg from "components/Icons/UploadImageIcon/uploadImageSvg";
import TextTag, { textVariants } from "components/Typography/TextTag";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import CartIcon from "components/Icons/CartIcon/cartIcon";
import Copy2Icon from "components/Icons/CopyIcon/Copy2Icon";
import FullScreenIcon from "components/Icons/FullScreenIcon/FullScreenIcon";
import ThreeDots from "components/Icons/ThreeDots/ThreeDots";
import staticDocImg from "../../../../../Assets/doctemplogo.jpg";
import DownloadStrokeIcon from "components/Icons/DownloadIcon/DownloadStrokeIcon";
import LinkIcon from "components/Icons/LinkIcon/LinkIcon";
import CrossIcon from "components/Icons/CrossIcon/CrossIcon";
import UploadAssetsBlock from "./DisplayFileStyle";
import NotFoundImage from "../../../UserPages/home/HomeComponents/FindLookingForSection/images/imageNotFound.png";
import useWindowDimensions from "helper/getScreenDimensions";
import { useNavigate } from "react-router-dom";
import { routeConfigs } from "utils/routeConfig";
import AddToBoardIcon from "components/Icons/AddToBoardIcon/AddToBoardIcon";
import { t } from "i18next";

const intitalValues = [[<UploadImageSvg />], [<NoImageSvg />, <NoImageSvg />]];

function HoverButtonBlock({
  setPopUpImage,
  file,
  filesLength,
  setButtonPopOver,
  setAnchorEl,
  buttonPopOver,
}) {
  const topHoverIcons = [
    { icon: AddToBoardIcon, onClick: () => {} },
    { icon: CartIcon, onClick: () => {} },
    { icon: Copy2Icon, onClick: () => {} },
    // { icon: InfoIcon, onClick: () => { } },
    { icon: FullScreenIcon, onClick: setPopUpImage },
  ];
  const bottomHoverIcons = [
    { icon: DownloadStrokeIcon, onClick: () => {} },
    { icon: LinkIcon, onClick: () => {} },
    { icon: Copy2Icon, onClick: () => {} },
  ];
  const openPopOverItems = (e) => {
    setButtonPopOver(file);
    setAnchorEl(e.currentTarget);
    return;
  };

  return (
    <Fragment key={file?.id}>
      <div className="topBarIcons">
        {filesLength === 4 ? (
          <ButtonWithFrontIcon
            className={`backButtonClass ${
              buttonPopOver && file?.id === buttonPopOver?.id
                ? "threeDotOpen"
                : ""
            }`}
            onClick={openPopOverItems}
          >
            <ThreeDots />
          </ButtonWithFrontIcon>
        ) : (
          topHoverIcons?.map(({ icon, onClick }, index) => (
            <ButtonWithFrontIcon
              key={index}
              icon={icon}
              className={`backButtonClass ${index === 3 ? "fullScreen" : ""}`}
              onClick={onClick}
            />
          ))
        )}
      </div>

      {filesLength !== 4 ? (
        <div className="uploadImageDetailBar">
          <div className="uploadImageName">
            <TextTag
              variant={textVariants._14px.semiBold}
              className="imageName"
            >
              {file?.name}
            </TextTag>
          </div>
          <div className="infoIcongBlock">
            {bottomHoverIcons?.map(({ icon, onClick }, idx) => (
              <ButtonWithFrontIcon
                key={idx}
                icon={icon}
                className="backButtonClass"
                onClick={onClick}
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="uploadImageDetailBar">
          <div className="uploadImageName">
            <TextTag
              variant={textVariants._14px.semiBold}
              className="imageName"
            >
              {file?.name?.length > 70
                ? file?.name.substring(0, 70) + "..."
                : file?.name}
            </TextTag>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const PopOverButtonsSection = ({
  setPopUpImage,
  handleClose,
  id,
  file,
  popOverId,
  anchorEl,
}) => {
  const fileThumbnail = file?.thumbnailUrl ? file?.thumbnailUrl : staticDocImg;

  const popOverIcons = [
    {
      icon: FullScreenIcon,
      onClick: () => setPopUpImage(fileThumbnail),
      labelName: "Full Width",
    },
    { icon: AddToBoardIcon, onClick: () => {}, labelName: "Like" },
    { icon: CartIcon, onClick: () => {}, labelName: "Add To Cart" },
    { icon: Copy2Icon, onClick: () => {}, labelName: "Similar" },
    { icon: DownloadStrokeIcon, onClick: () => {}, labelName: "Download" },
    // { icon: InfoIcon, onClick: () => {} },
    { icon: LinkIcon, onClick: () => {}, labelName: "Share" },
    { icon: Copy2Icon, onClick: () => {}, labelName: "Copy Link" },
  ];
  const open = !!anchorEl;
  return (
    <Popover
      id={popOverId}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <div className="popupGrid">
        {popOverIcons?.map(({ icon, onClick, labelName }, index) => (
          <ButtonWithFrontIcon
            key={index}
            icon={icon}
            className="backButtonClass"
            onClick={onClick}
          >
            <TextTag variant={textVariants._14px.medium} onClick={handleClose}>
              {" "}
              {labelName}
            </TextTag>
          </ButtonWithFrontIcon>
        ))}
      </div>
    </Popover>
  );
};

const ContributionImagesPopUp = ({ fileThumbnail, handleClose }) => {
  const open = !!fileThumbnail;

  return (
    <Box
      sx={{
        height: 300,
        flexGrow: 1,
        minWidth: 300,
        transform: "translateZ(0)",
      }}
    >
      <Modal
        open={!!open}
        onClose={handleClose}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        className="displaySectionPopUp"
      >
        <Box
          sx={(theme) => ({
            position: "relative",
            // bgcolor: "background.paper",
            // boxShadow: theme.shadows[5],
          })}
        >
          <div className="showcaseImagePopup">
            <ButtonWithFrontIcon
              icon={CrossIcon}
              className="backButtonClass"
              variant="contained"
              onClick={handleClose}
            />
            <img
              src={fileThumbnail}
              srcSet={fileThumbnail}
              alt={fileThumbnail}
            />
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

const patterns = [
  [3, 2, 4, 3],
  [3, 4, 2, 3],
  [4, 3, 2, 3],
  [4, 3, 3, 2],
  [2, 4, 3, 3],
  [3, 3, 4, 2],
  [4, 2, 3, 3],
  [2, 2, 4, 4],
  [2, 4, 2, 4],
  [3, 3, 2, 4],
  [4, 4, 2, 2],
];

const pattern1 = patterns[Math?.floor(Math?.random() * patterns?.length)];
const createPatternChunks = (array, width) => {
  const pattern2 = [2, 2, 2, 2];
  const randomPattern = width > 1200 ? pattern1 : pattern2;

  const result = [array.slice(0, 1)]; // First chunk is always the first image
  let remainingImages = array?.length - 1; // Exclude the first image
  let start = 1;

  for (let chunkSize of randomPattern) {
    if (remainingImages <= 0) break; // No more images to allocate
    const end = start + Math?.min(chunkSize, remainingImages); // Ensure we don't exceed remaining images
    result.push(array?.slice(start, end));
    remainingImages -= end - start;
    start = end;
  }
  return result;
};
const UploadFile = ({
  uploadAssetsHandler,
  setOpenSidebar,
  id,
  contributerData,
}) => {
  // const { getInputProps } = useDropzone({
  //   onDrop: uploadAssetsHandler,
  // });
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [buttonPopOver, setButtonPopOver] = useState(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    const { digital_collections, base_url, image_container_name } =
      contributerData || {};

    // Fetch assets data
    const assetsData = digital_collections?.[0]?.asset_data || [];
    const assetsLength = assetsData?.length;

    if (assetsLength > 0) {
      const temporaryElements = intitalValues
        ?.flat(Infinity)
        ?.slice(assetsLength);
      const assetsServerUrl = `${base_url}/${image_container_name}`;

      const assetsFileSlugUrls = assetsData
        ?.map((asset) => {
          const { fileType, file_slug, thumbnail, _id, file_name } = asset;

          const thumbnailUrl = fileType?.startsWith("image")
            ? `${assetsServerUrl}/${file_slug}`
            : fileType?.startsWith("video") && thumbnail
            ? `${assetsServerUrl}/${thumbnail}`
            : fileType?.startsWith("application")
            ? staticDocImg
            : NotFoundImage;

          return {
            id: _id,
            name: file_name,
            thumbnailUrl,
            fileType,
            assetUrl: `${assetsServerUrl}/${file_slug}`,
          };
        })
        ?.reverse();

      const concatedData = [...assetsFileSlugUrls, ...temporaryElements];
      const arrangedFiles = createPatternChunks(concatedData, width);

      setFiles(arrangedFiles);
    } else {
      setFiles(intitalValues);
    }
  }, [contributerData, width]);

  const closeButtonPoverHandler = () => {
    setAnchorEl(null);
    setButtonPopOver(null);
  };

  return (
    <>
      <UploadAssetsBlock>
        <div className="uploadAssetsBlock">
          {files?.map((file, chunkIndex) => (
            <div className="blankUploadSec" key={chunkIndex}>
              <ImageList
                sx={{ height: chunkIndex === 0 ? "100%" : 174 }}
                cols={file?.length}
                className={`showcaseImages${file?.length}  ${
                  chunkIndex === 0 ? "featuredImages" : "notFeaturedImages"
                }`}
              >
                {file?.length > 0 &&
                  file?.map((item, index) => {
                    const isFileValid = isValidElement(item);
                    const fileThumbnail = item?.thumbnailUrl
                      ? item?.thumbnailUrl
                      : staticDocImg;

                    return (
                      <React.Fragment key={index}>
                        {isFileValid ? (
                          <div
                            className="uploadImageBlank"
                            onClick={() => {
                              navigate(`${routeConfigs.uploadScreen}?id=${id}`);
                            }}
                          >
                            {item}
                            {/* <input
                              type="file"
                              style={{ display: "none" }}
                              id="uploadMyAssets"
                              {...getInputProps()}
                            /> */}
                            <TextTag variant={textVariants._14px.semiBold}>
                              <label htmlFor="uploadMyAssets">
                                {t("uploadAssets")}
                              </label>
                            </TextTag>
                          </div>
                        ) : (
                          <div className="afterUploadImages">
                            <ImageListItem
                              key={item.id}
                              cols={1}
                              rows={1}
                              className="uploadImg"
                            >
                              <img
                                src={fileThumbnail}
                                srcSet={fileThumbnail}
                                alt={item?.name}
                              />
                            </ImageListItem>
                            <HoverButtonBlock
                              setButtonPopOver={setButtonPopOver}
                              buttonPopOver={buttonPopOver}
                              setAnchorEl={setAnchorEl}
                              file={item}
                              filesLength={file?.length}
                              setPopUpImage={() => setOpenPopUp(fileThumbnail)}
                            />
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
              </ImageList>
            </div>
          ))}
        </div>
      </UploadAssetsBlock>
      {openPopUp && (
        <ContributionImagesPopUp
          fileThumbnail={openPopUp}
          handleClose={() => setOpenPopUp(false)}
        />
      )}
      {buttonPopOver && (
        <PopOverButtonsSection
          anchorEl={anchorEl}
          file={buttonPopOver}
          setPopUpImage={setOpenPopUp}
          handleClose={closeButtonPoverHandler}
        />
      )}
    </>
  );
};
export default UploadFile;
