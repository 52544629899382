import React from "react";

const TrashIconOutline = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 3L8.59955 9.00645C8.5645 9.5325 8.54695 9.79555 8.43335 9.995C8.3333 10.1706 8.1824 10.3118 8.00055 10.3999C7.794 10.5 7.53035 10.5 7.0031 10.5H4.99688C4.46963 10.5 4.20601 10.5 3.99944 10.3999C3.81758 10.3118 3.66669 10.1706 3.56666 9.995C3.45303 9.79555 3.4355 9.5325 3.40043 9.00645L3 3M2 3H10M8 3L7.8647 2.59403C7.73355 2.20062 7.66795 1.50392 7.54635 1.35849C7.43895 1.23006 7.30105 1.13066 7.14525 1.06939C6.9688 1 6.7615 1 6.3468 1H5.6532C5.2385 1 5.0312 1 4.85475 1.06939C4.69896 1.13066 4.56104 1.23006 4.45365 1.35849C4.33203 1.50392 4.26646 2.20062 4.13532 2.59403L4 3"
        stroke="#676D75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default TrashIconOutline;
