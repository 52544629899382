import { styled } from "@mui/system";

const MarketSegmentStyles = styled("div")(({ theme }) => ({
  padding: "7.5rem 0",
  ".marketSegmentMain": {
    display: 'flex',
    fontFamily: 'Poppins',
    WebkitBoxPack: 'justify',
    WebkitJustifyContent: 'space-between',
    justifyContent: 'space-between',
    maxWidth: '1342px',
    margin: 'auto',
    padding: '0 15px',
    " .marketLeftSection": {
      maxWidth: '528px',
      flex: '0 0 42%',
      ".marketHeading": {
        marginBottom: "1.5rem",
        h2: {
          color: theme.palette.blackColor.main,
          margin: 0,
        },
      },
      " .marketDescription": {
        maxWidth: '400px',
        span: {
          paddingRight: "18px",
          display: "block",
          color: theme.palette.blackColor.main,
        },
      },
    },
    " .marketRightSection": {
      flex: "1",
      ".linksBlock": {
        maxWidth: "100%",
        margin: "0 0 0 auto",
        display: "flex",
        justifyContent: "space-between",
        gap: "15px",
        ul: {
          padding: "0",
          flex: "0 0 20%",
          maxWidth: "18%",
          a: {
            marginBottom: "24px",
            span: {
              wordBreak: 'break-all',
            },
          },
        },
      },
    },
  },
}));

export default MarketSegmentStyles;
