import { Box } from "@mui/system";
import CustomButton from "components/Button/CustomButton";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoryQuickLinksStyles from "../cms-changes/CategoryQuickLinks/CategoryQuickLinksStyles";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { UserDetailDialogDesign } from "../user-administration/styles/roleStyles";
import { DialogActions, DialogContent, TextField } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  getCuratedGalleryInfoApi,
  updateCuratedGalleryInfoApi,
} from "./features/curatedgalleryActions";
import { curatedGallerySelector } from "./features/curatedGallerySlice";

const CuratedGallery = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data: dynamicQuickTitleData } = useSelector(curatedGallerySelector);

  const [open, setOpen] = useState(false);
  const [popUpName, setPopUpName] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);

  useEffect(() => {
    dispatch(getCuratedGalleryInfoApi());
  }, [dispatch]);

  useEffect(() => {
    console.log("Fetched data for curated gallery:", dynamicQuickTitleData);
  }, [dynamicQuickTitleData]);

  return (
    <CategoryQuickLinksStyles>
      <Box className="titleButton">
        <PageTitle
          title={dynamicQuickTitleData?.title || t("curatedGallery")}
        />
        <Box className={"addNewDiv1"}>
          <CustomButton
            onClick={() => {
              setPopUpName(t("changeSectionTitle"));
              setOpenPopUp(true);
            }}
            variant="outlined"
          >
            {t("changeSectionTitle")}
          </CustomButton>
        </Box>
      </Box>
      <ChangeSelectionTitle
        popUpName={popUpName}
        closePopUp={() => setOpenPopUp(false)}
        open={openPopUp}
        data={dynamicQuickTitleData}
        dispatch={dispatch}
      />
    </CategoryQuickLinksStyles>
  );
};

export default CuratedGallery;

const ChangeSelectionTitle = ({
  data,
  popUpName,
  closePopUp,
  open,
  dispatch,
}) => {
  const validationSchema = Yup.object({
    title: Yup.string()
      .trim()
      .max(50, validationMessages.tooLong)
      .required(t("pleaseEnterPageTitle"))
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      ),
    description: Yup.string()
      .trim()
      .required(t("pleaseEnterDescription"))
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      )
      .max(400, validationMessages.tooLong),
  });

  const submitHandler = async (values) => {
    console.log("Submitting values:", values);
    const body = {
      curatedGalleryInfoId: data?._id,
      title: values?.title,
      description: values?.description,
    };

    console.log("Body for update:", body);

    try {
      await dispatch(updateCuratedGalleryInfoApi(body));
      await dispatch(getCuratedGalleryInfoApi());
      closePopUp();
    } catch (error) {
      console.error("Error updating curated gallery:", error);
    }
  };

  return (
    <DialogCommon
      title={popUpName}
      handleClose={closePopUp}
      open={open}
      extraPaperClasses={`singleFileInput`}
      maxWidth={"md"}
    >
      <UserDetailDialogDesign>
        <Box className="popup-main AddUserDetails addEditCollectionType">
          <DialogContent>
            <Box className="requesOuter">
              <Formik
                initialValues={{
                  title: data?.title || "",
                  description: data?.description,
                }}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
              >
                {({ values, handleBlur, handleChange }) => (
                  <Form>
                    <Box className="formSection">
                      <Box className="request-common">
                        <label htmlFor="title" className="label">
                          {t("enterName", { name: t("title") })}
                        </label>
                        <TextField
                          placeholder={t("enterName", { name: t("title") })}
                          size="small"
                          className={"addNewText"}
                          variant="outlined"
                          name={"title"}
                          id={"title"}
                          value={values?.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="title"
                          component={"span"}
                          className="errorClass"
                        />
                      </Box>
                      <Box className="request-common">
                        <label htmlFor={"description"} className="label">
                          {t("description")}
                        </label>
                        <TextField
                          placeholder={t("description")}
                          rows={3}
                          size="small"
                          variant="outlined"
                          type="text"
                          multiline={true}
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description ?? ""}
                        />
                        <ErrorMessage
                          name="description"
                          component={"span"}
                          className="errorClass"
                        />
                      </Box>
                      <DialogActions className="popup-button-outer">
                        <CustomButton variant={"contained"} type="submit">
                          {t("save")}
                        </CustomButton>
                        <CustomButton
                          variant={"contained"}
                          onClick={closePopUp}
                        >
                          {t("cancel")}
                        </CustomButton>
                      </DialogActions>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Box>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
};
