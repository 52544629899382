/**
 * This is a popper where a user can add/select asset into an album.
 */

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { t } from "i18next";
import { ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
import { StyledAlbumPopper } from "./AlbumPopper";
import {
  buttonVariants,
  MainButtonWithChildren,
} from "components/Button/MainButton";
import PlusIcon from "components/Icons/PlusIcon/PlusIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import TextfieldWithIcon from "components/Textfield/TextfieldwithIcon";
import { contributerDataState } from "pages/User-Pages/ContributePage/features/contributionSlice";
import CheckboxWithLabel from "components/Checkbox/CheckboxWithLabel";
import { ButtonMiniIconWithChildren } from "components/Button/ButtonMiniIcon";
import TickIcon from "components/Icons/TickIcon/TickIcon";
import { intersectArrays } from "helper/arrayHelpers";
import { linkAssetToAlbums } from "../../features/uploadScreenAction";
import { getAllContributeAlbums } from "pages/User-Pages/ContributePage/features/contributionAction";

const checkboxStatesObj = {
  fullyChecked: "fullyChecked",
  partiallyChecked: "partiallyChecked",
  notChecked: "notChecked",
};

const AddToAlbumPopper = ({
  addToAlbumPopper,
  setAddToAlbumPopper,
  addToAlbumRef,
  setAddAlbumPopup,
  checkedAssetIds,
  collection_id,
  setToggleSnack,
}) => {
  const dispatch = useDispatch();
  const { allAlbumsData } = useSelector(contributerDataState);
  const albumDataArr = allAlbumsData?.collection_albums?.[0]?.album_data;

  const [albumData, setAlbumData] = useState();
  const [linkedAlbumData, setLinkedAlbumData] = useState([]);

  useEffect(() => {
    setAlbumData(albumDataArr);
  }, [albumDataArr]);

  useEffect(() => {
    const data = albumDataArr?.map((album) => {
      return { album_id: album?._id, asset_ids: album?.asset_ids };
    });
    setLinkedAlbumData(data);
  }, [checkedAssetIds, albumDataArr]);

  const handleClose = () => {
    setAddToAlbumPopper(false);
    setAlbumData([]);
  };

  const handleAlbumSearch = (e) => {
    const value = e.target.value?.toLowerCase();
    setAlbumData((prev) =>
      value === "" || !value
        ? albumDataArr
        : prev?.filter((x) => x?.name?.toLowerCase()?.includes(value)),
    );
  };

  const handleAddAssetInAlbum = () => {
    const body = { data: linkedAlbumData, noToast: true }; //send noToast true to show no toast
    const data = { body, collection_id };
    dispatch(linkAssetToAlbums(data)).then((res) => {
      dispatch(getAllContributeAlbums(collection_id));
      if (res?.error) return;
      setToggleSnack((prev) => ({ ...prev, addToAlbumSnack: true }));
    });
    handleClose();
  };

  // const handleCheckboxChange = (e, data) => {
  //   //when checkbox is checked
  //   const value = e.target.checked;
  //   const album_id = data?._id; //checked album's id
  //   const allAlbumData = deepCopy(linkedAlbumData);

  //   //getting the index of checked album from all albums
  //   const albumAssetArrIndex = allAlbumData?.findIndex(
  //     (x) => x?.album_id === album_id,
  //   );

  //   if (albumAssetArrIndex === -1) return;

  //   /**
  //    * if checkbox is checked then add the asset id with
  //    * the existing asset ids inside the album
  //    *
  //    * else remove the asset ids from album which are selected asset ids
  //    */
  //   allAlbumData[albumAssetArrIndex].asset_ids = value
  //     ? [...checkedAssetIds, ...allAlbumData[albumAssetArrIndex].asset_ids]
  //     : allAlbumData[albumAssetArrIndex].asset_ids?.filter(
  //         (x) => !checkedAssetIds?.includes(x),
  //       );

  //   setLinkedAlbumData(allAlbumData);
  // };

  const handleCheckboxChange = (e, data) => {
    const { checked: isChecked } = e.target; // Whether the checkbox is checked
    const { _id: albumId } = data || {}; // Album ID

    // Find the index of the album in the linkedAlbumData array
    const albumIndex = linkedAlbumData.findIndex(
      (album) => album.album_id === albumId,
    );

    // If the album doesn't exist in the data, exit early
    if (albumIndex === -1) return;

    // Copy the current album data to avoid direct mutation
    const updatedAlbumData = [...linkedAlbumData];

    // Get the current asset IDs for this album
    const currentAssetIds = updatedAlbumData[albumIndex].asset_ids || [];

    // Determine the new asset IDs based on the checkbox state
    const updatedAssetIds = isChecked
      ? [...new Set([...checkedAssetIds, ...currentAssetIds])] // Add checked assets and ensure uniqueness
      : currentAssetIds.filter((assetId) => !checkedAssetIds.includes(assetId)); // Remove unchecked assets

    // Update the album data with the new asset IDs
    updatedAlbumData[albumIndex].asset_ids = updatedAssetIds;

    // Update the state with the modified album data
    setLinkedAlbumData(updatedAlbumData);

    //also update the albumdata so checkbox conditions can work
    setAlbumData((prev) => {
      const updatedAlbums = [...prev];
      updatedAlbums[albumIndex] = {
        ...updatedAlbums[albumIndex],
        asset_ids: updatedAssetIds,
      };
      return updatedAlbums;
    });
  };

  return (
    <Popper
      //   sx={{ top: "0px !important", left: "0px!important" }}
      open={addToAlbumPopper}
      anchorEl={addToAlbumRef.current}
      placement="top-start"
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Paper sx={{ boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07);" }}>
            <ClickAwayListener onClickAway={handleClose}>
              <StyledAlbumPopper className={"addToAlbumPopper"}>
                <MainButtonWithChildren
                  variant={buttonVariants?.grey}
                  className="addAlbum"
                  onClick={() => setAddAlbumPopup(true)}
                >
                  <span>{<PlusIcon />}</span>
                  <TextTag variant={textVariants._14px.medium}>
                    {t("createAlbum")}
                  </TextTag>
                </MainButtonWithChildren>
                {albumDataArr?.length > 4 && (
                  <TextfieldWithIcon
                    onChange={handleAlbumSearch}
                    className="albumSearch"
                  />
                )}
                <div className="albumCheckboxList">
                  {albumData?.length <= 0 ? (
                    <div className="noAlbumFound">
                      <TextTag variant={textVariants._12px.medium}>
                        {t("noDataFound")}
                      </TextTag>
                    </div>
                  ) : (
                    albumData?.map((data, index) => {
                      //getting common assets that are selected and in the album
                      const interSectedArr = intersectArrays(
                        data?.asset_ids,
                        checkedAssetIds,
                      );

                      //checking if all selected assets are in album
                      const isEqual = _.isEqual(
                        checkedAssetIds.sort(),
                        interSectedArr.sort(),
                      );

                      const isDefaultChecked = interSectedArr?.length >= 1;

                      //if there is even one selected asset in the album then the asset is selected
                      //if all the selected assets are in album then checkbox is fully selected else partially selected
                      //if no selected asset in the album then checkbox is not checked
                      const checkboxClass = isDefaultChecked
                        ? isEqual
                          ? checkboxStatesObj?.fullyChecked
                          : checkboxStatesObj?.partiallyChecked
                        : checkboxStatesObj?.notChecked;

                      return (
                        <div className={`albumCheckbox`} key={index}>
                          <CheckboxWithLabel
                            onChange={(e) =>
                              handleCheckboxChange(e, data, checkboxClass)
                            }
                            labelName={data?.name}
                            // checked={selectedAlbumIds?.includes(data?._id)}
                            defaultChecked={isDefaultChecked}
                            className={checkboxClass}
                          />
                        </div>
                      );
                    })
                  )}
                </div>
                <ButtonMiniIconWithChildren
                  onClick={handleAddAssetInAlbum}
                  className={"albumFilterButton"}
                >
                  <TickIcon />
                  <TextTag variant={textVariants._12px.medium}>
                    {t("apply")}
                  </TextTag>
                </ButtonMiniIconWithChildren>
              </StyledAlbumPopper>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default AddToAlbumPopper;
