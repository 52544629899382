import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import DialogCommon from "components/DialogComponent/DialogCommon";
import UploadImageSvg from "components/Icons/UploadImageIcon/uploadImageSvg";
import TextTag, { textVariants } from "components/Typography/TextTag";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import { useNavigate } from "react-router-dom";
import { SearchSimilarCompStyle } from "./SearchSimilarCompStyle";
import { captureFrame } from "./CaptureFrame";

const SearchSimilarComp = ({ open, setOpen }) => {
  const [uploadedFile, setUploadedFile] = useState(null);
  const [error, setError] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    setUploadedFile(null);
    setError("");
    setOpen(false);
  };

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      setError("");
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const fileType = file.type;

        if (fileType.startsWith("image/")) {
          setUploadedFile(file);
          // navigate("/search");

          return;
        }

        if (fileType.startsWith("video/")) {
          setUploadedFile(file);
          // navigate("/search");
          return;
        }
      }
      if (rejectedFiles) {
        setError(t("onlyImageAndVideoAllowed"));
      }
    },
    [navigate],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "video/mp4": [".mp4"],
      "video/quicktime": [".mov"],
      "video/webm": [".webm"],
    },
  });

  const renderUploadedContent = () => {
    if (uploadedFile) {
      const fileType = uploadedFile.type;

      if (fileType.startsWith("video/")) {
        let videoRef = null; // to store the video reference

        return (
          <>
            <TextTag variant={textVariants._18px.medium}>
              Select a frame
            </TextTag>
            <TextTag variant={textVariants._14px.medium}>
              Pause on a frame to find similar content
            </TextTag>
            <video
              controls
              width="100%"
              height="auto"
              src={URL.createObjectURL(uploadedFile)}
              ref={(el) => (videoRef = el)}
            />
            <MainButton
              title={t("startSearching")}
              onClick={(e) => {
                e.stopPropagation();
                captureFrame(videoRef);
                setUploadedFile(null);
                setOpen(false);
                navigate("/search");
              }}
              variant={buttonVariants.grey}
              aria-hidden="false"
            />
          </>
        );
      }

      if (fileType.startsWith("image/")) {
        handleClose();
      }
    }
    return null;
  };

  return (
    <DialogCommon
      open={open}
      handleClose={() => handleClose()}
      title={t("searchSimilarPopUpHead")}
      fullwidth={true}
      maxWidth="lg"
      extraPaperClasses="searchSimilarDialog"
      titleClass="searchSimilarTitle"
    >
      <SearchSimilarCompStyle>
        <div
          className="searchSimilarPopupMain"
          {...(!uploadedFile && getRootProps())} // Enable drag-and-drop only if no file is uploaded
        >
          {error && (
            <TextTag
              className="errorShow"
              variant={textVariants._14px.regular}
              style={{ color: "red", marginTop: "8px" }}
            >
              {error}
            </TextTag>
          )}
          {uploadedFile ? (
            renderUploadedContent()
          ) : (
            <>
              <div
                className={`uploadIconBlock  ${isDragActive ? "dragtrue" : ""}`}
              >
                <div
                  className="hidden-upload-dialog"
                  style={{ display: uploadedFile ? "none" : "block" }}
                  inert={uploadedFile ? true : undefined}
                >
                  <UploadImageSvg />
                </div>
                <input {...getInputProps()} />
                <TextTag variant={textVariants._14px.semiBold}>
                  {isDragActive ? t("dropFileHere") : t("dropFileStartSearch")}
                </TextTag>
                <MainButton
                  title="or Select here"
                  variant={buttonVariants.grey}
                />
              </div>
              <TextTag variant={textVariants._14px.semiBold}>
                Accepted file formats: JPG, PNG, MP4, MOV, WebM
              </TextTag>
            </>
          )}
        </div>
      </SearchSimilarCompStyle>
    </DialogCommon>
  );
};

export default SearchSimilarComp;
