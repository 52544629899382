/**
 * array - 1 dimensional array like [1,2,3,4,5,6,7]
 * size - size of chunk like 2
 * returns - 2d array like [[1,2],[3,4],[5,6],[7]]
 */

export const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const intersectArrays = (arr1, arr2) => {
  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  const intersectionSet = set1.intersection(set2);

  return [...intersectionSet];
};

export function paginateArray(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array && array?.length > 0
    ? array.slice((page_number - 1) * page_size, page_number * page_size)
    : [];
}
