import { styled } from "@mui/system";

export const ContributePopupStyles = styled("div")(({ theme }) => ({
  position: "fixed",
  zIndex: "99",
  background: "rgba(33, 42, 54, 0.72)",
  width: "100%",
  height: "100%",
  top: "0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  right: "0",
  ".contributePopupMain": {
    width: "100%",
    // minWidth: "416px",
    maxWidth: "416px",
    background: theme.palette.whiteColor.main,
    padding: "1.5rem 0",
    borderRadius: "0.5rem",
    position: "relative",
    wordBreak: "break-word",
    svg: {
      cursor: "pointer",
    },
    "button.createAlbum": {
      svg: {
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
    },
    ".aiDescPopupBody": {
      ".descSection": {
        padding: "24px",
        ".aiKeywords": {
          marginTop: "24px",
        },
        "textarea#aiDescription": {
          width: "100%",
          maxWidth: "100%",
          minHeight: "304px",
        },
        "button.generateDesc": {
          width: "100%",
          maxWidth: "100%",
          marginTop: "24px",
          span: {
            color: theme.palette.primaryColor.main,
            svg: {
              path: {
                stroke: theme.palette.primaryColor.main,
              },
            },
          },
        },
      },
    },
    ".addAlbunFooter": {
      button: {
        "&.copyButton ": {
          maxWidth: "53px",
          padding: "0",
          svg: {
            width: "18px",
            height: "18px",
          },
        },
        "&.deleteBtn ": {
          flex: "1",
          width: "100%",
          maxWidth: "none",
          display: "flex",
          gap: "8px",
        },
        svg: {
          path: {
            stroke: theme.palette.whiteColor.main,
          },
        },
        "&:disabled": {
          svg: {
            path: {
              stroke: theme.palette.gray_500.main,
            },
          },
        },
      },
    },
    "&:has(.aiKeywordsBody )": {
      maxWidth: "816px",
      ".descSection": {
        button: {
          "&.generateDesc ": {
            marginTop: "0",
          },
        },
      },
    },
  },
}));
