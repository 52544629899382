import React from "react";
import { styled } from "@mui/system";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";
import ArrowUpIcon from "components/Icons/ArrowIcons/ArrowUpIcon";
import FileTickIcon from "components/Icons/FileIcon/FileCheckIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";

const TitleCreateAlbumButtonStyles = styled("button")(
  ({ theme, disabled = false }) => ({
    position: "relative",
    width: "100%",
    background: "#fff",
    border: "1px solid #f2f2f2",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "max-content",
    gap: "8px",
    padding: "0 16px",
    cursor: "pointer",
    color: !disabled
      ? `${theme.palette.primaryColor.main}`
      : `${theme.palette.gray_300.main}`,
    span: {
      minHeight: "37px",
      display: "flex",
      alignItems: "center",
      position: "relative",
      //   "&:after": {
      //     content: "''",
      //     position: "absolute",
      //     width: "100%",
      //     height: "2px",
      //     background: theme.palette.primaryColor.main,
      //     bottom: "-2px",
      //   },
    },
    "&:after": {
      content: "''",
      position: "absolute",
      width: "calc(100% - 36px)",
      height: "2px",
      background: theme.palette.primaryColor.main,
      bottom: "0px",
    },
    ":disabled": {
      "&:after": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "2px",
        background: "transparent",
        bottom: "-2px",
      },
    },
    "&.collectionNameHeading": {
      ".albumFileIcon": {
        display: "flex",
        alignItems: "center",
        background: "#F5F5F6",
        borderRadius: "8px",
        padding: "2px 8px",
        gap: "4px",
        color: theme.palette.gray_400.main,
        span: {
          minHeight: "unset",
          "&:after": {
            position: "unset",
            height: "unset",
          },
        },
      },
    },
  }),
);

const TitleCreateAlbumButton = ({
  handleTitleClick,
  albumDropdownRef,
  collectionName,
  filteredAlbumsData,
  albumDropdown,
  disabled,
}) => {
  return (
    <TitleCreateAlbumButtonStyles
      onClick={handleTitleClick}
      className={`collectionNameHeading ${disabled ? "disabled" : ""}`}
      ref={albumDropdownRef}
      disabled={disabled}
    >
      <TextTag variant={textVariants._14px.semiBold}>{collectionName}</TextTag>
      {filteredAlbumsData?.length > 0 && (
        <div className="albumFileIcon">
          <FileTickIcon />
          <TextTag variant={textVariants._10px.semiBold}>
            {filteredAlbumsData?.length}
          </TextTag>
        </div>
      )}
      {!disabled && (albumDropdown ? <ArrowUpIcon /> : <ArrowDownIcon />)}
    </TitleCreateAlbumButtonStyles>
  );
};

export default TitleCreateAlbumButton;
