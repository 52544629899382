import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { AssetsListItems } from "./UploadSection";
import ContributePopupHeader from "components/ContributePopup/ContributePopupHeader/ContributePopupHeader";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import UploadFile from "./DisplayFilesSection";
import { contributerDataState } from "../../features/contributionSlice";
import { useQuery } from "utils/queryString";
import { getContributionById } from "../../features/contributionAction";
import UploadSectionStyles from "./UploadSection.style";

const UploadSection = ({
  activeComponent,
  selectedAlbum,
  setSelectedAlbum,
  uploadAssetsHandler,
  unMountActiveComponent,
}) => {
  const id = useQuery().get("id");

  const dispatch = useDispatch();
  const [openSidebar, setOpenSidebar] = useState(false);
  const { contributerData } = useSelector(contributerDataState);
  useEffect(() => {
    document.body.style.overflow = openSidebar ? "hidden" : "unset";
    return () => (document.body.style.overflow = "unset");
  }, [openSidebar]);
  useEffect(() => {
    dispatch(getContributionById(id));
  }, [dispatch, id]);

  return (
    <UploadSectionStyles>
      {/* <TextTag variant={textVariants._14px.medium}>{t("assets")}</TextTag> */}
      <div className="uploadSection-buttons">
        <AssetsListItems
          selectedAlbum={selectedAlbum}
          setSelectedAlbum={setSelectedAlbum}
          unMountActiveComponent={unMountActiveComponent}
          uploadAssetsHandler={uploadAssetsHandler}
          id={id}
        />
      </div>
      <UploadFile
        //  uploadAssetsHandler={uploadAssetsHandler}
        setOpenSidebar={setOpenSidebar}
        contributerData={contributerData}
        id={id}
        uploadAssetsHandler={uploadAssetsHandler}
      />
      <sidebar
        className={`uploadSection-sidebar ${openSidebar ? "openSider" : ""}`}
      >
        <div className="closeSideBar" onClick={() => setOpenSidebar(false)} />
        <section>
          <div className="sideBarHeader">
            <ContributePopupHeader
              handlePopupClose={() => setOpenSidebar(false)}
              popupTitle={"New York Presbyterian Hospital David Koch"}
              className="sideBarHeader"
            />
          </div>
          <div className="collectionData">
            <div className="imageBlock">
              <img
                src={
                  "https://t4.ftcdn.net/jpg/02/81/89/73/360_F_281897358_3rj9ZBSZHo5s0L1ug7uuIHadSxh9Cc75.jpg"
                }
                alt="Display-Image"
                className="uploadSection-Image"
              />
            </div>
            <div className="sideBarText">
              <TextTag variant={textVariants._14px.regular}>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                Praesentium nisi culpa aperiam quasi, fuga dolorem error
                doloremque tempora, non dicta, pariatur ab aut? Atque rerum ad,
                itaque voluptas ducimus corrupti! Minima asperiores aspernatur
                ad ratione facilis? Praesentium aliquid quod incidunt ad itaque
                fugit nobis minus tenetur vel dolore aliquam doloremque, quos
                amet voluptas rem veritatis quis odit officiis consectetur.
                Assumenda. Ea aliquam fuga vel fugiat totam autem nam magnam
                alias sapiente deserunt ab minima officia sed corporis
                dignissimos non, commodi illum saepe voluptas a voluptate nisi
                enim odio? Dolorum, consectetur.
              </TextTag>
              <TextTag variant={textVariants._14px.regular}>
                Heather Place is a rental housing complex owned by the Metro
                VancouverHousing Corporation, on a 2-acre site. The renewal of
                Heather Placereplaces 86 aging housing units with a socially and
                environmentallysustainable rental community with units for a
                diverse mix of families,seniors, couples, urban singles, and
                people with disabilities across a mixedrange of incomes. Heather
                Place is part of a larger redevelopment of threebuildings.
              </TextTag>
            </div>
            <div className="footerButton">
              <MainButton
                title={"Go to Collection"}
                variant={buttonVariants.grey}
              />
              {/* <ButtonMiniIcon > Go To Collection </ButtonMiniIcon> */}
            </div>
          </div>
        </section>
      </sidebar>
    </UploadSectionStyles>
  );
};

export default UploadSection;
