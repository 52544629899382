import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { ClickAwayListener } from "@mui/material";
import { allActiveAssets } from "pages/Admin-Pages/asset-administration/AssetType/features/typeSlice";
import { getAllAssetCategoryApi } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategoryAction";
// import { allAssetCategory } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";

export const searchFlagType = {
  all: "ALL",
  type: "TYPE",
  cat: "CAT",
};

const SearchTypeSelect = (props) => {
  const { openSearchType, setOpenSearchType, searchType, setSearchType } =
    props;

  const dispatch = useDispatch();

  const allTypes = useSelector(allActiveAssets)?.data;
  // const allCategories = useSelector(allAssetCategory)?.asset_type;

  useEffect(() => {
    dispatch(getAllAssetCategoryApi());
  }, [dispatch]);

  // const fial =
  //   allTypes && allCategories
  //     ? allTypes?.map((item1) => ({
  //         name: item1.name,
  //         _id: item1._id,
  //         enabled: item1.enabled,
  //         category_data: allCategories
  //           .filter((item2) => item2.asset_type_id === item1._id)
  //           .map((item2) => ({
  //             name: item2.name,
  //             id: item2._id,
  //             enabled: item2.enabled,
  //           })),
  //       }))
  //     : [];

  const handleChangeSearch = (searchObj, searchFlag) => {
    setSearchType({ name: searchObj?.name, searchFlag });
    setOpenSearchType(false);
  };

  return (
    openSearchType && (
      <ClickAwayListener
        onClickAway={() => {
          setOpenSearchType(false);
        }}
      >
        <div
          onMouseLeave={() => setOpenSearchType(false)}
          className={`searchTypeDropDown ${openSearchType ? "active" : ""}`}
        >
          <div
            className={`searchType AllTypes ${
              searchType?.searchFlag === searchFlagType?.all
                ? "selectedType"
                : ""
            }`}
            onClick={() =>
              handleChangeSearch({ name: "All Content" }, searchFlagType?.all)
            }
          >
            <span>{"All Content"}</span>
            {searchType?.searchFlag === searchFlagType?.all && (
              <div className="selectedTypeIcon">
                <CheckRoundedIcon />
              </div>
            )}
          </div>
          {allTypes?.map(
            (searchTypeObj, index) =>
              searchTypeObj?.enabled && (
                <div key={index} className={`searchTypeParent`}>
                  <div
                    className={`searchType boldSearchType  ${
                      searchType?.searchFlag === searchFlagType?.type &&
                      searchType?.name === searchTypeObj?.name
                        ? "selectedType"
                        : ""
                    }`}
                    onClick={() =>
                      handleChangeSearch(searchTypeObj, searchFlagType?.type)
                    }
                  >
                    <span>{searchTypeObj?.name}</span>
                    {searchType?.searchFlag === searchFlagType?.type &&
                      searchType?.name === searchTypeObj?.name && (
                        <div className="selectedTypeIcon">
                          <CheckRoundedIcon />
                        </div>
                      )}
                  </div>
                  {/* <div className="searchTypeCats">
                    {searchTypeObj?.category_data?.map(
                      (catsObj, catIndex) =>
                        catsObj?.enabled && (
                          <div
                            className={`searchType searchCatsMain  ${
                              searchType?.searchFlag === searchFlagType?.cat &&
                              searchType?.name === catsObj?.name
                                ? "selectedType"
                                : ""
                            }`}
                            onClick={() =>
                              handleChangeSearch(catsObj, searchFlagType?.cat)
                            }
                            key={catIndex}
                          >
                            <span>{catsObj?.name}</span>
                            {searchType?.searchFlag === searchFlagType?.cat &&
                              searchType?.name === catsObj?.name && (
                                <div className="selectedTypeIcon">
                                  <CheckRoundedIcon />
                                </div>
                              )}
                          </div>
                        ),
                    )}
                  </div> */}
                </div>
              ),
          )}
        </div>
      </ClickAwayListener>
    )
  );
};

export default SearchTypeSelect;
