import React from "react";

const AssetUploadIcon = (props) => {
  return (
    <svg
      width="179"
      height="206"
      viewBox="0 0 179 206"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.335938 8.5C0.335938 4.08172 3.91766 0.5 8.33594 0.5H157.666C162.084 0.5 165.666 4.08172 165.666 8.5V108.5H0.335938V8.5Z"
        fill="#E9E9E9"
      />
      <rect
        x="16.5"
        y="17.5"
        width="15"
        height="15"
        rx="3.5"
        stroke="#BDC0C4"
      />
      <mask id="path-3-inside-1_4047_57020" fill="white">
        <path d="M0.335938 108.5H165.666V197.5C165.666 201.918 162.084 205.5 157.666 205.5H8.33594C3.91766 205.5 0.335938 201.918 0.335938 197.5V108.5Z" />
      </mask>
      <path
        d="M0.335938 108.5H165.666H0.335938ZM166.666 197.5C166.666 202.471 162.637 206.5 157.666 206.5H8.33594C3.36537 206.5 -0.664062 202.471 -0.664062 197.5H1.33594C1.33594 201.366 4.46994 204.5 8.33594 204.5H157.666C161.532 204.5 164.666 201.366 164.666 197.5H166.666ZM8.33594 206.5C3.36537 206.5 -0.664062 202.471 -0.664062 197.5V108.5H1.33594V197.5C1.33594 201.366 4.46994 204.5 8.33594 204.5V206.5ZM166.666 108.5V197.5C166.666 202.471 162.637 206.5 157.666 206.5V204.5C161.532 204.5 164.666 201.366 164.666 197.5V108.5H166.666Z"
        fill="#E9E9E9"
        mask="url(#path-3-inside-1_4047_57020)"
      />
      <rect x="16.3359" y="124.5" width="65" height="8" fill="#81868C" />
      <rect
        x="16.8359"
        y="141"
        width="44"
        height="23"
        rx="11.5"
        stroke="#CCCED1"
      />
      <rect x="28.3359" y="151.5" width="21" height="2" fill="#BDC0C4" />
      <rect x="141.664" y="151.5" width="8" height="2" fill="#BDC0C4" />
      <rect x="16.3359" y="180.5" width="133.33" height="1" fill="#E9E9E9" />
      <rect x="16.3359" y="189.5" width="8" height="8" fill="#BDC0C4" />
      <rect x="32.3359" y="192.5" width="8" height="2" fill="#BDC0C4" />
      <path
        d="M158.997 122.664V120.664V122.664ZM153.745 124.745L152.331 123.331L153.745 124.745ZM153.745 135.331L152.331 136.745L153.745 135.331ZM164.331 124.745L165.745 123.331L164.331 124.745ZM151.664 129.997H149.664H151.664ZM168.15 139.584L164.828 145.753C164.448 146.458 164.258 146.811 164.03 146.9C163.832 146.977 163.609 146.955 163.429 146.841C163.223 146.709 163.105 146.327 162.869 145.562L158.258 130.591C158.065 129.966 157.969 129.654 158.046 129.443C158.114 129.259 158.259 129.114 158.443 129.046C158.654 128.969 158.966 129.065 159.591 129.258L174.562 133.869C175.327 134.105 175.709 134.223 175.841 134.429C175.955 134.609 175.977 134.832 175.9 135.03C175.811 135.258 175.458 135.448 174.753 135.828L168.584 139.15C168.479 139.206 168.426 139.235 168.381 139.271C168.34 139.303 168.303 139.34 168.271 139.381C168.235 139.426 168.206 139.479 168.15 139.584Z"
        fill="#F5F5F6"
      />
      <path
        d="M158.997 122.664V120.664M153.745 124.745L152.331 123.331M153.745 135.331L152.331 136.745M164.331 124.745L165.745 123.331M151.664 129.997H149.664M168.15 139.584L164.828 145.753C164.448 146.458 164.258 146.811 164.03 146.9C163.832 146.977 163.609 146.955 163.429 146.841C163.223 146.709 163.105 146.327 162.869 145.562L158.258 130.591C158.065 129.966 157.969 129.654 158.046 129.443C158.114 129.259 158.259 129.114 158.443 129.046C158.654 128.969 158.966 129.065 159.591 129.258L174.562 133.869C175.327 134.105 175.709 134.223 175.841 134.429C175.955 134.609 175.977 134.832 175.9 135.03C175.811 135.258 175.458 135.448 174.753 135.828L168.584 139.15C168.479 139.206 168.426 139.235 168.381 139.271C168.34 139.303 168.303 139.34 168.271 139.381C168.235 139.426 168.206 139.479 168.15 139.584Z"
        stroke="#676D75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AssetUploadIcon;
