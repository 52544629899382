import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Box, Grid } from "@mui/material";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import CustomButton from "components/Button/CustomButton";
import {
  assetSubCategoryEditApi,
  assetSubCategoryEnableDisableApi,
  assetsSubCategorydeleteApi,
  countActiveSubCategories,
  getAllAssetSubCategories,
  getAssetSubCategoryApi,
  getSubCategoryFields,
  subCatShowHomePageApi,
} from "./features/assetSubCategoryAction";
import {
  subAssetCategoryState,
  subCategoryFieldValueArray,
} from "./features/assetSubCategorySlice";
import AddEditPopupSubCategory from "./Components/AddEditPopupSubCategory";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import ToggleSwitch from "pages/Components/ToggleSwitch/ToggleSwitch";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import TabsComponent from "pages/Components/Tabs/Tabs";
import { categoriesTabs } from "pages/Components/Tabs/TabsConstant";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { validationMessages } from "utils/validation/validationUtils";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import CommonDeletePopup from "pages/Components/CommonDeletePopup/CommonDeletePopup";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import { FieldManagementStyle } from "pages/Admin-Pages/fields-management/fieldManagementStyle";
import AddEditFileType from "../AssetType/components/AddEditFileTypePopup";

function AssetSubCategory() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();
  const subAssetCategoryData = useSelector(subAssetCategoryState);
  const fieldValueData = useSelector(subCategoryFieldValueArray); //filter

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("category_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "sub_category_image",
      headerName: t("subCategoryImage"),
      minWidth: 250,
      headerClass: "fieldCenter",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellClass: "category_center",
      cellRenderer: (props) => {
        const url = `${props?.data?.partial_image_url}/${props?.value}`;
        return (
          <span key={props?.value} className="center thumbImage dblock">
            <img
              src={props?.value ? url : "./logos/assetCategoryDefault.png"}
              alt="categoryImage"
            />
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: t("subCategory"),
      headerClass: "leftAlign",
      minWidth: 250,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: true,
        editFunction: editSubCategoryName,
      },
      cellClass: "editableCell category_edit category_cell",
      editable: true,
    },
    {
      field: "category_data",
      headerName: t("category"),
      headerClass: "leftAlign",
      minWidth: 250,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "category_data.name",
          dispatchName: "category_data.name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"category_data.name"}
                sortBy={"category_data.name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value?.name),
      cellClass: "category_cell",
      cellEditorParams: {
        checkAlphaNumericValidation: true,
      },
    },
    {
      field: "asset_type_data",
      headerName: t("type"),
      headerClass: "leftAlign",
      minWidth: 250,
      cellClass: "category_cell",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "asset_type_data.name",
          dispatchName: "asset_type_data.name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"asset_type_data.name"}
                sortBy={"asset_type_data.name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value?.name),
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "createdAt",
          dispatchName: "createdAt",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"createdAt"}
                sortBy={"createdAt"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      cellRenderer: ({ data }) =>
        ToolTipWrapperComponent(dayjs(data?.createdAt).format("DD-MM-YYYY")),
      cellClass: "category_cell",
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn category_status",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                setCurrentRowData(props?.data);
                handleSwitchChange(
                  props?.data?._id, //id
                  props?.value, //value
                  props,
                );
              }}
            />
          </Grid>
        );
      },
    },
    // {
    //   field: "show_on_home_page",
    //   headerName: t("quickThumbnail"),
    //   headerClass: "fieldCenter",
    //   minWidth: 200,
    //   headerComponent: (params) => {
    //     const filterClickProps = {
    //       dispatchGetName: "show_on_home_page",
    //       dispatchName: "show_on_home_page",
    //       applied_filters: params?.applied_filters_data?.applied_filters,
    //     };
    //     return (
    //       <th
    //         key={params?.column?.colId}
    //         className="userRole leftAlign"
    //         onClick={(e) => {
    //           commonFilterOnClick(e, filterClickProps);
    //         }}
    //         id={params?.column?.colId}
    //       >
    //         {params?.displayName}
    //         <button>
    //           <ShowHeaderIcon
    //             columnName={"show_on_home_page"}
    //             sortBy={"show_on_home_page"}
    //             dataState={params?.applied_filters_data?.dataState}
    //           />
    //         </button>
    //       </th>
    //     );
    //   },
    //   headerComponentParams: {
    //     applied_filters_data: [],
    //   },
    //   cellClass: "statusColumn category_status",
    //   cellRenderer: (props) => {
    //     return (
    //       <Grid className="toggleBlockCo">
    //         <ToggleSwitch
    //           checked={props?.value}
    //           value={props?.value}
    //           defaultChecked={props?.value}
    //           onChange={(e) => {
    //             gridRef.current.api.deselectAll();
    //             gridRef.current.api.forEachNode((rowNode) => {
    //               if (rowNode?.data?._id !== props?.data?._id) {
    //                 return false;
    //               }

    //               //updating the ui by toggling switchvalue
    //               rowNode.setDataValue("show_on_home_page", !props?.value);

    //               const body = {
    //                 id: props?.data?._id,
    //                 show_on_home_page: !props?.value,
    //               };

    //               dispatch(subCatShowHomePageApi(body));
    //             });
    //           }}
    //         />
    //       </Grid>
    //     );
    //   },
    // },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      minWidth: 200,
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableEditIcon
            onClick={() => {
              setEditTrue("Edit");
              setAddOpen(true);
              setSelectedRows([]);
              setCurrentRowData(props?.data);
            }}
          />
          <TableDeleteIcon
            onClick={() => {
              setOpenDelete(true);
              setRowsToBeDeleted([props?.data?._id]);
            }}
          />
        </Grid>
      ),
    },
  ];

  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [addOpen, setAddOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState();
  const [editTrue, setEditTrue] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [rowData, setRowData] = useState();
  const [selectedRows, setSelectedRows] = useState();
  const [openChangeTitle, setOpenChangeTitle] = useState(false);

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    setIsLoading(true);
  }, []);

  const handleEditClick = (tooltip) => {
    setOpenChangeTitle(true);
  };

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getAssetSubCategoryApi(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dataState, dispatch]);

  useEffect(() => {
    setPreviousColumnFilterData(subAssetCategoryData?.applied_filters);
    const partial_image_url = `${subAssetCategoryData?.image_base_url}/${subAssetCategoryData?.image_container_name}`;
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) =>
        (commonDataObj[commonKey] = subAssetCategoryData?.[commonKey]),
    );
    setRowData(
      subAssetCategoryData?.AssetSubCategory?.map((tableDataObj) => ({
        partial_image_url,
        _id: tableDataObj?._id,
        categoryId:
          tableDataObj?.category_data?._id ||
          tableDataObj?.category_data?.[0]?._id,
        category_data: tableDataObj?.category_data?.[0],
        name: tableDataObj?.name,
        createdAt: tableDataObj?.createdAt,
        enabled: tableDataObj?.enabled,
        action: "",
        sub_category_image: tableDataObj?.sub_category_image,
        show_on_home_page: tableDataObj?.show_on_home_page,
        other_table_data: commonDataObj,
        asset_type_data: tableDataObj?.asset_type_data?.[0],
      })),
    );
  }, [subAssetCategoryData]);

  const handleSwitchChange = useCallback(
    async (entryId, switchValue, props) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }
        setSelectedRows([]);

        rowNode.setDataValue("enabled", !switchValue);

        let modifiedDataState = dataState;

        if (props?.data?.other_table_data) {
          const other_data = props?.data?.other_table_data;
          modifiedDataState = modifyDataState(other_data);
        }

        const body = {
          enabled: !switchValue,
          id: entryId,
        };

        dispatch(assetSubCategoryEnableDisableApi(body)).then((res) => {
          if (res.error) {
            //reverting the change in case of api error
            dispatch(getAssetSubCategoryApi(modifiedDataState));
          }
        });
      });
    },
    //eslint-disable-next-line
    [],
  );

  function editSubCategoryName(data) {
    const formData = new FormData();
    formData.append("name", data?.updatedValue);
    formData.append("category_id", data?.dataObj?.categoryId);
    formData.append("old_image", true);

    const dataValue = { id: data?.dataObj?._id, data: formData };

    const other_data = data?.dataObj?.other_table_data;
    const modifiedDataState = modifyDataState(other_data);

    dispatch(assetSubCategoryEditApi(dataValue))
      .then(() => {
        dispatch(getAssetSubCategoryApi(modifiedDataState));
      })
      .catch(() => {
        console.error("an error occured");
      });
  }

  function handleDelete() {
    const body = {
      ids: rowsToBeDeleted,
    };
    const dataBody = { body, dataState };

    const shouldPageChange = deletePageChangeChecker(
      subAssetCategoryData?.currentPage,
      subAssetCategoryData?.totalDocuments,
      rowsToBeDeleted?.length,
      subAssetCategoryData?.limit,
    );

    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(assetsSubCategorydeleteApi(dataBody)).then(() => {
      if (shouldPageChange) setDataState(dataBody.dataState);
      gridRef.current.api.deselectAll();
    });
    setOpenDelete(false);
  }

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function commonFilterOnClick(e, props) {
    const ref = e.currentTarget;
    const { dispatchGetName, dispatchName, applied_filters } = props;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getSubCategoryFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  const extraBulkDeleteProps = {
    //props required for extra bulk delete functionality
    //if allowBulkDeleteConditions is false then no need to send other props at all
    allowBulkDeleteConditions: false,
    //if true bulk delete will not check for extra conditions
    childCountExtension: null, //no child entries
    // setOpenedFor,
    getActiveEntries: countActiveSubCategories,
    activeTableEntriesExtension: "total_subcat",
    lastRecordValidationMessage:
      validationMessages.oneSubCategoryShouldBeEnabled,
    getAllEntries: getAllAssetSubCategories,
    allEntriesExtension: "AssetSubCategory",
    setOpen: null, //no need to open reassign
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("subCategory")} />
      <Box className="tooltipTextEdit">
        <p>
          {subAssetCategoryData?.tooltipDescription?.description ||
            "No tooltip description available."}
        </p>
        <TableEditIcon onClick={handleEditClick} />
      </Box>

      <TabsComponent tabsDataArray={categoriesTabs} />
      <Box container spacing={4} className={"userReq-table"}>
        <Box item xs={12}>
          <Box className="fieldTableMain category">
            <Box className="fieldTableHeader">
              <Box className="table-upper">
                <Box className={`add_button`}>
                  <CustomButton
                    onClick={() => {
                      setAddOpen(true);
                      setEditTrue("");
                    }}
                  >
                    {t("addSubCategory")}
                  </CustomButton>
                </Box>
                <PaginationLimitDropDown
                  dataState={dataState}
                  setDataState={setDataState}
                  setSelectedRows={setSelectedRows}
                />
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={subAssetCategoryData?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
              rowHeight={90}
              {...extraBulkDeleteProps}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("subcategoryMultipleDeleteConfirmationText")}
        singleDeleteText={t("subcategoryDeleteConfirmationText")}
      />
      {addOpen && (
        <AddEditPopupSubCategory
          open={addOpen}
          setOpen={() => setAddOpen(false)}
          id={currentRowData?._id ? currentRowData?._id : ""}
          editTrue={editTrue}
          name={currentRowData?.name ? currentRowData?.name : ""}
          dataState={dataState}
          setDataState={setDataState}
          categoryId={
            currentRowData?.categoryId ? currentRowData?.categoryId : ""
          }
          url={
            currentRowData?.sub_category_image &&
            `${subAssetCategoryData?.image_base_url}/${subAssetCategoryData?.image_container_name}/${currentRowData?.sub_category_image}`
          }
          urlName={
            currentRowData?.sub_category_image
              ? currentRowData?.sub_category_image
              : ""
          }
        />
      )}
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
      {openChangeTitle && (
        <AddEditFileType
          openChangeTitle={openChangeTitle}
          setOpenChangeTitle={setOpenChangeTitle}
          dataState={dataState}
          desc={subAssetCategoryData?.tooltipDescription?.description || ""}
          id={subAssetCategoryData?.tooltipDescription?._id || ""}
          moduleName="subcategory"
        />
      )}
    </FieldManagementStyle>
  );
}

export default AssetSubCategory;
