const { styled } = require("@mui/system");

const HeaderHoverMenuStyles = styled("div")(({ theme }) => ({
  "&.blueCollection": {
    ".headerTextItem": {
      ".collectionText": {
        color: theme.palette.primaryColor.main,
      },
      svg: {
        path: {
          stroke: theme.palette.primaryColor.main,
        },
      },
    },
  },
  ".headerTextItem": {
    display: "flex",
    alignItems: "center",
    gap: "0.375rem",
    cursor: "pointer",
    "&:is(:hover, .active)": {
      "> span": {
        color: "rgba(0, 86, 184, 1)",
      },
      // svg: {
      //   path: {
      //     stroke: theme.palette.primaryColor.main,
      //   },
      // },
    },
    // "&:has(.smallHeaderMenu)": {
    //   position: 'relative',
    //   // ".headerTypeMenuMain ": {
    //   //   width: '260px',
    //   //   padding: '0',
    //   // }
    // }
    "&:has(.moreTypes)": {
      // ".headerTypeMenuMain.megaMenu": {
      //   display: "none",
      // }
    },
  },
  ".headerTypeMenuMain": {
    // backgroundColor: theme.palette.white.main,
    position: "absolute",
    // top: "80px",
    top: "50px",
    left: "0",
    width: "100%",
    padding: "30px 0 0",
    height: "auto",
    ".hoverMenuClose": {
      content: "''",
      position: "fixed",
      height: "100vh",
      background: "rgb(33 42 54 / 32%)",
      width: "100%",
      top: "0",
      zIndex: "-1",
    },
    ".moreTypes": {
      display: "flex",
      background: theme.palette.whiteColor.main,
      padding: "30px 40px",
      gap: "10px 20px",
      borderBottom: "1px solid #f2f2f2",
      flexWrap: "wrap",
      position: "relative",
      zIndex: "1",
      ".headerItmeAndMenu": {
        ".headerTextItem": {
          span: {
            color: theme.palette.blackColor.main,
          },
          svg: {
            path: {
              stroke: theme.palette.blackColor.main,
            },
          },
          "&:is(:hover)": {
            span: {
              color: theme.palette.primaryColor.main,
            },
            svg: {
              path: {
                stroke: theme.palette.primaryColor.main,
              },
            },
          },
        },
        "&.has-child": {
          " .headerTypeMenuMain": {
            ".hoverMenuClose": {
              height: "calc(100vh - 160px)!important",
              top: "160px!important",
            },
          },
        },
        "&:nth-child(2)": {
          ".headerTypeMenuMain": {
            // transform: "translate(-25px, 0px)!important",
          },
        },
      },
    },
    ".headerTypeMenu": {
      display: "flex",
      gap: "40px",
      padding: "0 40px",
      backgroundColor: theme.palette.white.main,
      minHeight: "456px",
      ".noDataFound": {
        width: "100%",
        display: "grid",
        placeItems: "center",
      },
      "@media screen and (width < 1366px)": {
        gap: "15px",
      },
      ".headerRightSection": {
        display: "flex",
        width: "100%",

        ".curatedGallerySections": {
          display: "flex",
          gap: "24px",
          // justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
          ".curatedSectionDatas": {
            display: "flex",
            flexDirection: "column",
            ".curatedHeadLink": {
              textDecoration: "none",
            },
          },
        },
      },
      ".megaMenusCard": {
        padding: "32px 0",
        "&.menuLeftSection": {
          flex: "0 0 17%",
          maxWidth: "350px",
          maxHeight: "485px",
          overflow: "auto",
          paddingRight: "8px",
          "::-webkit-scrollbar": {
            width: "4px;",
            height: "8px",
          },
          "::-webkit-scrollbar-thumb": {
            background: theme.palette.gray_200.main,
          },
          ".leftListHeading": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "14px 16px",
            borderRadius: "8px",
            cursor: "pointer",
            span: {
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              color: theme.palette.gray_800.main,
            },
            "&:hover": {
              span: {
                color: theme.palette.primaryColor.main,
              },
              svg: {
                path: {
                  stroke: theme.palette.primaryColor.main,
                },
              },
            },
            "&.active": {
              backgroundColor: theme.palette.gray_200.main,
              span: {
                color: theme.palette.primaryColor.main,
              },
              svg: {
                path: {
                  stroke: theme.palette.primaryColor.main,
                },
              },
            },
          },
        },
        "&.menuMiddleSection": {
          backgroundColor: theme.palette.gray_200.main,
          flex: "0 0 17%",
          maxWidth: "17%",
          // minWidth: "300px",
          // maxWidth: "350px",
          padding: "32px 24px",
          "@media screen and (width < 1280px)": {
            padding: "32px 15px",
          },
          ".menuMiddleCenter": {
            margin: "16px 0",
            span: {
              color: "#4E555E",
              lineHeight: "0.14px",
              wordBreak: "break-word",
            },
          },
          ".menuMiddleBottom": {
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            span: {
              color: theme.palette.gray_800.main,
              maxWidth: "calc(100% - 30px)",
              wordBreak: "break-word",
            },
            svg: {
              path: {
                stroke: theme.palette.primaryColor.main,
              },
            },
          },
          " .menuMiddleTop": {
            span: {
              color: theme.palette.blackColor.main,
              wordBreak: "break-word",
            },
          },
        },
        "&.headerRightSection": {
          paddingBottom: "32px",
          "@media screen and (width < 1366px)": {
            flex: "0 0 100%",
            maxWidth: "calc(100% - 37%)",
          },
          ".collectionTextCross": {
            ".headerCollectionCross": {
              cursor: "pointer",
              position: "absolute",
              top: "12px",
              right: "16px",
              input: {
                "::placeholder": {
                  color: theme.palette.gray_600.main,
                },
              },
            },
          },
          ".curatedGallerySections": {
            minHeight: "370px",
            ".curatedSections": {
              // flex: "0 0 100%",
              flex: "0 0 25%",
              maxWidth: "calc(25% - 18px)",
              // maxWidth: "calc(100% / 4 - 15px)",
              ".curatedSectionHeading": {
                marginBottom: "24px",
              },
              ".curatedSectionDatas": {
                a: {
                  "&.curatedHeadLink": {
                    lineHeight: "21px",
                    marginBottom: "16px",
                    span: {
                      color: theme.palette.gray_800.main,
                    },
                  },
                  "&:hover": {
                    span: {
                      color: theme.palette.primaryColor.main,
                    },
                  },
                },
              },
              "button.seeAllButton": {
                background: "transparent",
                border: "0",
                height: "auto",
                fontFamily: "Poppins",
                fontSize: "14px!important",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "normal",
                letterSpacing: "0.14px",
                color: "#0056B8",
                cursor: "pointer",
                padding: "0",
              },
              "&:is(.showAll)": {
                flex: "1",
                display: "flex",
                flexWrap: "wrap",
                gap: "0 15px",
                maxWidth: "100%",
                ".curatedSectionHeading": {
                  flex: "0 0 100%",
                  margin: "0",
                },
                ".curatedSectionDatas": {
                  flexBasis: "100%",
                  maxWidth: "calc(100% / 4 - 15px)",
                  button: {
                    textAlign: "left",
                    padding: "0",
                  },
                },
              },
              "&.hideAll": {
                display: "none",
              },
            },
          },
          ".headerCollecRightMain": {
            width: "100%",
            ".headerRightTop": {
              display: "flex",
              gap: "16px",
              flexWrap: "wrap",
              ".selectedCustomTagsPills": {
                flex: "0 0 100%",
                display: "flex",
                alignItems: "flex-start",
                ".customTagPills": {
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                  marginRight: "16px",
                  flex: "1",
                  maxWidth: "max-content",
                },
                ".clearFilterIcon": {
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  minWidth: "100px",
                  marginTop: "8px",
                },
              },
              button: {
                alignItems: "center",
                span: {
                  color: theme.palette.whiteColor.main,
                  textTransform: "math-auto",
                },
                "&:disabled": {
                  span: {
                    color: theme.palette.gray_500.main,
                  },
                },
              },
              ".collectionTextCross": {
                position: "relative",
                flex: "1",
              },
            },
            ".headerCollecRightAlphabet": {
              ".curatedGallerySections": {
                ".noDataFound": {
                  width: "100%",
                  display: "grid",
                  placeItems: "center",
                },
                ".curatedSections": {
                  ".curatedSectionHeading": {
                    marginBottom: "0",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    "button.backToFilters": {
                      background: "transparent",
                      border: "0",
                      padding: "0",
                      height: "auto",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      cursor: "pointer",
                      minWidth: "146px",
                      span: {
                        color: theme.palette.blackColor.main,
                        textTransform: "math-auto",
                      },
                      svg: {
                        maxHeight: "21px",
                        path: {
                          stroke: theme.palette.primaryColor.main,
                        },
                      },
                    },
                    ".clearSelectedTags": {
                      display: "inherit",
                    },
                    label: {
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      width: "100%",
                      "&.showClearFilterButtonCond": {
                        width: "75%",
                      },
                      ".collectionCustomTag": {
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        a: {
                          // width: "max-content",
                          // textAlign: "left !important",
                          // display: "inline-block",
                          // textOverflow: "ellipsis",
                          // whiteSpace: "nowrap",
                          // overflow: "hidden",
                          // maxWidth: "70%",
                        },
                        span: {
                          cursor: "pointer",
                          color: theme.palette.blackColor.main,
                          "&.customTagName ": {
                            width: "auto",
                            textAlign: "left !important",
                            display: "inline-block",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            maxWidth: "185px",
                            // display: "none",
                            "@media screen and (width < 1280px)": {
                              maxWidth: "115px",
                            },
                          },
                          "&.assetNumber": {
                            color: theme.palette.gray_300.main,
                          },
                        },
                        "&:hover": {
                          span: {
                            color: theme.palette.primaryColor.main,
                          },
                        },
                      },
                    },
                  },
                  ".curatedSectionDatas": {
                    marginTop: "16px",
                    minHeight: "328px",
                    ".curatedHeadLink": {
                      marginBottom: "16px",
                      label: {
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                        cursor: "pointer",
                        ".collectionCustomTag": {
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                          "@media screen and (width < 1366px)": {
                            // gap: "4px",
                          },
                          a: {
                            color: theme.palette.blackColor.main,
                            lineHeight: "normal",
                          },
                          span: {
                            "&.customTagName": {
                              width: "auto",
                              textAlign: "left!important",
                              display: "inline-block",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "185px",
                              color: theme.palette.gray_800.main,
                              "@media screen and (width < 1366px)": {
                                maxWidth: "115px",
                              },
                            },
                            "&.assetNumber": {
                              color: theme.palette.gray_300.main,
                              fontWeight: "500",
                            },
                            "@media screen and (width < 1366px)": {
                              // fontSize: "12px",
                            },
                          },
                        },
                        "&:hover": {
                          ".collectionCustomTag": {
                            span: {
                              color: theme.palette.primaryColor.main,
                            },
                          },
                        },
                      },
                      "&:last-child": {
                        // marginBottom: "0",
                      },
                    },
                  },
                },
              },
            },
            ".headerCollectionRightBottom": {
              padding: "16px 40px 0",
              borderTop: `1px solid ${theme.palette.gray_250.main}`,
              margin: "32px -40px 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              cursor: "pointer",
              "@media screen and (width < 1366px)": {
                margin: "32px -15px 0",
                padding: "16px 16px 0",
                width: "100vw",
                maxWidth: "calc(100% + 57px)",
              },
              span: {
                color: theme.palette.blackColor.main,
              },
              svg: {
                path: {
                  stroke: theme.palette.primaryColor.main,
                },
              },
            },
          },
          "&:has(.headerCollectionRightBottom)": {
            paddingBottom: "16px",
          },
        },
      },
    },

    "&.smallHeaderMenu": {
      // ".headerTypeMenu": {
      //   background: 'transparent',
      // },
      ".headerTypeMenu": {
        backgroundColor: "transparent",
        padding: "0",
        minHeight: "unset",
        "+ .hoverMenuClose": {
          display: "none",
        },
        ".menuLeftSection": {
          flex: "0 0 100%",
          padding: "0",
          ".leftListHeading": {
            backgroundColor: theme.palette.whiteColor.main,
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",
            sapn: {
              color: theme.palette.gray_600.main,
            },
            "&.active": {
              backgroundColor: theme.palette.whiteColor.main,
            },
            "&:hover": {
              backgroundColor: theme.palette.gray_200.main,
              span: {
                color: theme.palette.primaryColor.main,
              },
            },
          },
        },
        ".menuMiddleSection, .headerRightSection": {
          display: "none",
        },
        ".headerItmeAndMenu": {
          position: "relative",
        },
      },
      ".headerItmeAndMenu ": {
        position: "relative",
        ".headerTypeMenuMain": {
          width: "231px",
          padding: "0",
          transform: "translateX(-33px)",
          top: "19px",
          paddingTop: "33px",
          ".headerTypeMenu": {
            ".menuLeftSection": {
              " .leftListHeading ": {},
            },
          },
        },
        // "&.inactive": {
        //   ".headerTypeMenuMain ": {
        //     display: "none",
        //   }
        // },

        "&:has-child": {
          "&:hover": {
            ".headerTypeMenuMain": {
              display: "block",
            },
          },
        },
      },
    },
  },
}));

export default HeaderHoverMenuStyles;
