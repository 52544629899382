import _ from "lodash";
import {
  localConfig,
  retrieveDataFromLocalStorage,
} from "../utils/localStorage";

export function _getToken() {
  try {
    const token = retrieveDataFromLocalStorage(localConfig.userLoginToken)
      ? retrieveDataFromLocalStorage(localConfig.userLoginToken)
      : false;
    return { token, status: !_.isEmpty(token) };
  } catch (e) {
    return { token: null, status: false };
  }
}

export function capitalizeWords(str) {
  return str
    .split(" ") // Split the string into an array of words
    .map((word) => {
      // Capitalize the first letter of each word and lower the rest
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" "); // Join the array back into a string
}
