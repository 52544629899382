import React from "react";

const FolderIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 5.46536C2 4.71862 2 4.34526 2.14533 4.06004C2.27315 3.80916 2.47713 3.60518 2.72801 3.47736C3.01323 3.33203 3.3866 3.33203 4.13333 3.33203H6.44968C6.7758 3.33203 6.93887 3.33203 7.09233 3.36887C7.22833 3.40153 7.3584 3.4554 7.47773 3.52851C7.61227 3.61096 7.7276 3.72626 7.9582 3.95687L8.0418 4.04052C8.2724 4.27113 8.38773 4.38643 8.52227 4.46888C8.6416 4.54199 8.77167 4.59586 8.90767 4.62852C9.06113 4.66536 9.2242 4.66536 9.55033 4.66536H11.8667C12.6134 4.66536 12.9868 4.66536 13.272 4.81069C13.5229 4.93852 13.7269 5.14249 13.8547 5.39338C14 5.67859 14 6.05196 14 6.7987V10.532C14 11.2788 14 11.6522 13.8547 11.9374C13.7269 12.1882 13.5229 12.3922 13.272 12.52C12.9868 12.6654 12.6134 12.6654 11.8667 12.6654H4.13333C3.38659 12.6654 3.01323 12.6654 2.72801 12.52C2.47713 12.3922 2.27315 12.1882 2.14533 11.9374C2 11.6522 2 11.2788 2 10.532V5.46536Z"
        stroke="#BDC0C4"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FolderIcon;
