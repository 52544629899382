import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { register } from "swiper/element/bundle";
import HomeSliderTop from "../HomeSliderTop";
import { feGetAllSubCategoriesApi } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategoryAction";
import { feAllSubCategories } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategorySlice";
import notFoundImage from "./images/imageNotFound.png";
import { routeConfigs } from "utils/routeConfig";
import TextTag, { textVariants } from "components/Typography/TextTag";
import {
  quickThumbData,
  selectQuickThumbnailData,
} from "pages/Admin-Pages/quick-Thumbs/features/quickThumbSlice";
import {
  getQuickThumb,
  getQuickThumbnailApi,
} from "pages/Admin-Pages/quick-Thumbs/features/quickThumbAction";
import FindLookingForStyles from "./FindLookingForStyles";

const FindLookingForSection = () => {
  const { t } = useTranslation();

  const swiperElRef = useRef(null);
  const dispatch = useDispatch();
  const allSubCategory = useSelector(feAllSubCategories);
  const quickThumbnailData = useSelector(selectQuickThumbnailData);
  const thumbData = useSelector(quickThumbData);
  const imageBasePath = `${thumbData?.image_base_url}/${thumbData?.image_container_name}`;
  const SLIDESVIEW = "4.186";

  const result = thumbData?.fields
    ?.map((item) => {
      if (item.category_data.length > 0) {
        return {
          type: "category",
          ...item.category_data?.[0],
        };
      } else if (item.sub_category_data.length > 0) {
        return {
          type: "sub-category",
          ...item.sub_category_data?.[0],
        };
      } else {
        return null;
      }
    })
    .filter(Boolean);

  const swiperSettings = {
    init: "false",
    "slides-per-view": SLIDESVIEW,
    "slides-offset-before": "0",
    "slides-offset-after": "64",
    "space-between": "32",
  };

  useEffect(() => {
    dispatch(getQuickThumbnailApi());
    dispatch(
      getQuickThumb({
        search: "",
        limit: 2000,
        page: 1,
        sortOrder: "asc",
        sortBy: "display_index",
        column_name: "",
        column_values: "",
        latest_action: "",
        filtersQueryString: [],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(feGetAllSubCategoriesApi(controller.signal));
    return () => {
      controller.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    register();
    swiperElRef.current.setAttribute("slides-per-view", SLIDESVIEW);
    swiperElRef.current.initialize();
  }, [allSubCategory]);

  const SlideComp = ({ imgData, imageSrcKey, imgSrc, type }) => {
    const [active, setActive] = useState(false);
    return (
      <Link
        to={`${routeConfigs.searchPage}?${type}=${imgData?._id}`}
        className={`findLookingForSlideMain ${active ? "active" : ""}`}
      >
        <div className="findLookingSlide">
          <img
            src={
              imgSrc && imgData?.[imageSrcKey]
                ? `${imgSrc}/${imgData?.[imageSrcKey]}`
                : notFoundImage
            }
            alt={imgData?.name}
            onMouseOver={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
          />
          <TextTag variant={textVariants._16px.semiBold}>
            {imgData?.name}
          </TextTag>
          <span></span>
        </div>
        <div className="hoverSlide">
          <div className="slideHoverTop">
            {/* not needed anymore */}
            {/*<span>
              {"#"}
              {imgData?.search_code}
            </span> */}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <FindLookingForStyles>
      <div className="findLookingForSectionMain">
        <HomeSliderTop
          sectionHeading={
            quickThumbnailData?.title
              ? quickThumbnailData?.title
              : t("findWhatYouAreLookingFor")
          }
          swiperElRef={swiperElRef}
          slidesPerView={SLIDESVIEW}
        />
        <div className="featured_slider">
          <swiper-container ref={swiperElRef} {...swiperSettings}>
            {result?.map((imgData, i) => (
              <swiper-slide key={i}>
                <SlideComp
                  imgData={imgData}
                  imageSrcKey={
                    imgData?.type === "category"
                      ? "category_image"
                      : "sub_category_image"
                  }
                  imgSrc={imageBasePath}
                  type={imgData?.type === "category" ? "cat" : "subCat"}
                />
              </swiper-slide>
            ))}
          </swiper-container>
        </div>
      </div>
    </FindLookingForStyles>
  );
};

export default FindLookingForSection;
