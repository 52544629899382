import React from "react";
import { styled } from "@mui/system";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { buttonVariants } from "./MainButton";

const ButtonMiniIconStyles = styled("button")(({ theme, variant }) => ({
  backgroundColor: theme.palette.primaryColor.main,
  color: theme.palette.whiteColor.main,
  border: `1px solid ${theme.palette.primaryColor.main}`,
  borderRadius: "0.5rem",
  cursor: "pointer",
  padding: "8px 16px 8px 16px",
  height: "unset",
  display: "flex",
  gap: "0.5rem",
  svg: {
    height: "1rem",
    width: "1rem",
  },
  "&:is(:hover, .active)": {
    backgroundColor: theme.palette.darkBlueColor.main,
  },
  ":disabled": {
    cursor: "auto",
    backgroundColor: theme.palette.gray_300.main,
    borderColor: theme.palette.gray_300.main,
    color: theme.palette.gray_500.main,
  },
  ...(variant === buttonVariants.red && {
    background: "#F04848",
    color: theme.palette.white.main,
    border: "none",
    display: "flex",
    alignItems: "center",
    "&:is(:hover, .active)": {
      backgroundColor: "#F04848",
    },
    svg: {
      path: {
        stroke: theme.palette.white.main,
      },
    },
  }),
}));

export const ButtonMiniIconWithChildren = ({ children, ...props }) => {
  return <ButtonMiniIconStyles {...props}>{children}</ButtonMiniIconStyles>;
};

const ButtonMiniIcon = ({ children, ...props }) => {
  return (
    <ButtonMiniIconStyles {...props}>
      <TextTag variant={textVariants._12px.medium}>{children}</TextTag>
      <ArrowForwardIosRoundedIcon />
    </ButtonMiniIconStyles>
  );
};

export default ButtonMiniIcon;
