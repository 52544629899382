import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import FilterIcon from "components/Icons/FilterIcon/FilterIcon";
import PlusMinusPill from "components/Pills/PlusMinusPill";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { contributerDataState } from "pages/User-Pages/ContributePage/features/contributionSlice";
import { getCustomTagDatas } from "pages/User-Pages/ContributePage/features/contributionAction";
import TextfieldWithIcon from "components/Textfield/TextfieldwithIcon";
import CrossIconPill from "components/Pills/CrossIconPill";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import AITagsPopup from "./AITagsPopup";
import {
  aiKeywordsData,
  assetsData,
  changeAssetsFinalData,
} from "pages/User-Pages/UploadScreen/features/uploadScreenSlice";
import ButtonOutlineIcon from "components/Button/ButtonOutlineIcon";
import AIGenerateIcon from "components/Icons/AIGenerateIcon/AIGenerateIcon";
import TooltipWrapper from "components/TooltipComponent/TooltipWrapper";
import KeyWordStyle from "pages/User-Pages/ContributePage/Components/Keywords/KeyWordStyle";

const TagsSection = ({ checkedAssetIds, fileExtension, imageTypes }) => {
  const dispatch = useDispatch();
  const { customTagData: customTagData1 } = useSelector(contributerDataState);
  const allAssetsData = useSelector(assetsData);
  const allFinalAiKeywords = useSelector(aiKeywordsData);

  const keyWordArr = allAssetsData?.[checkedAssetIds[0]]?.keyWordArr || [];

  const [customTagData, setCustomTagData] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [openAiPopup, setOpenAiPopup] = useState(false);

  useEffect(() => {
    dispatch(getCustomTagDatas());
  }, [dispatch]);

  useEffect(() => {
    //search functionality
    if (searchQuery && filteredData.length > 0) {
      const matchingIds = filteredData
        .filter((item) => item.autoExpand)
        .map((item) => item._id);

      setExpandedItems(matchingIds);
    } else {
      setExpandedItems([]);
    }
    //eslint-disable-next-line
  }, [searchQuery]);

  useEffect(() => {
    if (!customTagData1) return;

    //checking if any ai keywords are selected from popup
    const hasFinalAiKeywords =
      allFinalAiKeywords?.[checkedAssetIds[0]]?.length > 0;

    const updatedKeywordArr = hasFinalAiKeywords
      ? keyWordArr.filter(
          (tagData) =>
            tagData?.custom_tag_id !== "0" ||
            allFinalAiKeywords?.[checkedAssetIds[0]].includes(tagData?._id),
        )
      : keyWordArr;

    dispatch(
      changeAssetsFinalData({
        ...allAssetsData,
        [checkedAssetIds[0]]: {
          ...allAssetsData[checkedAssetIds[0]],
          keyWordArr: updatedKeywordArr,
        },
      }),
    );

    const updatedCustomTagData = hasFinalAiKeywords
      ? [
          {
            _id: "0",
            name: "AI Keywords",
            custom_tags_data: allFinalAiKeywords?.[checkedAssetIds[0]].map(
              (keyword) => ({
                _id: keyword,
                isDeleted: "false",
                enabled: "true",
                name: keyword,
                custom_tag_id: "0",
              }),
            ),
          },
          ...customTagData1?.custom_tag_all,
        ]
      : customTagData1?.custom_tag_all;

    setCustomTagData(updatedCustomTagData);
    //eslint-disable-next-line
  }, [customTagData1, allFinalAiKeywords, checkedAssetIds]);

  const keyWordIds = keyWordArr?.reduce((accumulator, current) => {
    accumulator.push(current?._id);
    return accumulator;
  }, []);

  const handleExpandAll = () => {
    if (expandedItems.length === customTagData?.length) {
      setExpandedItems([]);
    } else {
      setExpandedItems(customTagData?.map((item) => item._id));
    }
  };

  const handleIndividualExpand = (panel) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(panel)
        ? prevExpandedItems.filter((item) => item !== panel)
        : [...prevExpandedItems, panel],
    );
  };

  const toogleKeywords = (keyword) => {
    const updatedKeywordArr = keyWordArr.some(
      (item) => item._id === keyword._id,
    )
      ? keyWordArr.filter((item) => item._id !== keyword._id)
      : [...keyWordArr, keyword];
    dispatch(
      changeAssetsFinalData({
        ...allAssetsData,
        [checkedAssetIds[0]]: {
          ...allAssetsData[checkedAssetIds[0]],
          keyWordArr: updatedKeywordArr,
        },
      }),
    );
  };

  const filteredData =
    customTagData && Array.isArray(customTagData)
      ? customTagData.map((item) => {
          const hasMatchingChild =
            Array.isArray(item.custom_tags_data) &&
            item.custom_tags_data.some((child) =>
              child.name.toLowerCase().includes(searchQuery.toLowerCase()),
            );

          return {
            ...item,
            isVisible: !searchQuery || hasMatchingChild,
            autoExpand: hasMatchingChild,
          };
        })
      : [];

  const removeSelectedItemsHandler = async (event, keyWordsArray) => {
    event.stopPropagation();

    const updatedKeywordArr = keyWordArr?.filter(
      (value) => !keyWordsArray?.some((keys) => value?._id === keys?._id),
    );

    dispatch(
      changeAssetsFinalData({
        ...allAssetsData,
        [checkedAssetIds[0]]: {
          ...allAssetsData[checkedAssetIds[0]],
          keyWordArr: updatedKeywordArr,
        },
      }),
    );
  };

  const handleCrossIcon = (id) => {
    //removing a specific tag from the applied tags
    const data = keyWordArr?.filter((val) => val?._id !== id);
    dispatch(
      changeAssetsFinalData({
        ...allAssetsData,
        [checkedAssetIds[0]]: {
          ...allAssetsData[checkedAssetIds[0]],
          keyWordArr: data,
        },
      }),
    );
  };

  const removeAllTags = () => {
    //clearing all the applied tags
    dispatch(
      changeAssetsFinalData({
        ...allAssetsData,
        [checkedAssetIds[0]]: {
          ...allAssetsData[checkedAssetIds[0]],
          keyWordArr: [],
        },
      }),
    );
  };

  return (
    <KeyWordStyle className="uploadScreen">
      <div className="titleAndButton">
        <HeadingTag variant={headingVariants.h3}>{t("tags")}</HeadingTag>
        <div className="headingButtons">
          {imageTypes?.includes(fileExtension) && (
            <ButtonOutlineIcon
              iconComp={<AIGenerateIcon />}
              title={t("aiKeywords")}
              className={"shortOutlineButton"}
              onClick={() => {
                setOpenAiPopup(true);
              }}
            />
          )}
          <MainButton
            title={
              expandedItems?.length === customTagData?.length
                ? t("collapseAll")
                : t("expandAll")
            }
            variant={buttonVariants.grey}
            onClick={handleExpandAll}
          />
        </div>
      </div>
      <div className="keyWordLeftSec">
        {keyWordArr?.length > 0 && (
          <div className="appliedTagsTop">
            <TextTag variant={textVariants._14px.semiBold}>
              {t("tagsApplied")}{" "}
              <TextTag
                variant={textVariants._12px.medium}
                className="numberText"
              >
                ({keyWordArr?.length})
              </TextTag>
            </TextTag>
            <div className="clearTags" onClick={removeAllTags}>
              <FilterIcon />
              <TextTag variant={textVariants._12px.medium}>
                {t("clearAllTags")}
              </TextTag>
            </div>
          </div>
        )}
        {keyWordArr?.length > 0 && (
          <div className="keyWordWordsClass">
            <div className="appliedTagsTop">
              <TextTag variant={textVariants._12px.semiBold}>
                {t("tags")}{" "}
                <TextTag
                  variant={textVariants._12px.medium}
                  className="numberText"
                >
                  ({keyWordArr?.length})
                </TextTag>
              </TextTag>
              <FilterIcon onClick={removeAllTags} />
            </div>
            <ul>
              {keyWordArr?.length > 0 ? (
                keyWordArr?.map((val, index) => {
                  return (
                    <CrossIconPill
                      name={val?.name}
                      isActive={true}
                      onCrossClick={() => handleCrossIcon(val?._id)}
                      key={index}
                    />
                  );
                })
              ) : (
                <TextTag
                  variant={textVariants._16px.regular}
                  className="noKeyWords"
                >
                  {t("noKeywordsFound")}
                </TextTag>
              )}
            </ul>
          </div>
        )}
      </div>
      <TextfieldWithIcon
        placeholder={t("enterKeywords")}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <div className="keyWordsAccording">
        {filteredData?.map((item) => (
          <Accordion
            key={item._id}
            expanded={expandedItems?.includes(item._id)}
            onChange={() => handleIndividualExpand(item._id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="updown-aside" />}
              aria-controls={`${item.name}-content`}
              id={`${item.name}-header`}
            >
              <TextTag variant={textVariants._16px.semiBold}>
                {item.name}{" "}
                <span className="keywordNumbers">
                  ({item.custom_tags_data?.length})
                </span>
              </TextTag>
              <div className="filter_cstm">
                <TooltipWrapper
                  className={"accordianTooltip"}
                  tooltipText={t("clearFilter")}
                  ellipses={true}
                >
                  <FilterIcon
                    onClick={(event) => {
                      removeSelectedItemsHandler(event, item.custom_tags_data);
                    }}
                    height={20}
                    width={45}
                  />
                </TooltipWrapper>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <ul>
                {item.custom_tags_data?.length > 0 ? (
                  item.custom_tags_data?.map((child) => (
                    <PlusMinusPill
                      key={child?._id}
                      isSelected={keyWordIds?.includes(child?._id)}
                      onClick={() => {
                        toogleKeywords(child);
                      }}
                      name={child.name}
                    />
                  ))
                ) : (
                  <TextTag
                    variant={textVariants._16px.regular}
                    className="noKeyWordFound"
                  >
                    {t("noKeywordsFound")}
                  </TextTag>
                )}
              </ul>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      {openAiPopup && (
        <AITagsPopup
          setOpenAiPopup={setOpenAiPopup}
          checkedAssetIds={checkedAssetIds}
        />
      )}
    </KeyWordStyle>
  );
};

export default TagsSection;
