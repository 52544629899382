import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { ClickAwayListener } from "@mui/material";
import { ContributePopupStyles } from "components/ContributePopup/contributePopupStyles";
import ContributePopupHeader from "components/ContributePopup/ContributePopupHeader/ContributePopupHeader";
import { PopupFooterStyles } from "components/ContributePopup/ContributePopupFooter/ContributePopupFooter";
import MainButton, {
  buttonVariants,
  MainButtonWithChildren,
} from "components/Button/MainButton";
import ButtonOutlineIcon from "components/Button/ButtonOutlineIcon";
import AIGenerateIcon from "components/Icons/AIGenerateIcon/AIGenerateIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import ContributePopTextAreaComp from "components/Textfield/ContributePopTextAreaComp";
import { getAsssetAiDescription } from "pages/User-Pages/ContributePage/features/contributionAction";
import { aiKeyWordsLoading } from "pages/User-Pages/ContributePage/features/contributionSlice";
import { Loader } from "components/Loader/Loader";
import LoopIcon from "components/Icons/LoopIcon/LoopIcon";
import CopyIcon from "components/Icons/CopyIcon/CopyIcon";
import { copyToClipboard } from "utils/copyToClipboard";
import {
  assetsData,
  changeAssetsFinalData,
} from "pages/User-Pages/UploadScreen/features/uploadScreenSlice";

const AIDescriptionPopup = ({ setOpenPopup, assetId }) => {
  const dispatch = useDispatch();
  const loading = useSelector(aiKeyWordsLoading);
  const allAssetsData = useSelector(assetsData);

  const [aiDescription, setAiDescription] = useState("");
  const [runApi, setRunApi] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        dispatch(getAsssetAiDescription({ id: assetId, signal })).then(
          (res) => {
            if (res?.error) return;
            setAiDescription(res.payload?.description);
          },
        );
      } catch (e) {}
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [assetId, dispatch, runApi]);

  const handlePopupClose = () => {
    setOpenPopup(false);
  };

  return (
    <ContributePopupStyles className="aiTagsPopup">
      <ClickAwayListener onClickAway={handlePopupClose}>
        <div className="contributePopupMain">
          <ContributePopupHeader
            handlePopupClose={handlePopupClose}
            popupTitle={t("aiAssetDescription")}
          />
          {loading ? (
            <Loader />
          ) : (
            <div className="aiDescPopupBody">
              <div className="descSection">
                <TextTag variant={textVariants._12px.semiBold}>
                  {t("generatedText")}
                </TextTag>
                <ContributePopTextAreaComp
                  onChange={(e) => {
                    setAiDescription(e.target.value);
                  }}
                  value={aiDescription}
                  id="aiDescription"
                />
                <ButtonOutlineIcon
                  iconComp={<AIGenerateIcon />}
                  title={t("generateAgain")}
                  className={"generateDesc"}
                  onClick={() => {
                    setRunApi((prev) => !prev);
                  }}
                />
              </div>
            </div>
          )}
          <PopupFooterStyles className="addAlbunFooter">
            <MainButton
              onClick={handlePopupClose}
              variant={buttonVariants.grey}
              title={t("cancel")}
            />
            <MainButtonWithChildren
              onClick={() => {
                copyToClipboard("aiDescription");
              }}
              variant={buttonVariants.blue}
              className="copyButton"
              disabled={aiDescription?.length <= 0}
            >
              <CopyIcon />
            </MainButtonWithChildren>
            <MainButtonWithChildren
              onClick={() => {
                handlePopupClose();
                dispatch(
                  changeAssetsFinalData({
                    ...allAssetsData,
                    [assetId]: {
                      ...allAssetsData[assetId],
                      ai_desc: aiDescription,
                    },
                  }),
                );
              }}
              variant={buttonVariants.blue}
              className="deleteBtn"
              disabled={aiDescription?.length <= 0}
            >
              <LoopIcon />
              <TextTag variant={textVariants?._14px?.medium}>
                {t("saveAndReplace")}
              </TextTag>
            </MainButtonWithChildren>
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

export default AIDescriptionPopup;
