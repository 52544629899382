import { t } from "i18next";
import { ClickAwayListener } from "@mui/material";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import { PopupFooterStyles } from "components/ContributePopup/ContributePopupFooter/ContributePopupFooter";
import ContributePopupHeader from "components/ContributePopup/ContributePopupHeader/ContributePopupHeader";
import { ContributePopupStyles } from "components/ContributePopup/contributePopupStyles";

const DeleteAssetPopup = ({
  popupTitle,
  setPopupOpen,
  onYesClick,
  onPopupClose, //optional
  setToggleSnack,
}) => {
  const handlePopupClose = () => {
    if (onPopupClose) onPopupClose();
    setPopupOpen(false);
  };

  return (
    <ContributePopupStyles className="addAlbumPopup">
      <ClickAwayListener onClickAway={handlePopupClose}>
        <div className="contributePopupMain">
          <ContributePopupHeader
            handlePopupClose={handlePopupClose}
            popupTitle={popupTitle}
          />
          <PopupFooterStyles className="addAlbunFooter uploadDelete">
            <MainButton
              onClick={handlePopupClose}
              variant={buttonVariants.grey}
              title={t("cancel")}
            />
            <MainButton
              onClick={onYesClick}
              variant={buttonVariants.red}
              title={t("delete")}
              className="deleteBtn"
            />
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

export default DeleteAssetPopup;
