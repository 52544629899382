import React from "react";

const TagIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_6765_7020)">
        <path
          d="M5.33398 5.33203H5.34065M1.33398 3.46536V6.44837C1.33398 6.7745 1.33398 6.93756 1.37082 7.09103C1.40348 7.22703 1.45736 7.3571 1.53046 7.47643C1.61292 7.61097 1.72822 7.7263 1.95882 7.9569L7.07125 13.0693C7.86325 13.8613 8.25932 14.2574 8.71592 14.4057C9.11765 14.5362 9.55032 14.5362 9.95198 14.4057C10.4087 14.2574 10.8047 13.8613 11.5967 13.0693L13.0713 11.5948C13.8633 10.8028 14.2593 10.4067 14.4077 9.95003C14.5382 9.54836 14.5382 9.1157 14.4077 8.71396C14.2593 8.25736 13.8633 7.8613 13.0713 7.0693L7.95885 1.95687C7.72825 1.72626 7.61292 1.61096 7.47838 1.52851C7.35905 1.4554 7.22898 1.40153 7.09298 1.36887C6.93952 1.33203 6.77645 1.33203 6.45033 1.33203H3.46732C2.72058 1.33203 2.34721 1.33203 2.062 1.47736C1.81112 1.60518 1.60714 1.80916 1.47931 2.06004C1.33398 2.34526 1.33398 2.71862 1.33398 3.46536ZM5.66732 5.33203C5.66732 5.51612 5.51808 5.66536 5.33398 5.66536C5.14989 5.66536 5.00065 5.51612 5.00065 5.33203C5.00065 5.14794 5.14989 4.9987 5.33398 4.9987C5.51808 4.9987 5.66732 5.14794 5.66732 5.33203Z"
          stroke="#676D75"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_6765_7020">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TagIcon;
