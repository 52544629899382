import { useEffect, useState } from "react";

export const useDebounce = (state, delay = 500) => {
  const [debounceValue, setDebounceValue] = useState();
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setDebounceValue(state);
    }, delay);
    return () => clearTimeout(timeOut);
  }, [state, delay]);

  return debounceValue;
};
