import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import TextTag, { textVariants } from "components/Typography/TextTag";
import AssetUploadIcon from "components/Icons/AssetUploadIcon/AssetUploadIcon";
import { getCustomTagDatas } from "pages/User-Pages/ContributePage/features/contributionAction";
import FilePropertiesSection from "./FilePropertiesSection/FilePropertiesSection";
import TagsSection from "./TagsSection/TagsSection";
import MetaDataSection from "./MetadataSection/MetadataSection";
import FeTabs from "pages/Components/Tabs/FeTabs";
import { uploadScreenTabs } from "pages/Components/Tabs/TabsConstant";
import { assetFileProperties } from "pages/User-Pages/ContributePage/features/contributionSlice";

const imageTypes = [
  "JPG",
  "JPEG",
  "PNG",
  "GIF",
  "BMP",
  "TIFF",
  "TIF",
  "SVG",
  "WEBP",
  "HEIC",
  "HEIF",
  "ICO",
  "RAW",
  "PSD",
  "AI",
  "EPS",
  "INDD",
];

const RightUploadSection = (props) => {
  const { assetsLength, checkedAssetIds, files } = props;

  const fileExtension = files
    ?.find((x) => x.id === checkedAssetIds[0])
    ?.name?.split(".")
    .pop()
    ?.toUpperCase();

  const noAssetUploadedText = t("uploadScreenFirstText");
  const noAssetSelectedTitle = t("selectAssetsToSeeInfo");

  const dispatch = useDispatch();

  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    dispatch(getCustomTagDatas());
  }, [dispatch]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <FeTabs
        tabValue={tabValue}
        handleTabChange={handleTabChange}
        disabled={
          !assetsLength || assetsLength <= 0 || checkedAssetIds?.length !== 1
        }
        tabsDataArray={uploadScreenTabs}
      />
      <div
        className={`assetsTAbsData startUploadingFiles ${
          checkedAssetIds?.length === 1 ? "showSelectedData" : ""
        }`}
      >
        <div className="innerAssetsDataBlock">
          {assetsLength <= 0 ? (
            <TextTag
              className={"noAssetsUploaded"}
              variant={textVariants._12px.medium}
            >
              {noAssetUploadedText}
            </TextTag>
          ) : checkedAssetIds?.length !== 1 ? (
            <TextTag
              className={"noAssetsUploaded"}
              variant={textVariants._12px.medium}
            >
              <AssetUploadIcon />
              {noAssetSelectedTitle}
            </TextTag>
          ) : (
            <TabElements
              value={tabValue}
              checkedAssetIds={checkedAssetIds}
              fileExtension={fileExtension}
            />
          )}
        </div>
      </div>
    </>
  );
};

const TabElements = ({ value, checkedAssetIds, fileExtension }) => {
  const { metadata } = useSelector(assetFileProperties);
  return (
    <>
      {value === 0 && (
        <FilePropertiesSection
          checkedAssetIds={checkedAssetIds}
          metadata={metadata}
        />
      )}
      {value === 1 && (
        <MetaDataSection
          checkedAssetIds={checkedAssetIds}
          imageTypes={imageTypes}
          fileExtension={fileExtension}
        />
      )}
      {value === 2 && (
        <TagsSection
          checkedAssetIds={checkedAssetIds}
          imageTypes={imageTypes}
          fileExtension={fileExtension}
        />
      )}
    </>
  );
};

export default RightUploadSection;
