import React from "react";

const AIGenerateIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.33333 14L3.33333 11.3333M4.66667 12.6667L2 12.6667M3.33333 4.66667L3.33333 2M4.66667 3.33333L2 3.33333M9.33333 12.6667L10.3133 10.1819C10.4386 9.86422 10.5012 9.7054 10.5972 9.57148C10.6822 9.45277 10.7861 9.34883 10.9048 9.2638C11.0387 9.16793 11.1976 9.10527 11.5152 8.98L14 8L11.5152 7.02C11.1976 6.89473 11.0387 6.83207 10.9048 6.7362C10.7861 6.65113 10.6822 6.5472 10.5972 6.42853C10.5012 6.2946 10.4386 6.1358 10.3133 5.81813L9.33333 3.33333L8.35333 5.81813C8.22807 6.1358 8.1654 6.2946 8.06953 6.42853C7.98447 6.5472 7.88053 6.65113 7.76187 6.7362C7.62793 6.83207 7.46913 6.89473 7.15147 7.02L4.66667 8L7.15147 8.98C7.46913 9.10527 7.62793 9.16793 7.76187 9.2638C7.88053 9.34883 7.98447 9.45277 8.06953 9.57148C8.1654 9.7054 8.22807 9.86422 8.35333 10.1819L9.33333 12.6667Z"
        stroke="#212A36"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AIGenerateIcon;
