import { useState } from "react";
import { t } from "i18next";
import { styled } from "@mui/system";
import TextTag, { textVariants } from "components/Typography/TextTag";
import TrashIconOutline from "components/Icons/Trash/TrashIconOutline";
import { useDispatch } from "react-redux";
import { removeTemporaryFiles } from "pages/User-Pages/ContributePage/features/contributionSlice";
import { ButtonMiniIconWithChildren } from "components/Button/ButtonMiniIcon";
import { buttonVariants } from "components/Button/MainButton";

const SnackBodyStyles = styled("div")(({ theme }) => ({}));

export const AssetsNotUploadedSnackBody = ({
  reason,
  rejectedFiles,
  handleClose,
}) => {
  const dispatch = useDispatch();
  const bodyText = t("assetsNotDeleted", { reason });

  const [showAll, setShowAll] = useState(false);
  const assets = showAll ? rejectedFiles : rejectedFiles?.slice(0, 2);

  const handleRemoveErroredAssets = () => {
    for (const rejectedFile of rejectedFiles) {
      dispatch(removeTemporaryFiles(rejectedFile?.randomId));
    }
    handleClose();
  };

  return (
    <SnackBodyStyles>
      <TextTag variant={textVariants._12px.regular}>{bodyText}</TextTag>
      <div className="rejectedAssetList">
        {assets?.map((data, index) => (
          <div className="assetName" key={index}>
            <TextTag variant={textVariants._12px.regular}>{data?.name}</TextTag>
          </div>
        ))}
      </div>
      {rejectedFiles?.length > 2 &&
        (showAll ? (
          <button className="seeAllLess" onClick={() => setShowAll(false)}>
            <TextTag variant={textVariants._12px.medium}>{t("back")}</TextTag>
          </button>
        ) : (
          <button className="seeAllLess" onClick={() => setShowAll(true)}>
            <TextTag variant={textVariants._12px.medium}>{t("seeAll")}</TextTag>
          </button>
        ))}
      <ButtonMiniIconWithChildren
        className="remove-asset-btn"
        onClick={handleRemoveErroredAssets}
        variant={buttonVariants.red}
      >
        <TrashIconOutline />{" "}
        <TextTag variant={textVariants._12px.semiBold}>
          {t("Remove From Upload")}
        </TextTag>
      </ButtonMiniIconWithChildren>
    </SnackBodyStyles>
  );
};
