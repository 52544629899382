import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import axiosConfig from "utils/axiosConfig";
import { apiRoutes } from "utils/apiRoutes";

export const cancelCollectionAssetUpload = createAsyncThunk(
  "uploadScreen/cancelCollectionAssetUpload",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.cancelCollectionAssetUpload, data),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//linkAssetToAlbums
export const linkAssetToAlbums = createAsyncThunk(
  "uploadScreen/linkAssetToAlbums",
  async (data) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.linkAssetToAlbums}/${data.collection_id}`,
            data.body,
          ),
        null,
        //if notoast then send false to show no toast
        data.body?.noToast ? false : true,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//publishAssetUsingAssetId
export const publishAssetUsingAssetId = createAsyncThunk(
  "uploadScreen/publishAssetUsingAssetId",
  async (data) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.publishAssetUsingAssetId}/${data.asset_id}`,
            data.body,
          ),
        null,
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
