import React, { useRef, useState } from "react";
import HeaderHoverMenuStyles from "./HeaderHoverMenuStyles";
import TextTag, { textVariants } from "components/Typography/TextTag";
import ArrowUpIcon from "components/Icons/ArrowIcons/ArrowUpIcon";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";
import { headerMenuObj } from "../../HeaderNew";
import HeaderTypeMenu from "./HeaderTypeMenu/HeaderTypeMenu";
import HeaderCollectionMenu from "./HeaderCollectionMenu/HeaderCollectionMenu";
import { routeConfigs } from "utils/routeConfig";

const HeaderHoverMenu = (props) => {
  const {
    itemName,
    headerTypeData,
    menuType,
    allTypes,
    activeTypeIndex,
    setActiveTypeIndex,
    index,
  } = props;
  const [isActive, setIsActive] = useState(false);

  const timeoutRef = useRef(null);

  // Condition for making collection text on header blue when on upload assets screen
  const blueCollection =
    menuType === headerMenuObj?.collection &&
    window.location.pathname === routeConfigs.uploadScreen;

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      setIsActive(true);
      if (setActiveTypeIndex && index) {
        setActiveTypeIndex(index);
      }
    }, 200);
  };

  const handleMouseLeave = () => {
    setIsActive(false);
    clearTimeout(timeoutRef.current);
  };

  const smallHeaderMenu =
    headerTypeData?.category_count === 1 &&
    headerTypeData?.linked_curated_gallery === 0;

  return (
    <HeaderHoverMenuStyles
      className={`headerItmeAndMenu ${isActive ? "active" : "inactive"} ${
        smallHeaderMenu && menuType === headerMenuObj?.type
          ? "smallHeaderMenu2"
          : ""
      } ${blueCollection ? "blueCollection" : ""}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // onClick={() => {
      //   setIsActive(true);
      //   setActiveTypeIndex && index && setActiveTypeIndex(index);
      // }}
      {...props}
    >
      <div className="headerTextItem">
        <TextTag className="collectionText" variant={textVariants._14px.medium}>
          {itemName}
        </TextTag>
        {(menuType === headerMenuObj?.more ||
          menuType === headerMenuObj?.collection ||
          headerTypeData?.category_count > 0) &&
          (headerTypeData?.noDropdown &&
          headerTypeData?.category_count === 0 ? (
            ""
          ) : isActive ? (
            <ArrowUpIcon />
          ) : (
            <ArrowDownIcon />
          ))}
      </div>
      {isActive && (
        <div className="headerMenu">
          {(menuType === headerMenuObj?.type ||
            menuType === headerMenuObj?.more) &&
            (menuType === headerMenuObj?.more ||
              headerTypeData?.category_count > 0) && (
              <HeaderTypeMenu
                menuType={menuType}
                headerTypeData={headerTypeData}
                allTypes={allTypes}
                activeTypeIndex={activeTypeIndex}
                setActiveTypeIndex={setActiveTypeIndex}
                handleMouseLeave={handleMouseLeave}
              />
            )}
          {menuType === headerMenuObj?.collection && (
            <HeaderCollectionMenu handleMouseLeave={handleMouseLeave} />
          )}
        </div>
      )}
    </HeaderHoverMenuStyles>
  );
};

export default HeaderHoverMenu;
