import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { t } from "i18next";
import { LinearProgress } from "@mui/material";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import TextTag, { textVariants } from "components/Typography/TextTag";
import FolderIcon from "components/Icons/FileIcon/FolderIcon";
import TagIcon from "components/Icons/TagIcon/TagIcon";
import TooltipWrapper from "components/TooltipComponent/TooltipWrapper";
import { assetsData } from "../features/uploadScreenSlice";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import { ButtonMiniIconWithChildren } from "components/Button/ButtonMiniIcon";
import TrashIconOutline from "components/Icons/Trash/TrashIconOutline";
import DangerIcon from "components/Icons/DangerIcon/DangerIcon";
import SingleCheckbox from "components/Checkbox/SingleCheckbox";

export const FileUploadComponent = ({ file, getRootProps, getInputProps }) => {
  return (
    <div className="skeletonImage" {...getRootProps()}>
      <div className="uploadIcong">{file}</div>
      <input type="file" id="uploadMyAssets" {...getInputProps()} />
      <HeadingTag variant={headingVariants.h4}>
        {t("dropFilesToStartUpload")}
      </HeadingTag>
      <MainButton title={t("selectHere")} variant={buttonVariants.blue} />
    </div>
  );
};

export const FileDisplayComponent = ({
  file,
  checkedAssetIds,
  uploadingAssetProgress,
  albumDataArr,
  handleCheckboxClick,
}) => {
  let [progress] = Object.keys(uploadingAssetProgress);
  const allAssetsData = useSelector(assetsData);
  const tagIconEnabled = allAssetsData?.[file?.id]?.keyWordArr?.length > 0;
  const assetAlbums = albumDataArr?.filter((x) =>
    x?.asset_ids?.includes(file?.id),
  );

  return (
    <div className="afterUploadImages" key={file?._id || file?.randomId}>
      {!file?.randomId && (
        <SingleCheckbox
          type="checkbox"
          checked={checkedAssetIds?.includes(file.id)}
          onChange={(e) => handleCheckboxClick(e, file)} // Handle checkbox click
          className="uploadCheckbox"
        />
      )}
      <div className="uploadImg">
        <div className="uploadAImage">
          <img src={file?.thumbnailUrl} alt={file?.name} style={{}} />
        </div>
        <div className="assetHoverSection">
          <TextTag variant={textVariants._12px.semiBold}>
            {file?.name?.length > 40
              ? file.name.substring(0, 40) + "..."
              : file.name}
          </TextTag>
        </div>
      </div>
      <div className="assetLowerSection">
        {uploadingAssetProgress[file.randomId] &&
          uploadingAssetProgress[file.randomId] < 100 &&
          uploadingAssetProgress[file.randomId] >= 0 && (
            <LinearProgress
              style={{
                width: "100%",
                position: "absolute",
                flex: "0 0 100%",
                maxWidth: "100%",
                bottom: "0",
                top: "0",
                left: "0",
              }}
              color={"primaryColor"}
              variant="determinate"
              value={uploadingAssetProgress[file.randomId]}
            />
          )}
        <div className="assetLowerLeft">
          <TooltipWrapper
            className={"minContent uploadTooltip"}
            ellipses={assetAlbums?.length === 0 ? false : true}
            tooltipText={assetAlbums?.map(
              (data, index) =>
                index < 12 && <div key={data?._id}>{data.name}</div>,
            )}
          >
            <FolderIcon
              className={`folderIcon ${
                assetAlbums?.length === 0 ? "disabled" : ""
              }`}
            />
          </TooltipWrapper>
          <TooltipWrapper
            className={"minContent uploadTooltip"}
            ellipses={tagIconEnabled}
            tooltipText={allAssetsData?.[file?.id]?.keyWordArr?.map(
              (data, index) =>
                index < 12 && <div key={index}>{data?.name}</div>,
            )}
          >
            <TagIcon
              className={`tagIcon ${tagIconEnabled ? "" : "disabled"}`}
            />
          </TooltipWrapper>
        </div>
        <div className="assetLowerRight">
          <div
            className={
              (isEmpty(uploadingAssetProgress) ||
                uploadingAssetProgress[progress] === 100) &&
              !file?.randomId
                ? "greenProgressCircle"
                : "blueProgressCircle"
            }
          />
        </div>
      </div>
    </div>
  );
};

export const UploadFailedComponent = ({ file, handleDelete }) => (
  <div
    className="failed-contribution-uploads"
    key={file?._id || file?.randomId}
  >
    <div className="assetNotUploadedTop">
      <DangerIcon />
      <TextTag
        className="failed-asset-message"
        variant={textVariants._14px.medium}
      >
        {t("assetNotUploaded")}
      </TextTag>
    </div>
    <TextTag className="failed-asset-name" variant={textVariants._12px.medium}>
      {file?.name?.length > 40 ? file.name.substring(0, 40) + "..." : file.name}
    </TextTag>
    <ButtonMiniIconWithChildren
      className="remove-asset-btn"
      onClick={() => handleDelete(file?.randomId)}
      variant={buttonVariants.red}
    >
      <TrashIconOutline />{" "}
      <TextTag variant={textVariants._12px.semiBold}>
        {t("removeFromUpload")}
      </TextTag>
    </ButtonMiniIconWithChildren>
  </div>
);
