import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { t } from "i18next";
import { Grid, TextField, Box, Breadcrumbs, Typography } from "@mui/material";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import CustomButton from "components/Button/CustomButton";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import CommonDeletePopup from "pages/Components/CommonDeletePopup/CommonDeletePopup";
import { FieldManagementStyle } from "pages/Admin-Pages/fields-management/fieldManagementStyle";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import TabsComponent from "pages/Components/Tabs/Tabs";
import {
  addFieldCategoryApi,
  deleteFieldCategoryApi,
  getFieldCategoryApi,
  manageCategoryOrderingIndexAction,
  updateFieldCategoryApi,
} from "../features/manageCategoryAction";
import { routeConfigs } from "utils/routeConfig";
import { Getcollection } from "pages/Admin-Pages/collections/features/collectionAction";
import { useQuery } from "utils/queryString";
import { fieldCategories } from "../features/manageCategorySlice";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";

const ManageCategory = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputRef = useRef(null);
  const gridRef = useRef();

  const currentCollectionId1 = useQuery().get("id");

  const fieldCategoriesData = useSelector(fieldCategories);
  const assetCollectionId = currentCollectionId1
    ? atob(currentCollectionId1)
    : null;

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "rowDrag",
      headerName: "",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxDragColumn",
      maxWidth: "110",
      suppressNavigable: true,
      lockPosition: "left",
      foreverShow: true,
    },
    // {
    //   field: "selectionCheckbox",
    //   headerName: "",
    //   checkboxSelection: true,
    //   headerCheckboxSelection: true,
    //   suppressFiltersToolPanel: true,
    //   headerClass: "checkboxColumn",
    //   maxWidth: "64",
    //   suppressNavigable: true,
    //   lockPosition: "left",
    // },
    {
      field: "name",
      headerName: t("categoryName"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            id={params?.column?.colId}
          >
            {params?.displayName}
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: true,
        editFunction: editField,
      },
      cellClass: "editableCell",
      editable: true,
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableDeleteIcon
            onClick={() => {
              gridRef.current.api.deselectAll();
              setSelectedRows([]);
              setCurrentRowData(props?.data);
              setRowsToBeDeleted([props?.data?._id]);
              setOpenDelete(true);
            }}
          />
        </Grid>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState();
  const [currentRowData, setCurrentRowData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState();
  const [alphaNumErrorAdd, setAlphaNumErrorAdd] = useState("");
  const [tabsDataArray, setTabsDataArray] = useState();
  const [searchParams] = useSearchParams();
  const [categoryName, setCategoryName] = useState("");

  const initialDataState = {
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "asc", //probable values: asc, desc
    sortBy: "order_index", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
    collectionId: assetCollectionId,
  };

  const [dataState, setDataState] = useState(initialDataState);
 

  useEffect(() => {
    try {
      setIsLoading(true);
      setDataState(initialDataState);
    } catch (e) {}
    //eslint-disable-next-line
  }, [currentCollectionId1]);

  useEffect(() => {
    dispatch(Getcollection()).then((resp) => {
      if (resp?.error) {
        return;
      }
      const dataArray = [];
      resp?.payload?.collections?.map((dataObj) => {
        if (dataObj?.enabled) {
          dataArray.push({
            _id: dataObj?._id,
            value: routeConfigs.adminCategoryManagementField,
            label: dataObj?.name,
          });
        }
        return false;
      });
      if (!window.location.search) {
        //set first tab when page opens for the first time
        navigate({
          pathname: window.location.pathname,
          search: `?id=${btoa(dataArray?.[0]?._id)}`,
          replace: true,
        });
      }
      setTabsDataArray(dataArray);
    });
    //eslint-disable-next-line
  }, [dispatch, searchParams]);

  const setAgGridValuesHandler=useCallback(()=>{
    dispatch(
      getFieldCategoryApi({ collectionId: assetCollectionId, ...dataState }),
    ).then((res) => {
      setIsLoading(false);
      setRowsToBeDeleted(false);
      setSelectedRows(false);
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
    });
  },[assetCollectionId,colDefs,dataState,dispatch])
  useEffect(() => {
    if (!assetCollectionId || assetCollectionId === null) return;
    setIsLoading(true);
    setAgGridValuesHandler()
    //eslint-disable-next-line
  }, [dispatch, assetCollectionId, dataState]);

  useEffect(() => {
    // setPreviousColumnFilterData(fieldCategoriesData?.applied_filters);

    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) =>
        (commonDataObj[commonKey] = fieldCategoriesData?.[commonKey]),
    );

    setRowData(
      fieldCategoriesData?.data?.map((category) => ({
        _id: category._id,
        name: category.name,
        assetCollectionId: category.assetCollectionId,
        createdAt: category.createdAt,
        updatedAt: category.updatedAt,
      })),
    );
  }, [fieldCategoriesData]);
  const onRowDragEnd = useCallback(
    (e) => {
      const dataBody =  {
          oldIndex:
            parseInt(e.node.id) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
          newIndex:
            parseInt(e.node.rowIndex) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
          assetCollectionId:assetCollectionId
        };
    
      dispatch(manageCategoryOrderingIndexAction(dataBody)).then(setAgGridValuesHandler);
    },
    [dataState, dispatch,setAgGridValuesHandler,assetCollectionId],
  );
  const validateCategoryName = (value) => {
    if (value.length > 50) {
      return validationMessages.tooLong;
    }
    if (!validationRegex.alphanumeric.test(value)) {
      return validationMessages.alphanumericOnly;
    }
    if (!validationRegex.checkForDoubleSpaces.test(value)) {
      return validationMessages.noConsecutiveDoubleSpaces;
    }
    return "";
  };

  const handleCategoryNameChange = (e) => {
    const value = e.target.value;
    setCategoryName(value);
    if (value.trim()) {
      setAlphaNumErrorAdd(validateCategoryName(value));
    } else {
      setAlphaNumErrorAdd("");
    }
  };

  function editField(data) {
    const { dataObj, updatedValue } = data;
    if (!dataObj || !updatedValue || !dataObj._id) {
      return;
    }
    const body = { name: updatedValue };

    let modifiedDataState = {
      ...dataState,
      collectionId: data?.dataObj?.assetCollectionId,
    };

    if (data?.dataObj?.other_table_data) {
      const other_data = data?.dataObj?.other_table_data;
      modifiedDataState = modifyDataState(other_data);
    }

    dispatch(updateFieldCategoryApi({ id: dataObj._id, body })).then(() => {
      dispatch(getFieldCategoryApi(modifiedDataState));
    });
  }

  function addNewFieldCategory() {
    const trimmedName = !!categoryName && categoryName.trim();

    if (!trimmedName || trimmedName === "") {
      setAlphaNumErrorAdd(t("pleaseAddCategoryName"));
      return;
    }

    const dataBody = {
      body: { name: trimmedName },
      assetCollectionId,
    };

    dispatch(addFieldCategoryApi(dataBody)).then((res) => {
      if (res?.error) {
        return;
      }
      setDataState({
        ...dataState,
        search: "",
        limit: 20,
        page: 1,
        sortOrder: "desc",
        sortBy: "createdAt",
        column_name: "",
        column_values: "",
      });
      setCategoryName("");
    });
    return;
  }

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const handleDelete = () => {
    const body = {
      fieldCategoriesID:
        rowsToBeDeleted?.length > 0 ? rowsToBeDeleted : [currentRowData?._id],
        assetCollectionId:assetCollectionId
    };

    const dataBody = { body, dataState };

    const shouldPageChange = deletePageChangeChecker(
      fieldCategoriesData?.currentPage,
      fieldCategoriesData?.totalDocuments,
      rowsToBeDeleted?.length,
      fieldCategoriesData?.limit,
    );

    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(deleteFieldCategoryApi(dataBody)).then((res) => {
      if (res.error) {
        return;
      }
      setOpenDelete(false);
      setSelectedRows([]);
      if (shouldPageChange) setDataState(dataBody.dataState);
    });
  };
  const icons = useMemo(() => {
    return {
      rowDrag: `<img src ="./logos/menu.svg")} style="height: 46px; width: 26px;padding-right: 2px"/>`,
    };
  }, []);
  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    onRowDragEnd,
    columnDefs: colDefs,
    rowDragManaged: true,
    icons,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("administration")} />
      <Box role="presentation" className="breadcrumbs">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            {t("administration")}
          </Link>
          <Link
            component="button"
            underline="hover"
            color="inherit"
            onClick={() => {
              navigate(routeConfigs.adminFieldsManagement);
            }}
          >
            {t("fields")}
          </Link>
          <Typography color="text.primary">{t("manageCategories")}</Typography>
        </Breadcrumbs>
      </Box>
      <Box className="tabsLine" />
      {tabsDataArray?.length > 0 ? (
        window.location.search && (
          <TabsComponent
            tabsDataArray={tabsDataArray}
            setDataState={setDataState}
            initialDataState={initialDataState}
            className="subTabs"
          />
        )
      ) : (
        <Box style={{ height: "120px" }} />
      )}
      <Box container spacing={4} className="userReq-table">
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType ">
                <Box className="leftSideInputs">
                  <Grid container spacing={4} className={`addNew`}>
                    <Box className={"addNewDiv"}>
                      <TextField
                        placeholder={t("enterCategory")}
                        size="small"
                        className="addNewText"
                        variant="outlined"
                        onChange={handleCategoryNameChange}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            addNewFieldCategory();
                          }
                        }}
                        inputRef={inputRef}
                        value={categoryName}
                      />
                    </Box>
                    <Box className={"addNewDiv1"}>
                      <CustomButton
                        onClick={() => addNewFieldCategory()}
                        variant="outlined"
                      >
                        {t("addCategory")}
                      </CustomButton>
                    </Box>
                    <span className="errorClass">{alphaNumErrorAdd}</span>
                  </Grid>
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={setSelectedRows}
                  />
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={fieldCategoriesData?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={fieldCategoriesData?.currentPage}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("confirmMultiCategoryDelete")}
        singleDeleteText={t("confirmSingleCategoryDelete")}
        gridRef={gridRef}
      />
      {/* {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )} */}
    </FieldManagementStyle>
  );
};

export default ManageCategory;
