import {
  cancelCollectionAssetUpload,
  publishAssetUsingAssetId,
} from "./uploadScreenAction";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  assetsData: {}, //asset metadata and keywords based on asset id
  filteredAlbums: [], //album ids selected for filtering assets
  aiKeywordsData: {}, //accordian ai keyword data based on assetid
  cancelLoading: false,
  publishAssetLoading: false,
};

const uploadScreenSlice = createSlice({
  name: "uploadScreenSlice",
  initialState,
  reducers: {
    changeAssetsFinalData(state, action) {
      state.assetsData = action.payload;
    },
    changeFilteredAlbums(state, action) {
      state.filteredAlbums = action.payload;
    },
    changeAiKeywords(state, action) {
      state.aiKeywordsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(cancelCollectionAssetUpload.pending, (state) => {
        state.cancelLoading = true;
      })
      .addCase(cancelCollectionAssetUpload.rejected, (state) => {
        state.cancelLoading = false;
      })
      .addCase(cancelCollectionAssetUpload.fulfilled, (state) => {
        state.cancelLoading = false;
      });
    //publishAssetUsingAssetId
    builder
      .addCase(publishAssetUsingAssetId.pending, (state) => {
        state.publishAssetLoading = true;
      })
      .addCase(publishAssetUsingAssetId.rejected, (state) => {
        state.publishAssetLoading = false;
      })
      .addCase(publishAssetUsingAssetId.fulfilled, (state) => {
        state.publishAssetLoading = false;
      });
  },
});

export const { changeAssetsFinalData, changeFilteredAlbums, changeAiKeywords } =
  uploadScreenSlice.actions;

export const assetsData = (state) => state?.uploadScreenSlice?.assetsData;
export const filteredAlbums = (state) =>
  state?.uploadScreenSlice?.filteredAlbums;
export const aiKeywordsData = (state) =>
  state?.uploadScreenSlice?.aiKeywordsData;

export const cancelLoading = (state) => state?.uploadScreenSlice?.cancelLoading;
export const publishAssetLoading = (state) =>
  state?.uploadScreenSlice?.publishAssetLoading;

export default uploadScreenSlice.reducer;
