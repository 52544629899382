import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { getCollectionUser } from "pages/Admin-Pages/collections/features/collectionAction";
import { allCollectionsUser } from "pages/Admin-Pages/collections/features/collectionSlice";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { getCustomTagByCollectionType } from "pages/Admin-Pages/aui-form-fields/CustomTags/features/customTagAction";
import { customTagByCollectionType } from "pages/Admin-Pages/aui-form-fields/CustomTags/features/customTagSlice";
import HeaderCollectionRight from "./HeaderCollectionRight";
import ArrowRightIcon from "components/Icons/ArrowIcons/ArrowRightIcon";
import ButtonMiniIcon from "components/Button/ButtonMiniIcon";
import Alphabet from "components/Alphabet/Alphabet";
import TextfieldWithIcon from "components/Textfield/TextfieldwithIcon";
import CrossIcon from "components/Icons/CrossIcon/CrossIcon";
import FePaginationComp from "components/Pagination/FePaginationComp";
import CrossIconPill from "components/Pills/CrossIconPill";
import FilterIcon from "components/Icons/FilterIcon/FilterIcon";
import { routeConfigs } from "utils/routeConfig";
import { paginateArray } from "helper/arrayHelpers";

const countCustomTags = (data, key) => {
  return Object.values(
    data.reduce((acc, item) => {
      const tagId = item[key];
      if (!acc[tagId]) {
        acc[tagId] = { [key]: tagId, commonNo: 0 };
      }
      acc[tagId].commonNo += 1;
      return acc;
    }, {}),
  );
};

const HeaderCollectionMenu = ({ handleMouseLeave }) => {
  const dispatch = useDispatch();
  const debounceRef = useRef(null);
  const navigate = useNavigate();

  const prevSearchRef = useRef({
    page: null,
    custom_tag_id: null,
    alfabetSearch: null,
  });

  const allCollectionTypes = useSelector(allCollectionsUser)?.collections;
  const collectionTypeKeywords = useSelector(customTagByCollectionType);
  const collecTypeKeywordsArr = useMemo(() => {
    return collectionTypeKeywords?.collection_type?.filter(
      (x) => x?.custom_tag_data?.length > 0,
    );
  }, [collectionTypeKeywords?.collection_type]);

  const initialKeywordState = {
    page: 1,
    limit: 8,
    search: "",
    custom_tag_id: "",
    alfabetSearch: "",
  };

  const initialRightState = {
    page: 1,
    limit: 4,
  };

  const [activeColIndex, setActiveColIndex] = useState(0);
  const [keywordState, setKeywordState] = useState(initialKeywordState);
  const [showAll, setShowAll] = useState(false);
  const [rightState, setRightState] = useState(initialRightState);
  const [selectedTagArr, setSelectedTagArr] = useState([]);
  const [seeAllLoading, setSeeAllLoading] = useState(false);

  useEffect(() => {
    dispatch(getCollectionUser());
  }, [dispatch]);

  useEffect(() => {
    if (!allCollectionTypes?.[activeColIndex]?._id) return;

    const { page, custom_tag_id, alfabetSearch } = prevSearchRef.current;

    // Check if following keyword state value has changed
    const hasKeywordChanged =
      page !== keywordState.page ||
      custom_tag_id !== keywordState.custom_tag_id ||
      alfabetSearch !== keywordState.alfabetSearch;

    const data = {
      collectionTypeId: allCollectionTypes[activeColIndex]._id,
      ...keywordState,
    };

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    if (hasKeywordChanged) {
      dispatch(getCustomTagByCollectionType(data)).then(() => {
        setSeeAllLoading(false);
      });
    } else {
      debounceRef.current = setTimeout(() => {
        dispatch(getCustomTagByCollectionType(data)).then(() => {
          setSeeAllLoading(false);
        });
      }, 400);
    }

    prevSearchRef.current = {
      page: keywordState.page,
      custom_tag_id: keywordState.custom_tag_id,
      alfabetSearch: keywordState.alfabetSearch,
    };

    return () => {
      clearTimeout(debounceRef.current);
    };
  }, [activeColIndex, allCollectionTypes, dispatch, keywordState]);

  const commonTagsArr = useMemo(() => {
    //count common custom tags in from selected tags
    return countCustomTags(selectedTagArr, "custom_tag_id");
  }, [selectedTagArr]);

  const paginatedCustomArray = useMemo(() => {
    //create array according to pagination
    return paginateArray(
      collecTypeKeywordsArr,
      rightState.limit,
      rightState.page,
    );
  }, [collecTypeKeywordsArr, rightState.limit, rightState.page]);

  const handleCrossIconPillClick = (obj) => {
    //when cross icon on tag pill
    if (!obj) return;

    const customTagId = obj?.custom_tag_id;

    setSelectedTagArr((prev) =>
      prev.filter((data) => data.custom_tag_id !== customTagId),
    );
  };

  const handleTypeMouseEnter = (index) => {
    setActiveColIndex(index);
    setKeywordState(initialKeywordState);
    setRightState(initialRightState);
    setShowAll(false);
    setSelectedTagArr([]);
  };

  return (
    <div className="headerTypeMenuMain megaMenu">
      <div className="headerTypeMenu ">
        <div className="menuLeftSection megaMenusCard">
          {allCollectionTypes?.map((catData, index) => {
            return (
              <div
                className={`leftListHeading ${activeColIndex === index ? "active" : ""
                  }`}
                key={index}
                onMouseEnter={() => {
                  handleTypeMouseEnter(index);
                }}
              >
                <TextTag variant={textVariants._14px.medium}>
                  {catData?.name}
                </TextTag>
                <ArrowRightIcon />
              </div>
            );
          })}
        </div>
        <div className="menuMiddleSection megaMenusCard">
          <div className="menuMiddleTop">
            <TextTag variant={textVariants._18px.medium}>
              {allCollectionTypes?.[activeColIndex]?.name}
            </TextTag>
          </div>
          <div className="menuMiddleCenter">
            <TextTag variant={textVariants._14px.regular}>
              {allCollectionTypes?.[activeColIndex]?.description}
            </TextTag>
          </div>
          <div
            className="menuMiddleBottom"
            onClick={() => navigate(routeConfigs.searchPage)}
          >
            <ArrowRightIcon />
            <TextTag variant={textVariants._14px.medium}>
              {t("seeAllName", {
                name: allCollectionTypes?.[activeColIndex]?.name,
              })}
            </TextTag>
          </div>
        </div>
        <div className="headerRightSection megaMenusCard">
          <div className="headerCollecRightMain">
            <div className="headerRightTop">
              {selectedTagArr?.length > 0 && (
                <div className="selectedCustomTagsPills">
                  <div className="customTagPills">
                    {commonTagsArr?.map((data, index) => {
                      const customTagName = collecTypeKeywordsArr.find(
                        (x) => x?.custom_tag?._id === data?.custom_tag_id,
                      )?.custom_tag?.name;

                      return (
                        <CrossIconPill
                          name={`${customTagName}`}
                          isActive={true}
                          onCrossClick={() => {
                            handleCrossIconPillClick(data);
                          }}
                          key={index}
                          number={data?.commonNo}
                        />
                      );
                    })}
                  </div>
                  <div
                    className="clearFilterIcon"
                    onClick={() => {
                      setSelectedTagArr([]);
                    }}
                  >
                    <FilterIcon className={"clearfilterIcon"} />
                    <TextTag
                      className={"clearfilterText"}
                      variant={textVariants._12px.medium}
                    >
                      {t("clearFilters")}
                    </TextTag>
                  </div>
                </div>
              )}
              <ButtonMiniIcon
                disabled={selectedTagArr?.length <= 0}
                onClick={() => navigate(routeConfigs.searchPage)}
              >
                {t("displaySearchResults")}{" "}
                {selectedTagArr?.length > 0 && `(${selectedTagArr?.length})`}
              </ButtonMiniIcon>
              <div className="collectionTextCross">
                <TextfieldWithIcon
                  placeholder={t("enterKeywords")}
                  value={keywordState?.search}
                  onChange={(e) => {
                    setKeywordState((prev) => ({
                      ...prev,
                      search: e.target.value,
                      page: initialKeywordState.page,
                    }));
                    setRightState((prev) => ({
                      ...prev,
                      page: initialRightState.page,
                    }));
                  }}
                />
                {keywordState?.search?.length > 0 && (
                  <CrossIcon
                    className={"headerCollectionCross"}
                    onClick={() => {
                      setKeywordState((prev) => ({
                        ...prev,
                        search: "",
                      }));
                    }}
                  />
                )}
              </div>
            </div>
            <div className="headerCollecRightAlphabet">
              <Alphabet
                value={keywordState.alfabetSearch}
                onChange={(alphabet) => {
                  setKeywordState((prev) => ({
                    ...prev,
                    alfabetSearch: alphabet,
                    page: initialKeywordState.page,
                  }));
                  setRightState((prev) => ({
                    ...prev,
                    page: initialRightState.page,
                  }));
                }}
              />
              <div className="curatedGallerySections">
                {paginatedCustomArray?.map((keyData, index) => {
                  return (
                    <HeaderCollectionRight
                      keyData={keyData}
                      key={index}
                      keywordState={keywordState}
                      setKeywordState={setKeywordState}
                      showAll={showAll}
                      setShowAll={setShowAll}
                      setSelectedTagArr={setSelectedTagArr}
                      selectedTagArr={selectedTagArr}
                      setRightState={setRightState}
                      initialRightState={initialRightState}
                      initialKeywordState={initialKeywordState}
                      commonTagsArr={commonTagsArr}
                      collectionTypeId={
                        allCollectionTypes?.[activeColIndex]?._id
                      }
                      seeAllLoading={seeAllLoading}
                      setSeeAllLoading={setSeeAllLoading}
                      handleCrossIconPillClick={handleCrossIconPillClick}
                    />
                  );
                })}
                {collecTypeKeywordsArr?.length <= 0 && (
                  <div className="noDataFound">
                    <TextTag variant={textVariants._12px.medium}>
                      {t("")}
                    </TextTag>
                  </div>
                )}
              </div>
              {collecTypeKeywordsArr?.length > rightState.limit && (
                <FePaginationComp
                  count={Math.ceil(
                    collecTypeKeywordsArr?.length / rightState.limit,
                  )}
                  onChange={(e, value) => {
                    setRightState((prev) => ({ ...prev, page: value }));
                  }}
                  page={rightState?.page}
                />
              )}
            </div>
            <div
              className="headerCollectionRightBottom"
              onClick={() => navigate(routeConfigs.searchPage)}
            >
              <TextTag variant={textVariants._14px.medium}>
                {t("headerCollectionGetMoreSearchText")}
              </TextTag>
              <ArrowRightIcon />
            </div>
          </div>
        </div>
      </div>
      <div
        className="hoverMenuClose"
        onMouseMove={handleMouseLeave}
        onMouseEnter={handleMouseLeave}
      />
    </div>
  );
};

export default HeaderCollectionMenu;
