import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";
import {
  updateProgress,
  updateTemporaryFiles,
  removeTemporaryFiles,
} from "./contributionSlice";

export const getContributionById = createAsyncThunk(
  "contribution/getContributionById",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getContributeById}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllDigitalAssetsInCollectionDraft = createAsyncThunk(
  "contribution/getAllDigitalAssetsInCollectionDraft",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.getAllDigitalAssetsInCollectionDraft}/${id}`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllContributeAlbums = createAsyncThunk(
  "contribution/getAllContributionsAlbums",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes?.getAllContributeAlbums}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

export const createSingleContributeAlbum = createAsyncThunk(
  "contribution/createSingleContributeAlbum",
  async (body) => {
    try {
      return await performApiRequest(
        () => axiosConfig.post(apiRoutes?.createSingleContributeAlbum, body),
        null,
        //if notoast then send false to show no toast
        body?.noToast ? false : true,
      );
    } catch (error) {
      return { success: false, error: error?.data };
    }
  },
);

export const deleteSingleContributeAlbum = createAsyncThunk(
  "contribution/deleteSingleContributeAlbum",
  async (body) => {
    try {
      return await performApiRequest(
        () => axiosConfig.put(`${apiRoutes.deleteSingleContributeAlbum}`, body),
        null,
        //if notoast then send false to show no toast
        body?.noToast ? false : true,
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

//deleteAssetDraftCollection
export const deleteAssetDraftCollection = createAsyncThunk(
  "contribution/deleteAssetDraftCollection",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(
        () => axiosConfig.put(`${apiRoutes.deleteAssetDraftCollection}`, body),
        null,
        //if notoast then send false to show no toast
        body?.noToast ? false : true,
      );
      const vid = new URLSearchParams(window.location.search).get("id");
      return dispatch(getAllDigitalAssetsInCollectionDraft(vid));
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

export const getCustomTagDatas = createAsyncThunk(
  "contribution/getCustomTagDatas",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getCustomTagData}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const uploadAssetsForContribution = createAsyncThunk(
  "contribution/uploadAssetsForContribution",
  async (data, { dispatch, getState }) => {
    const { collection_id, id, formData, key } = data;

    const params = new URLSearchParams();
    const { contributionSlice } = getState();

    let apiUrl = `${apiRoutes.createAssetInCollection}${id ? `/${id}` : ""}`;

    let { uploadingTemporaryFiles } = contributionSlice;
    try {
      const config = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          dispatch(updateProgress([key, percent]));
        },
      };

      if (collection_id) params.append("collection_id", collection_id);

      if (params.toString()) {
        apiUrl += `?${params.toString()}`;
      }

      await performApiRequest(
        () => axiosConfig.post(apiUrl, formData, config),
        null,
        false, //do not show toast for this api
      );
      dispatch(removeTemporaryFiles(key));
    } catch (error) {
      dispatch(updateTemporaryFiles(key));
      return { success: false, error: error.response.data };
    } finally {
      const vid = new URLSearchParams(window.location.search).get("id");
      return dispatch(getAllDigitalAssetsInCollectionDraft(vid));
    }
  },
);

export const updateKeywordsContribute = createAsyncThunk(
  "contribution/updateKeywordsContribute",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.updateKeywordsContributeApi}/${body?.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

export const updateContributeDetailsApi = createAsyncThunk(
  "contribution/updateContributeDetailsApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.updateContributeDetails}/${body?.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

//updateContributeLocation
export const updateContributeLocationApi = createAsyncThunk(
  "contribution/updateContributeLocationApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.updateContributeLocation}/${body?.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

//getAssetFileProperties
export const getAssetFileProperties = createAsyncThunk(
  "contribution/getAssetFileProperties",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getAssetFileProperties}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//getAssetAiTags
export const getAssetAiTags = createAsyncThunk(
  "contribution/getAssetAiTags",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getAssetAiTags}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//getAsssetAiDescription
export const getAsssetAiDescription = createAsyncThunk(
  "contribution/getAsssetAiDescription",
  async ({ id, signal }) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getAsssetAiDescription}/${id}`, {
          signal,
        }),
      );
    } catch (error) {
      if (error.name === "AbortError") {
        return { success: false, error: "Request aborted" };
      }
      return { success: false, error: error.response?.data || error.message };
    }
  },
);
