import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import { ClickAwayListener } from "@mui/material";
import MainButton, {
  buttonVariants,
  MainButtonWithChildren,
} from "components/Button/MainButton";
import { PopupFooterStyles } from "components/ContributePopup/ContributePopupFooter/ContributePopupFooter";
import ContributePopupHeader from "components/ContributePopup/ContributePopupHeader/ContributePopupHeader";
import PlusIcon from "components/Icons/PlusIcon/PlusIcon";
import { getAssetAiTags } from "pages/User-Pages/ContributePage/features/contributionAction";
import {
  aiKeyWordsLoading,
  assetAiTags,
} from "pages/User-Pages/ContributePage/features/contributionSlice";
import PlusMinusPill from "components/Pills/PlusMinusPill";
import { Loader } from "components/Loader/Loader";
import TextTag, { textVariants } from "components/Typography/TextTag";
import ButtonOutlineIcon from "components/Button/ButtonOutlineIcon";
import AIGenerateIcon from "components/Icons/AIGenerateIcon/AIGenerateIcon";
import {
  aiKeywordsData,
  changeAiKeywords,
} from "pages/User-Pages/UploadScreen/features/uploadScreenSlice";
import { ContributePopupStyles } from "components/ContributePopup/contributePopupStyles";

const AITagsPopup = (props) => {
  const { setOpenAiPopup, checkedAssetIds } = props;

  const dispatch = useDispatch();
  const aiTags = useSelector(assetAiTags)?.tags;
  const aiKeyWordsLoader = useSelector(aiKeyWordsLoading);
  const aiKeyowrdsOld = useSelector(aiKeywordsData);

  const [aiKeywords, setAiKeywords] = useState([]);
  const [selectedAIKeywords, setSelectedAIKeywords] = useState([]);

  useEffect(() => {
    setSelectedAIKeywords(aiKeyowrdsOld?.[checkedAssetIds[0]] || []);
  }, [aiKeyowrdsOld, checkedAssetIds]);

  useEffect(() => {
    dispatch(getAssetAiTags(checkedAssetIds[0]));
  }, [dispatch, checkedAssetIds]);

  useEffect(() => {
    if (typeof aiTags === "string") {
      const aiTagArray = aiTags.split(", ");
      setAiKeywords(aiTagArray);
    } else {
      setAiKeywords([]);
    }
  }, [aiTags]);

  const handlePopupClose = () => {
    setOpenAiPopup(false);
  };

  const toggleKeywords = (keyword) => {
    setSelectedAIKeywords((prev) => {
      const newKeywords = new Set(prev);
      if (newKeywords.has(keyword)) {
        newKeywords.delete(keyword);
      } else {
        newKeywords.add(keyword);
      }
      return [...newKeywords];
    });
  };

  return (
    <ContributePopupStyles className="aiTagsPopup">
      <ClickAwayListener onClickAway={handlePopupClose}>
        <div className="contributePopupMain">
          <ContributePopupHeader
            handlePopupClose={handlePopupClose}
            popupTitle={t("aiKeywords")}
          />
          <div className="aiKeywordsBody aiDescPopupBody">
            <div className="descSection">
              <ButtonOutlineIcon
                iconComp={<AIGenerateIcon />}
                title={t("generateAgain")}
                className={"generateDesc"}
                onClick={() => {
                  dispatch(getAssetAiTags(checkedAssetIds[0]));
                }}
              />
              {aiKeyWordsLoader ? (
                <Loader />
              ) : (
                <div className="aiKeywords">
                  <ul>
                    {aiKeywords?.length > 0 ? (
                      aiKeywords.map((word, index) => {
                        return (
                          <PlusMinusPill
                            key={index}
                            isSelected={selectedAIKeywords?.includes(word)}
                            onClick={() => {
                              toggleKeywords(word);
                            }}
                            name={word}
                          />
                        );
                      })
                    ) : (
                      <TextTag
                        variant={textVariants._16px.regular}
                        className="noKeyWordFound"
                      >
                        {/* {t("noKeywordsFound")} */}
                      </TextTag>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
          <PopupFooterStyles className="addAlbunFooter">
            <MainButton
              onClick={handlePopupClose}
              variant={buttonVariants.grey}
              title={t("cancel")}
            />
            <MainButtonWithChildren
              onClick={() => {
                dispatch(
                  changeAiKeywords({
                    ...aiKeyowrdsOld,
                    [checkedAssetIds[0]]: selectedAIKeywords,
                  }),
                );
                handlePopupClose();
              }}
              variant={buttonVariants.blue}
              className="deleteBtn"
              disabled={selectedAIKeywords?.length <= 0}
            >
              <PlusIcon />
              {t("addKeywords")} ({selectedAIKeywords?.length})
            </MainButtonWithChildren>
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

export default AITagsPopup;
