import React from "react";

const AddToBoardIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
        stroke-width="1.7"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.0651 9.60156H5.73177C6.09996 9.60156 6.39844 9.90004 6.39844 10.2682V12.9349C6.39844 13.3031 6.09996 13.6016 5.73177 13.6016H3.0651C2.69691 13.6016 2.39844 13.3031 2.39844 12.9349V10.2682C2.39844 9.90004 2.69691 9.60156 3.0651 9.60156Z"
        stroke="#212A36"
      />
      <path
        d="M2 4.33333H6.66667M4.33333 2V6.66667"
        stroke="#212A36"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.401 9.60156H13.0677C13.4359 9.60156 13.7344 9.90004 13.7344 10.2682V12.9349C13.7344 13.3031 13.4359 13.6016 13.0677 13.6016H10.401C10.0329 13.6016 9.73438 13.3031 9.73438 12.9349V10.2682C9.73438 9.90004 10.0329 9.60156 10.401 9.60156Z"
        stroke="#212A36"
      />
      <path
        d="M10.401 2.39844H13.0677C13.4359 2.39844 13.7344 2.69691 13.7344 3.0651V5.73177C13.7344 6.09996 13.4359 6.39844 13.0677 6.39844H10.401C10.0329 6.39844 9.73438 6.09996 9.73438 5.73177V3.0651C9.73438 2.69691 10.0329 2.39844 10.401 2.39844Z"
        stroke="#212A36"
      />
    </svg>
  );
};

export default AddToBoardIcon;
