import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import styled from "@emotion/styled";
import WhatAreYouContributing from "./components/WhatAreYouContributing/WhatAreYouContributing";
import WhatTypeOfCollection from "./components/WhatTypeOfCollection/WhatTypeOfCollection";
import { getContributeRequiredFieldsApi } from "pages/Admin-Pages/fields-management/features/fieldsManagementAction";
import CollectionFieldsData from "./components/CollectionFieldsData/CollectionFieldsData";
import TextTag, { textVariants } from "components/Typography/TextTag";
import SelectCategorySubCategory from "./components/SelectCategorySubCategory/SelectCategorySubCategory";
import ScheduleAssetBody from "./components/ScheduleAssetBody/ScheduleAssetBody";
import { fieldsByCollectionLoading } from "pages/Admin-Pages/fields-management/features/fieldsManagementSlice";
import { Loader } from "components/Loader/Loader";

export const PopupBodyStyles = styled("div")(({ theme }) => ({
  minHeight: "394px",
  padding: "24px 24px 0px",
  maxHeight: "394px",
  overflow: "auto",
  marginRight: "11px",
  "&.addAlbumPopupBody": {
    minHeight: "0px",
    padding: "24px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#E9E9E9",
  },
  ".popupError": {
    color: theme.palette.errorColor.main,
    display: "block",
    width: "100%",
    maxWidth: "360px",
  },
  ".openScheduleAsset": {
    display: "none",
  },
}));

const ContributePopupBody = ({
  collectionType,
  setCollectionType,
  allCollectionFields,
  contributionTypeObject,
  setContributionType,
  selectedCategory,
  setSelectedCategory,
  openScheduleAsset,
  setOpenScheduleAsset,
  setScheduleDateTime,
  ...props
}) => {
  const dispatch = useDispatch();
  const noCollectionData = t("noCollectionFieldText2");

  const [openCollectionType, setOpenCollectionType] = useState(false);
  const [showCollectionFields, setShowCollectionFields] = useState(false);
  const fieldsByCollectionLoader = useSelector(fieldsByCollectionLoading);

  useEffect(() => {
    //get fields using collection type
    if (collectionType?._id) {
      dispatch(
        getContributeRequiredFieldsApi({
          collectionId: collectionType?._id,
        }),
      );
    }
  }, [collectionType, dispatch]);

  return (
    <PopupBodyStyles>
      {openScheduleAsset && (
        <div className="scheduleAssetMain">
          <ScheduleAssetBody setScheduleDateTime={setScheduleDateTime} />
        </div>
      )}
      <div className={openScheduleAsset ? "openScheduleAsset" : ""}>
        <WhatAreYouContributing
          activeContributingObj={contributionTypeObject}
          setOpenCollectionType={setOpenCollectionType}
          setContributionType={setContributionType}
        />
        {/* if contributing a collection */}
        {openCollectionType === contributionTypeObject.collection && (
          <WhatTypeOfCollection
            setCollectionType={setCollectionType}
            setShowCollectionFields={setShowCollectionFields}
          />
        )}
        {/* after selecting a collection */}
        {showCollectionFields &&
          (fieldsByCollectionLoader ? (
            <Loader />
          ) : allCollectionFields?.length > 0 ? (
            <CollectionFieldsData
              allCollectionFields={allCollectionFields}
              {...props}
            />
          ) : (
            <TextTag
              className={"popupError"}
              variant={textVariants._14px.regular}
            >
              {noCollectionData}
            </TextTag>
          ))}
        {/* if contributing a asset */}
        {openCollectionType === contributionTypeObject.asset && (
          <SelectCategorySubCategory
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        )}
      </div>
    </PopupBodyStyles>
  );
};

export default ContributePopupBody;
