import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import ToggleSwitch from "pages/Components/ToggleSwitch/ToggleSwitch";
import CommonGrid from "components/Grid/CommonGrid";
import CustomButton from "components/Button/CustomButton";
import PaginationLimitDropDown from "../../../Components/PaginationDropdown/PaginationLimitDropDown";
import CustomComponentFilter, {
  getFieldsDataHelper,
} from "../../../Components/CustomComponentfilter/CustomComponentFilter";
import TableViewIcon from "components/Grid/TableComponents/TableIcons/TableViewIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import CommonDeletePopup from "../../../Components/CommonDeletePopup/CommonDeletePopup";
import {
  deleteFeatureCollectionApi,
  getFeaturedCollectionsApi,
  updateFeatureCollectionApi,
  updateFeatureCollectionIndexApi,
  getFeaturedCollectionTitleApi,
} from "./features/featuredAction";
import {
  selectCollectionTitle,
  selectFeaturedCollections,
} from "./features/featuredSlice";
import { agSetColumnIndexes } from "../../../Components/ColumnDrag/ColumnDrag";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
} from "utils/datatable/agTableOptions";
import { routeConfigs } from "utils/routeConfig";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import FeaturedAssetsPopups from "./FeaturedAssetsPopups";
import { FieldManagementStyle } from "../../fields-management/fieldManagementStyle";

export default function FeaturedAssets() {
  const { t } = useTranslation();
  const [settingType, setSettingType] = useState();

  const [addNewPopUp, setAddNewPopUp] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [popUpMode, setPopUpMode] = useState(t("addFeatured"));
  const [selectedRows, setSelectedRows] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState();
  const [alphaNumErrorAdd, setAlphaNumErrorAdd] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [currentRowData, setCurrentRowData] = useState();
  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "asc", //probable values: asc, desc
    sortBy: "display_index", //column_name through which sort is applied like createdAt||
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter
  const dispatch = useDispatch();
  const searchData = useSelector(selectFeaturedCollections);
  // const fieldValueData = useSelector("searchFieldData");
  const fieldValueData = [];

  const gridRef = useRef();
  const resetErrorStates = () => setAlphaNumErrorAdd("");

  const handlePageChange = (e, value) =>
    setDataState({ ...dataState, page: value });
  const { data: collectionNameData } = useSelector(selectCollectionTitle);
  const setFeaturedCollectionsHandler = () => {
    dispatch(getFeaturedCollectionsApi(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(getFeaturedCollectionTitleApi());
    resetErrorStates();
    setFeaturedCollectionsHandler();
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    setPreviousColumnFilterData(searchData?.applied_filters);
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = searchData?.[commonKey]),
    );

    setRowData(
      searchData?.data?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        featured_collection: tableDataObj?.digitalAssetCollection,
        contributor: tableDataObj?.contributor,
        enabled: tableDataObj?.enable,
        action: tableDataObj?.digitalAssetCollectionId,
        other_table_data: commonDataObj,
      })),
    );
  }, [searchData]);

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      // getSearchFieldsApi,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const createHeaderComponent = (dispatchName) => (params) => {
    const filterClickProps = {
      dispatchGetName: dispatchName,
      dispatchName: dispatchName,
      applied_filters: params?.applied_filters_data?.applied_filters,
    };
    return (
      <th
        key={params?.column?.colId}
        className="userRole leftAlign"
        onClick={(e) => commonFilterOnClick(e, filterClickProps)}
        id={params?.column?.colId}
      >
        {params?.displayName}
      </th>
    );
  };

  const cellRendererActionIcons = (props) => (
    <Grid container className="actionIcons">
      <Link
        to={`${routeConfigs.contributePage}?id=${props?.value}&type=view`}
        target="_blank"
      >
        <TableViewIcon />
      </Link>
      <TableDeleteIcon
        onClick={() => {
          gridRef.current.api.deselectAll();
          setSelectedRows([]);
          setCurrentRowData(props?.data);
          setRowsToBeDeleted([props.data?._id]);
          setOpenDelete(true);
        }}
      />
    </Grid>
  );

  const cellRendererToggleSwitch = (props) => {
    const switchHandler = async (e, id) => {
      const checked = e.target.checked;

      const body = {
        featureCollectionId: id,
        status: checked,
      };
      dispatch(updateFeatureCollectionApi(body));
    };

    return (
      <Grid className="toggleBlockCo">
        <ToggleSwitch
          defaultChecked={props.value}
          onChange={(e) => switchHandler(e, props?.data?._id)}
        />
      </Grid>
    );
  };

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "rowDrag",
      headerName: "",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxDragColumn",
      maxWidth: "110",
      suppressNavigable: true,
      lockPosition: "left",
      foreverShow: true,
    },
    {
      field: "featured_collection",
      headerName: t("featuredAssets"),
      headerClass: "leftAlign",
      minWidth: 270,
      headerComponent: createHeaderComponent("featured_collection"),
      cellRenderer: ({ value }) =>
        ToolTipWrapperComponent(
          `${value?.clientId || value?.uniqueId} ${
            value?.name && ` - ${value?.name}`
          }`,
        ),
    },
    {
      field: "contributor",
      headerName: t("contributorName"),
      headerClass: "leftAlign",
      minWidth: 270,
      headerComponent: createHeaderComponent("contributor"),
      cellRenderer: ({ value }) =>
        ToolTipWrapperComponent(`${value?.firstName} ${value?.lastName}`),
    },
    {
      field: "enabled",
      headerName: t("Status"),
      headerClass: "fieldCenter",
      headerComponent: createHeaderComponent("enabled"),
      cellClass: "statusColumn",
      cellRenderer: cellRendererToggleSwitch,
    },
    {
      field: "action",
      headerName: t("Action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: cellRendererActionIcons,
    },
  ];

  const [colDefs, setColDefs] = useState(initialColDefs);

  function handleDelete() {
    const body = {
      featureCollectionIds:
        rowsToBeDeleted?.length > 0 ? rowsToBeDeleted : [currentRowData?._id],
    };

    // const shouldPageChange = deletePageChangeChecker(
    //   searchData?.currentPage,
    //   searchData?.totalDocuments,
    //   rowsToBeDeleted?.length,
    //   searchData?.limit,
    // );

    // const newDataState = {
    //   ...dataState,
    //   page: shouldPageChange
    //     ? parseInt(dataState?.page) - 1
    //     : parseInt(dataState?.page),
    // };

    dispatch(deleteFeatureCollectionApi(body)).then(
      setFeaturedCollectionsHandler,
    );

    setOpenDelete(false);
    setSelectedRows([]);
  }
  const onRowDragEnd = useCallback(
    (e) => {
      const body = {
        oldIndex:
          parseInt(e.node.id) +
          1 +
          (dataState.limit * dataState.page - dataState.limit),
        newIndex:
          parseInt(e.node.rowIndex) +
          1 +
          (dataState.limit * dataState.page - dataState.limit),
        dataState,
      };
      dispatch(updateFeatureCollectionIndexApi(body)).then((res) => {
        setFeaturedCollectionsHandler();
      });
    },
    //eslint-disable-next-line
    [dataState, dispatch],
  );

  const icons = useMemo(() => {
    return {
      rowDrag: `<img src ="./logos/menu.svg")} style="height: 46px; width: 26px;padding-right: 2px"/>`,
    };
  }, []);

  const commonGridObj = {
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
    onRowDragEnd,
    rowDragManaged: true,
    icons,
  };

  const closeAddNewPopUpHandler = () => setAddNewPopUp(false);
  return (
    <FieldManagementStyle>
      <Box className="titleButton">
        <PageTitle
          title={collectionNameData?.name || collectionNameData?._id}
        />
        <Box className={"addNewDiv1"}>
          <CustomButton
            onClick={() => {
              setPopUpMode(t("changeSectionTitle"));
              setAddNewPopUp(true);
            }}
            variant="outlined"
          >
            {t("changeSectionTitle")}
          </CustomButton>
        </Box>
      </Box>
      <Box className="tabsLine" />
      <Box container spacing={4} className="userReq-table">
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType ">
                <Box className="leftSideInputs">
                  <Grid container spacing={4} className={`addNew`}>
                    <Box className={"addNewDiv1"}>
                      <CustomButton
                        // onClick={() => addNewSearch()}
                        onClick={() => {
                          setPopUpMode(t("addFeatured"));
                          setAddNewPopUp(true);
                        }}
                        variant="outlined"
                      >
                        {t("add")}
                      </CustomButton>
                    </Box>
                    <span className="errorClass">{alphaNumErrorAdd}</span>
                  </Grid>
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={setSelectedRows}
                  />
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={searchData?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("deleteSelectedCollections")}
        singleDeleteText={t("deleteFeaturedCollection")}
      />
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
      <FeaturedAssetsPopups
        popUpMode={popUpMode}
        closeAddNewPopUpHandler={closeAddNewPopUpHandler}
        addNewPopUp={addNewPopUp}
        setFeaturedCollectionsHandler={setFeaturedCollectionsHandler}
        dispatch={dispatch}
        collectionNameData={collectionNameData}
      />
    </FieldManagementStyle>
  );
}
