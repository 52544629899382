import React, { useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Box, DialogActions, DialogContent, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { CsvPopupStyle } from "pages/Admin-Pages/user-administration/Components/CsvPopup/CsvPopupStyle";
import { getVideoModule } from "pages/Admin-Pages/video-module/features/videoModuleSlice";
import {
  getAllAssetType,
  getAssetType,
  updateTooltipDescription,
} from "../features/typeAction";
import { fileTypeSchema } from "utils/validation/validation-schemas/fileTypeSchema";
import { UserDetailDialogDesign } from "pages/Admin-Pages/user-administration/styles/roleStyles";
import { getAssetSubCategoryApi } from "../../AssetSubCategory/features/assetSubCategoryAction";
import { getAssetCategoryApi } from "../../AssetCategory/features/assetCategoryAction";

const AddEditFileType = (props) => {
  const { openChangeTitle, setOpenChangeTitle, desc, id, moduleName } = props;
  const dispatch = useDispatch();
  const { updateLoading } = useSelector(getVideoModule);

  const { t } = useTranslation();

  const initialValues = {
    description: desc || "",
  };

  const formik = useFormik({
    validationSchema: fileTypeSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  function handleSubmit(values) {
    const payload = {
      moduleName,
      description: values.description,
      id: id,
    };

    if (moduleName === "filetype") {
      dispatch(updateTooltipDescription(payload))
        .then(() => dispatch(getAssetType()))
        .then(handleClose);
    } else if (moduleName === "subcategory") {
      dispatch(updateTooltipDescription(payload))
        .then(() => dispatch(getAssetSubCategoryApi()))
        .then(handleClose);
    } else if (moduleName === "category") {
      dispatch(updateTooltipDescription(payload))
        .then(() => dispatch(getAssetCategoryApi()))
        .then(handleClose);
    }
  }

  const handleClose = () => {
    setOpenChangeTitle(false);
  };

  return (
    <DialogCommon
      title={" "}
      handleClose={handleClose}
      open={openChangeTitle}
      extraPaperClasses={`singleFileInput`}
      maxWidth={"md"}
    >
      <UserDetailDialogDesign>
        <Box className="popup-main AddUserDetails addEditCollectionType">
          <DialogContent>
            <Box className="requesOuter">
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <Box className="formSection">
                    <Box className="request-common">
                      <label className="label">{t("description")}</label>
                      <TextField
                        placeholder={t("description")}
                        rows={3}
                        size="small"
                        variant="outlined"
                        type="text"
                        multiline={true}
                        name="description"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.description}
                      />
                      <span className="errorClass">
                        {formik.errors.description &&
                          formik.touched.description &&
                          formik.errors.description}
                      </span>
                    </Box>
                    <DialogActions className="popup-button-outer">
                      <StyledButton
                        variant={"contained"}
                        type="submit"
                        disabled={updateLoading}
                      >
                        {t("save")}
                      </StyledButton>
                      <CustomButton
                        variant={"contained"}
                        onClick={() => setOpenChangeTitle(false)}
                        disabled={updateLoading}
                      >
                        {t("cancel")}
                      </CustomButton>
                    </DialogActions>
                  </Box>
                </form>
              </FormikProvider>
            </Box>
          </DialogContent>
        </Box>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
};

export default AddEditFileType;
