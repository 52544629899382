import React, { useState } from "react";
import { styled } from "@mui/system";
import InfoIcon from "components/Icons/InfoIcon/InfoIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import ContributePopupTooltip from "components/TooltipComponent/ContributePopupTooltip";

const ContributePopSectionHeaderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
  color: theme.palette.blackColor.main,
  ".contributePopRequreField": {
    color: theme.palette.errorColor.main,
  },
  "&.showTooltip": {
    "svg.infoIcon": {
      path: {
        stroke: theme.palette.primaryColor.main,
      },
    },
  },
}));

const ContributePopSectionHeader = ({
  sectionHeading,
  isRequired,
  tooltipHeading,
  tooltipContent,
  id,
  tooltipClass,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <ContributePopSectionHeaderStyle
      className={showTooltip ? "showTooltip" : ""}
      id={id ? id : ""}
    >
      <TextTag variant={textVariants._12px.semiBold}>
        {sectionHeading}
        <span className="contributePopRequreField">{isRequired && "*"}</span>
      </TextTag>
      {(tooltipHeading || tooltipContent) && (
        <InfoIcon
          className={"infoIcon"}
          onClick={() => setShowTooltip((prev) => !prev)}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        />
      )}
      {showTooltip && (
        <ContributePopupTooltip
          heading={tooltipHeading}
          setIsOpen={setShowTooltip}
          tooltipClass={tooltipClass}
          content={
            tooltipContent ? tooltipContent : "Tooltip data not available."
          }
        />
      )}
    </ContributePopSectionHeaderStyle>
  );
};

export default ContributePopSectionHeader;
