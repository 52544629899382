import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import { ClickAwayListener, Grow, Paper, Popper } from "@mui/material";
import { styled } from "@mui/system";
import PlusIcon from "components/Icons/PlusIcon/PlusIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { contributerDataState } from "pages/User-Pages/ContributePage/features/contributionSlice";
import MainButton, {
  buttonVariants,
  MainButtonWithChildren,
} from "components/Button/MainButton";
import ContributePopupHeader from "components/ContributePopup/ContributePopupHeader/ContributePopupHeader";
import ContributePopSectionHeader from "components/ContributePopup/ContributePopupBody/components/ContributePopSectionHeader/ContributePopSectionHeader";
import ContributePopTextfieldComp from "components/Textfield/ContributePopTextfieldComp";
import { PopupFooterStyles } from "components/ContributePopup/ContributePopupFooter/ContributePopupFooter";
import {
  createSingleContributeAlbum,
  deleteSingleContributeAlbum,
} from "pages/User-Pages/ContributePage/features/contributionAction";
import CheckboxWithLabel from "components/Checkbox/CheckboxWithLabel";
import { ButtonMiniIconWithChildren } from "components/Button/ButtonMiniIcon";
import TickIcon from "components/Icons/TickIcon/TickIcon";
import TextfieldWithIcon from "components/Textfield/TextfieldwithIcon";
import { ContributePopupStyles } from "components/ContributePopup/contributePopupStyles";
import {
  changeFilteredAlbums,
  filteredAlbums,
} from "../../features/uploadScreenSlice";
import TrashIconOutline from "components/Icons/Trash/TrashIconOutline";
import { PopupBodyStyles } from "components/ContributePopup/ContributePopupBody/ContributePopupBody";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import { StyledContributePopupSaveButton } from "components/Button/ContributePopupSaveButton";
import { capitalizeWords } from "helper/helper";

export const StyledAlbumPopper = styled("div")(({ theme }) => ({
  padding: "16px",
  display: "grid",
  gap: "16px",
  maxWidth: "221px",
  button: {
    maxWidth: "100%",
    padding: "8px 16px",
    height: "auto",
    gap: "8px",
    "&.addAlbum": {
      width: "100%",
      maxWidth: "100%",
      "&:hover": {
        color: theme.palette.white.main,
        background: theme.palette.primaryColor.main,
        borderColor: theme.palette.primaryColor.main,
        svg: { path: { stroke: theme.palette.white.main } },
      },
    },
    "&.albumFilterButton": {
      justifyContent: "center",
      svg: {
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
    },
  },
  ".albumCheckboxList": {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    ".albumCheckbox": {
      display: "flex",
      // textTransform: "capitalize",
      gap: "8px",
      cursor: "pointer",
      label: {
        ":has(input:checked)": {
          color: theme.palette.primaryColor.main,
        },
      },
      ".iconBlock": {
        width: "24px",
        height: "24px",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        border: `1px solid ${theme.palette.gray_200.main}`,
        borderRadius: "8px",
        "&:hover": {
          backgroundColor: theme.palette.primaryColor.main,
          borderColor: theme.palette.primaryColor.main,
          svg: {
            path: {
              stroke: theme.palette.whiteColor.main,
            },
          },
        },
      },
      "&:hover": {
        ".iconBlock": {
          display: "flex",
        },
      },
    },
  },
}));

export const AddAlbumPopup = ({
  setAddAlbumPopup,
  collection_id,
  setToggleSnack,
}) => {
  //this popup is used to create new album
  const albumInputRef = useRef();
  const dispatch = useDispatch();

  const [allbumInputError, setAlbumInputError] = useState(false);

  const createAlbumChangeHandler = (event) => {
    //when user is inputting the value
    const value = event?.target?.value;
    const trimmedValue = value?.trim();
    if (!value) {
      setAlbumInputError(t("pleaseEnterName"));
    } else if (trimmedValue === "") {
      setAlbumInputError(t("pleaseEnterName"));
    } else if (!validationRegex.checkForDoubleSpaces.test(trimmedValue)) {
      setAlbumInputError(validationMessages.noConsecutiveDoubleSpaces);
    } else {
      setAlbumInputError(null);
    }
  };

  const createAlbumHandler = () => {
    //Create new album
    const input = albumInputRef.current;
    const value = input?.value.trim();

    if (!value) {
      setAlbumInputError(t("pleaseEnterName"));
    } else if (value === "") {
      setAlbumInputError(t("pleaseEnterName"));
    }

    if (value && !allbumInputError) {
      dispatch(
        createSingleContributeAlbum({
          name: value,
          collection_id,
          noToast: true, //don't show toast
        }),
      ).then((res) => {
        if (res?.error || res?.payload?.error) {
          setToggleSnack((prev) => ({
            ...prev,
            errorSnack: {
              status: true,
              message: res?.payload?.error?.message || t("anErrorOccured"),
            },
          }));
          return;
        }
        setToggleSnack((prev) => ({ ...prev, newAlbumCreated: true }));
      });
      handleClosePopup();
      albumInputRef.current.value = "";
      return;
    }
    input?.focus();
    // input?.style.setProperty("border-color", "red");
    // setTimeout(() => {
    //   input?.style.setProperty("border-color", "");
    // }, 2000);
  };

  const handleClosePopup = () => {
    setAddAlbumPopup(false);
    albumInputRef.current.value = "";
  };

  return (
    <ContributePopupStyles className="addAlbumPopup">
      <ClickAwayListener onClickAway={handleClosePopup}>
        <div className="contributePopupMain">
          <ContributePopupHeader
            handlePopupClose={handleClosePopup}
            popupTitle={t("createAlbum")}
          />
          <PopupBodyStyles className="addAlbumPopupBody">
            <section className="dialog-content">
              <ContributePopSectionHeader
                sectionHeading={t("albumName")}
                isRequired={true}
                tooltipHeading={false}
                tooltipContent={false}
              />
              <ContributePopTextfieldComp
                type="text"
                className="albumInput"
                compRef={albumInputRef}
                onChange={createAlbumChangeHandler}
              />
              {allbumInputError && (
                <span className="errorClass">{allbumInputError}</span>
              )}
            </section>
          </PopupBodyStyles>
          <PopupFooterStyles className="addAlbunFooter">
            <MainButton
              title={t("cancel")}
              variant={buttonVariants.grey}
              onClick={handleClosePopup}
            />
            <StyledContributePopupSaveButton
              className={`${
                allbumInputError ? "disableSave" : ""
              } singleButton`}
            >
              <div className="createProjectBtn">
                <MainButtonWithChildren
                  title={t("createAlbum")}
                  className="createAlbum"
                  variant={buttonVariants.blue}
                  onClick={createAlbumHandler}
                >
                  <PlusIcon />
                  <p>{t("create")}</p>
                </MainButtonWithChildren>
              </div>
            </StyledContributePopupSaveButton>
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

export const DeleteAlbumPopup = ({
  deleteAlbum,
  setDeleteAlbum,
  collection_id,
  setToggleSnack,
}) => {
  const dispatch = useDispatch();

  const closeAlbumPopUp = () => {
    setDeleteAlbum(false);
  };

  const handleAlbumDelete = () => {
    dispatch(
      deleteSingleContributeAlbum({
        ids: [deleteAlbum?._id],
        collection_id,
        noToast: true, //do not show toast after api run
      }),
    ).then((res) => {
      if (res?.error) return;
      setToggleSnack((prev) => ({ ...prev, albumDeleted: true }));
      dispatch(changeFilteredAlbums([]));
    });
    closeAlbumPopUp();
  };

  return (
    <ContributePopupStyles className="addAlbumPopup">
      <ClickAwayListener onClickAway={closeAlbumPopUp}>
        <div className="contributePopupMain">
          <ContributePopupHeader
            handlePopupClose={closeAlbumPopUp}
            popupTitle={`${capitalizeWords(t("areYouDeleteAlbum"))} ${
              deleteAlbum?.name
            }?`}
          />
          <PopupFooterStyles className="addAlbunFooter uploadDelete">
            <MainButton
              onClick={closeAlbumPopUp}
              variant={buttonVariants.grey}
              title={t("cancel")}
            />
            <MainButton
              onClick={handleAlbumDelete}
              variant={buttonVariants.red}
              title={t("remove")}
              className="deleteBtn"
            />
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

const AlbumPopper = ({
  albumDropdown,
  albumDropdownRef,
  setAlbumDropdown,
  setAddAlbumPopup,
  setDeleteAlbum,
  setCheckedAssetIds,
}) => {
  const { allAlbumsData } = useSelector(contributerDataState);
  const filteredAlbumsData = useSelector(filteredAlbums);
  const dispatch = useDispatch();
  const albumDataArr = allAlbumsData?.collection_albums?.[0]?.album_data;

  const [selectedAlbumIds, setSelectedAlbumIds] = useState(filteredAlbumsData);
  const [albumData, setAlbumData] = useState([albumDataArr]);

  useEffect(() => {
    setAlbumData(albumDataArr);
  }, [albumDataArr]);

  const handleClose = () => {
    setAlbumDropdown(false);
  };

  const onAlbumChange = (data) => {
    const idIndex = selectedAlbumIds?.findIndex((x) => x === data?._id);
    if (idIndex === -1) {
      setSelectedAlbumIds((prev) => [...prev, data?._id]);
    } else {
      setSelectedAlbumIds((prev) => {
        const updated = [...prev];
        updated.splice(idIndex, 1);
        return updated;
      });
    }
  };

  const handleAlbumSearch = (e) => {
    const value = e.target.value?.toLowerCase();
    setAlbumData((prev) =>
      value === "" || !value
        ? albumDataArr
        : prev?.filter((x) => x?.name?.toLowerCase()?.includes(value)),
    );
  };

  const handleFilterAlbum = () => {
    dispatch(changeFilteredAlbums(selectedAlbumIds));
    setCheckedAssetIds([]);
    handleClose();
  };

  const handleAlbumDelete = (data) => {
    setDeleteAlbum(data);
  };

  return (
    <Popper
      sx={{ top: "8px !important", left: "16px!important" }}
      open={albumDropdown}
      anchorEl={albumDropdownRef.current}
      placement="bottom-start"
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Paper sx={{ boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07);" }}>
            <ClickAwayListener onClickAway={handleClose}>
              <StyledAlbumPopper className="addAlbumPopper">
                <MainButtonWithChildren
                  variant={buttonVariants?.grey}
                  className="addAlbum"
                  onClick={() => setAddAlbumPopup(true)}
                >
                  <span>{<PlusIcon />}</span>
                  <TextTag variant={textVariants._14px.medium}>
                    {t("createAlbum")}
                  </TextTag>
                </MainButtonWithChildren>
                {albumDataArr?.length > 4 && (
                  <TextfieldWithIcon
                    onChange={handleAlbumSearch}
                    className="albumSearch"
                  />
                )}
                <div className="albumCheckboxList">
                  {albumData?.length <= 0 ? (
                    <div className="noAlbumFound">
                      <TextTag variant={textVariants._12px.medium}>
                        {t("noDataFound")}
                      </TextTag>
                    </div>
                  ) : (
                    albumData?.map((data, index) => {
                      return (
                        <div className="albumCheckbox" key={index}>
                          <CheckboxWithLabel
                            onChange={() => onAlbumChange(data)}
                            labelName={data?.name}
                            checked={selectedAlbumIds?.includes(data?._id)}
                          />
                          <div className="iconBlock">
                            <TrashIconOutline
                              className={"albumDelete"}
                              onClick={() => handleAlbumDelete(data)}
                            />
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
                <ButtonMiniIconWithChildren
                  onClick={handleFilterAlbum}
                  className={"albumFilterButton"}
                >
                  <TickIcon />
                  <TextTag variant={textVariants._12px.medium}>
                    {t("filter")}
                  </TextTag>
                </ButtonMiniIconWithChildren>
              </StyledAlbumPopper>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

export default AlbumPopper;
