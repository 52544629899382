export const captureFrame = (videoElement) => {
  if (!videoElement) {
    console.error("Video element is not available");
    return null;
  }

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = videoElement.videoWidth;
  canvas.height = videoElement.videoHeight;

  context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

  const imageUrl = canvas.toDataURL("image/png");
  // console.log("Captured Image URL:", imageUrl);

  // Convert the base64 image to a file object
  const base64ToFile = (base64Data, fileName) => {
    const [header, base64String] = base64Data.split(",");
    const mime = header.match(/:(.*?);/)[1];
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArrays.push(new Uint8Array(byteNumbers));
    }
    return new File(byteArrays, fileName, { type: mime });
  };

  // Create a file object for the captured frame
  const frameFile = base64ToFile(imageUrl, "captured-frame.png");
  //   console.log("Captured Frame File:", frameFile);

  return imageUrl;
};
