import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

//adminGetFieldsDataByCollectionId
export const getQuickThumb = createAsyncThunk(
  "thumbnailModule/getQuickThumb",
  async (paramData) => {
    try {
      let apiUrl;
      if (!paramData?.limit || !paramData?.page) {
        apiUrl = `${apiRoutes.getQuickThumb}?sortOrder=asc&sortBy=order_index&limit=999999`;
      } else {
        const { sortBy, sortOrder, limit, page } = paramData;
        apiUrl = `${apiRoutes.getQuickThumb}?sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// //getQuickThumb
// export const getQuickThumb = createAsyncThunk(
//   "thumbnailModule/getQuickThumb",
//   async (body) => {
//     try {
//       return await performApiRequest(() =>
//         axiosConfig.get(apiRoutes.getQuickThumb),
//       );
//     } catch (error) {
//       return { success: false, error: error.response.data };
//     }
//   },
// );

export const getQuickThumbnailApi = createAsyncThunk(
  "thumbnailModule/getQuickThumbnailApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getQuickThumbnail),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateQuickThumbnailApi = createAsyncThunk(
  "thumbnailModule/updateQuickThumbnailApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.updateQuickThumbnail, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminAddQuickThumb
export const adminAddQuickThumb = createAsyncThunk(
  "thumbnailModule/adminAddQuickThumb",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminAddQuickThumb, body),
      );
      return dispatch(getQuickThumb());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//delete
export const deleteQuickThumb = createAsyncThunk(
  "thumbnailModule/deleteQuickThumb",
  async (depIds, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.deleteQuickThumb, depIds.body),
      );
      return dispatch(getQuickThumb(depIds.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//dragDropQuickThumb
export const dragDropQuickThumb = createAsyncThunk(
  "thumbnailModule/dragDropQuickThumb",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.dragDropQuickThumb, data?.body),
      );
      return dispatch(getQuickThumb(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
