import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import ArrowRightIcon from "components/Icons/ArrowIcons/ArrowRightIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { getActiveAssetCategoryByType } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategoryAction";
import {
  allActiveAssetCategoryByType,
  assetCategoryLoadingState,
} from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";
import { getCuratedGallery } from "pages/Admin-Pages/cms-changes/CategoryQuickLinks/features/categoryQuickLinkAction";
import { curatedGalleryHeaderData } from "pages/Admin-Pages/cms-changes/CategoryQuickLinks/features/categoryQuickLinkSlice";
import HeaderCuratedGallery from "./HeaderCuratedGallery";
import { headerMenuObj } from "Layout/AdminLayout/Header/HeaderNew";
import ArrowUpIcon from "components/Icons/ArrowIcons/ArrowUpIcon";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";
import { Loader } from "components/Loader/Loader";
import { deepCopy } from "helper/deepCopyFunction";
import { routeConfigs } from "utils/routeConfig";
import HeaderHoverMenuStyles from "../HeaderHoverMenuStyles";
import { useDebounce } from "helper/debounceHelper";

const MoreType = ({
  typeData,
  setHeaderTypeData1,
  setActiveTypeIndex,
  isIndexActive,
  index,
  TypeElement,
  ...props
}) => {
  const [isMoreTypeActive, setIsMoreTypeActive] = useState(false);

  return (
    <HeaderHoverMenuStyles
      className={`headerItmeAndMenu has-child ${
        isMoreTypeActive || isIndexActive ? "active" : "inactive"
      }`}
      onMouseEnter={() => {
        setIsMoreTypeActive(true);
        setHeaderTypeData1(typeData);
        setActiveTypeIndex(index);
      }}
      onMouseLeave={() => {
        setIsMoreTypeActive(false);
        setHeaderTypeData1([]);
        setActiveTypeIndex();
      }}
      {...props}
    >
      <div className="headerTextItem">
        <TextTag variant={textVariants._14px.medium}>{typeData?.name}</TextTag>
        {typeData?.category_count > 0 &&
          (isMoreTypeActive || isIndexActive ? (
            <ArrowUpIcon />
          ) : (
            <ArrowDownIcon />
          ))}
      </div>
      {typeData?.category_count > 0 && isMoreTypeActive && TypeElement}
    </HeaderHoverMenuStyles>
  );
};

const TypeElement = ({ hasChild, smallHeaderMenu, handleMouseLeave }) => {
  const curatedGalleryData = useSelector(
    curatedGalleryHeaderData,
  )?.curatedGallery;

  const galleryData = deepCopy(curatedGalleryData);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const allCategoryByType = useSelector(allActiveAssetCategoryByType).data;
  const catLoading = useSelector(assetCategoryLoadingState);

  const [activeCatIndex, setActiveCatIndex] = useState(0);
  const [oneTypeShowing, setOneTypeShowing] = useState(false);

  const changedIndex = useDebounce(activeCatIndex, 300);

  const sortedGalleryData = useMemo(() => {
    if (!galleryData || galleryData?.length <= 0) {
      return [];
    }
    return galleryData?.sort((a, b) => a?.display_index - b?.display_index);
  }, [galleryData]);

  useEffect(() => {
    allCategoryByType?.length > 0 &&
      allCategoryByType[changedIndex] &&
      dispatch(getCuratedGallery(allCategoryByType[changedIndex]?._id));
  }, [changedIndex, allCategoryByType, dispatch]);

  useEffect(() => {
    document.body.classList.add("darkBackground");

    return () => {
      document.body.classList.remove("darkBackground");
    };
  }, []);

  return (
    <div
      className={`headerTypeMenuMain ${
        smallHeaderMenu ? "megaMenu smallHeaderMenu" : "megaMenu"
      } ${hasChild ? "has-child" : ""}`}
    >
      {catLoading ? (
        <div className="headerTypeMenu">
          <Loader />
        </div>
      ) : allCategoryByType?.length > 0 ? (
        <div className="headerTypeMenu">
          <div className="menuLeftSection megaMenusCard">
            {allCategoryByType?.map((catData, index) => {
              return (
                <div
                  key={catData?._id}
                  className={`leftListHeading ${
                    activeCatIndex === index && !smallHeaderMenu ? "active" : ""
                  }`}
                  onClick={() => {
                    if (smallHeaderMenu) {
                      navigate(
                        `${routeConfigs.searchPage}?cat=${allCategoryByType[activeCatIndex]?._id}`,
                      );
                    } else {
                      setActiveCatIndex(index);
                    }
                  }}
                  onMouseEnter={() => setActiveCatIndex(index)}
                >
                  <TextTag variant={textVariants._14px.medium}>
                    {catData?.name}
                  </TextTag>
                  {!smallHeaderMenu && <ArrowRightIcon />}
                </div>
              );
            })}
          </div>
          {!smallHeaderMenu && (
            <div className="menuMiddleSection megaMenusCard">
              <div className="menuMiddleTop">
                <TextTag variant={textVariants._18px.medium}>
                  {allCategoryByType[activeCatIndex]?.name}
                </TextTag>
              </div>
              <div className="menuMiddleCenter">
                <TextTag variant={textVariants._14px.regular}>
                  {allCategoryByType[activeCatIndex]?.description}
                </TextTag>
              </div>
              <div
                className="menuMiddleBottom"
                onClick={() => navigate(routeConfigs.searchPage)}
              >
                <ArrowRightIcon />
                <TextTag variant={textVariants._14px.medium}>
                  {t("seeAllName", {
                    name: allCategoryByType[activeCatIndex]?.name,
                  })}
                </TextTag>
              </div>
            </div>
          )}
          {!smallHeaderMenu && (
            <div className="headerRightSection megaMenusCard">
              <div className="headerCollecRightMain">
                <div className="headerCollecRightAlphabet">
                  <div className="curatedGallerySections">
                    {sortedGalleryData?.length > 0 ? (
                      sortedGalleryData?.map((curatedData, index) => {
                        return (
                          <HeaderCuratedGallery
                            curatedData={curatedData}
                            key={index}
                            oneTypeShowing={oneTypeShowing}
                            setOneTypeShowing={setOneTypeShowing}
                          />
                        );
                      })
                    ) : (
                      <div className="noDataFound">
                        <TextTag variant={textVariants._12px.medium}>
                          {t("")}
                        </TextTag>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="headerTypeMenu">
          <div className="noDataFound">
            <TextTag variant={textVariants._12px.medium}>{t("")}</TextTag>
          </div>
        </div>
      )}
      <div
        className="hoverMenuClose"
        onMouseEnter={handleMouseLeave}
        onMouseMove={handleMouseLeave}
      />
    </div>
  );
};

const HeaderTypeMenu = (props) => {
  const {
    headerTypeData,
    menuType,
    allTypes,
    setActiveTypeIndex,
    activeTypeIndex,
    handleMouseLeave,
  } = props;

  const dispatch = useDispatch();
  const [headerTypeData1, setHeaderTypeData1] = useState(headerTypeData);

  const smallHeaderMenu =
    headerTypeData?.category_count === 1 &&
    headerTypeData?.linked_curated_gallery === 0;

  useEffect(() => {
    if (headerTypeData1 && headerTypeData1?._id)
      dispatch(getActiveAssetCategoryByType(headerTypeData1?._id));
  }, [dispatch, headerTypeData1]);

  return (
    <>
      {menuType === headerMenuObj.more ? (
        <div
          className={`headerTypeMenuMain ${
            smallHeaderMenu ? "megaMenu smallHeaderMenu" : "megaMenu"
          }`}
        >
          <div className="moreTypes">
            {allTypes?.length > 0 &&
              allTypes?.map((typeData, index) => {
                return (
                  <MoreType
                    typeData={typeData}
                    key={typeData}
                    setHeaderTypeData1={setHeaderTypeData1}
                    isIndexActive={index === activeTypeIndex}
                    setActiveTypeIndex={setActiveTypeIndex}
                    index={index}
                    TypeElement={
                      <TypeElement
                        hasChild={true}
                        key={activeTypeIndex}
                        handleMouseLeave={handleMouseLeave}
                        smallHeaderMenu={smallHeaderMenu}
                      />
                    }
                  />
                );
              })}
          </div>
        </div>
      ) : (
        <TypeElement
          hasChild={false}
          smallHeaderMenu={smallHeaderMenu}
          handleMouseLeave={handleMouseLeave}
        />
      )}
    </>
  );
};

export default HeaderTypeMenu;
