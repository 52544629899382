import * as Yup from "yup";
import { t } from "i18next";
import { validationMessages, validationRegex } from "../validationUtils";

const allowedFormats = ["image/jpeg", "image/jpg", "image/bmp", "image/png"];

export const CategorySchema = Yup.object().shape({
  files: Yup.mixed()
    .test("fileSize", validationMessages.pleaseUploadImageUptoOneMB, (file) => {
      if (file) {
        return file.size <= 1100000;
      } else {
        return true;
      }
    })
    .test(
      "fileFormat",
      validationMessages.pleaseUploadImageInValidFormat,
      (file) => {
        if (!file) return true; // Allow empty file
        return allowedFormats.includes(file.type);
      },
    )
    .notRequired(),
  type: Yup.string().trim().required(validationMessages.pleaseSelectAssetType),
  name: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(validationRegex.alphanumeric, validationMessages.alphanumericOnly)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterAssetCategory),
  description: Yup.string()
    .trim()
    .max(250, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    ),
});

export const QuickThumbsSchema = Yup.object().shape({
  type: Yup.string().trim().required(t("pleaseSelectOption")),
  category_id: Yup.string().when("type", {
    is: "category",
    then: () =>
      Yup.string()
        .trim()
        .required(validationMessages.pleaseSelectAssetCategory),
  }),
  subcategory_id: Yup.string().when("type", {
    is: "subCategory",
    then: () =>
      Yup.string()
        .trim()
        .required(validationMessages.pleaseSelectAssetSubCategory),
  }),
});
