import React from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { ClickAwayListener } from "@mui/material";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import ContributePopupHeader from "components/ContributePopup/ContributePopupHeader/ContributePopupHeader";
import { cancelLoading } from "../../features/uploadScreenSlice";
import { PopupFooterStyles } from "components/ContributePopup/ContributePopupFooter/ContributePopupFooter";
import { ContributePopupStyles } from "components/ContributePopup/contributePopupStyles";
import { capitalizeWords } from "helper/helper";
import { saveButtonIds } from "../UploadSaveButton";

const PublishConfirmPopup = ({ setPublishConfirmPopup, onButtonClick }) => {
  const loading = useSelector(cancelLoading);
  const popupTitle = t("confirmAssetPublishText");

  const handlePopupClose = () => {
    setPublishConfirmPopup(false);
  };

  return (
    <ContributePopupStyles className="cancelUploadProcess">
      <ClickAwayListener onClickAway={handlePopupClose}>
        <div className="contributePopupMain">
          <ContributePopupHeader
            handlePopupClose={handlePopupClose}
            popupTitle={capitalizeWords(popupTitle)}
          />
          <PopupFooterStyles className="addAlbunFooter">
            <MainButton
              onClick={handlePopupClose}
              variant={buttonVariants.grey}
              title={t("cancel")}
              disabled={loading}
            />
            <MainButton
              onClick={() => {
                onButtonClick({ id: saveButtonIds?.publish });
                setPublishConfirmPopup(false);
              }}
              variant={buttonVariants.blue}
              title={t("publish")}
              className="deleteBtn"
              disabled={loading}
            />
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

export default PublishConfirmPopup;
