import { createSlice } from "@reduxjs/toolkit";
import {
  createSingleContributeAlbum,
  deleteSingleContributeAlbum,
  getAllContributeAlbums,
  getContributionById,
  getCustomTagDatas,
  uploadAssetsForContribution,
  updateKeywordsContribute,
  updateContributeDetailsApi,
  updateContributeLocationApi,
  getAllDigitalAssetsInCollectionDraft,
  deleteAssetDraftCollection,
  getAssetFileProperties,
  getAssetAiTags,
  getAsssetAiDescription,
} from "./contributionAction";
const initialState = {
  contributerLoading: false,
  allAlbumsLoading: false,
  createAlbumLoading: false,
  deleteAlbumLoading: false,
  contributerData: [],
  assetCollectionDraft: [],
  createAlbumData: [],
  deleteAlbumData: [],
  allAlbumsData: [],
  customTagData: [],
  customTagLoading: false,
  uploadAssetsLoading: false,
  uploadAssetsData: [],
  uploadingAssetProgress: {},
  uploadingTemporaryFiles: [],
  keyWordUpdateLoading: false,
  assetFileProperties: [], //file properties of individual asset
  assetAiTags: {}, //ai tags for asset
  aiKeyWordsLoading: false, //for loader of ai keywords
  assetAiDescription: {}, //ai asset description
};

const contributionSlice = createSlice({
  name: "contributionSlice",
  initialState,
  reducers: {
    updateProgress(state, action) {
      const [key, value] = action.payload;
      if (!state.uploadingAssetProgress[key]) {
        state.uploadingAssetProgress[key] = 0;
      }
      state.uploadingAssetProgress[key] = value;
      // state.progress = action.payload;
    },
    temporaryUploadingFiles(state, action) {
      const [temporaryFiles, defaultfilesLoading] = action.payload;
      state.uploadingTemporaryFiles = [
        ...state.uploadingTemporaryFiles,
        ...temporaryFiles,
      ];
      state.uploadingAssetProgress = {
        ...state.uploadingAssetProgress,
        ...defaultfilesLoading,
      };
    },
    updateTemporaryFiles(state, action) {
      const key = action?.payload;
      return {
        ...state,
        uploadingTemporaryFiles: state.uploadingTemporaryFiles.map((obj) =>
          obj?.randomId === key ? { ...obj, status: "rejected" } : obj,
        ),
      };
    },
    removeTemporaryFiles(state, action) {
      const key = action?.payload;
      return {
        ...state,
        uploadingTemporaryFiles: state.uploadingTemporaryFiles.filter(
          (val) => val?.randomId !== key,
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getContributionById.pending, (state) => {
        state.contributerLoading = true;
      })
      .addCase(getContributionById.fulfilled, (state, action) => {
        state.contributerLoading = false;
        state.contributerData = action.payload;
      });

    builder
      .addCase(getAllDigitalAssetsInCollectionDraft.pending, (state) => {
        state.contributerLoading = true;
      })
      .addCase(
        getAllDigitalAssetsInCollectionDraft.fulfilled,
        (state, action) => {
          state.contributerLoading = false;
          state.assetCollectionDraft = action.payload;
        },
      );

    builder
      .addCase(getAllContributeAlbums.pending, (state) => {
        state.allAlbumsLoading = true;
      })
      .addCase(getAllContributeAlbums.fulfilled, (state, action) => {
        state.allAlbumsLoading = false;
        state.allAlbumsData = action.payload;
      });
    builder
      .addCase(createSingleContributeAlbum.pending, (state) => {
        state.createAlbumLoading = true;
      })
      .addCase(createSingleContributeAlbum.fulfilled, (state, action) => {
        state.createAlbumLoading = false;
        state.createAlbumData = action.payload;
      });
    builder
      .addCase(deleteSingleContributeAlbum.pending, (state) => {
        state.deleteAlbumLoading = true;
      })
      .addCase(deleteSingleContributeAlbum.fulfilled, (state, action) => {
        state.deleteAlbumLoading = false;
        state.deleteAlbumData = action.payload;
      });
    //deleteAssetDraftCollection
    builder
      .addCase(deleteAssetDraftCollection.pending, (state) => {
        state.deleteAlbumLoading = true;
      })
      .addCase(deleteAssetDraftCollection.fulfilled, (state, action) => {
        state.deleteAlbumLoading = false;
        state.deleteAlbumData = action.payload;
      });
    builder
      .addCase(getCustomTagDatas.pending, (state) => {
        state.customTagLoading = true;
      })
      .addCase(getCustomTagDatas.fulfilled, (state, action) => {
        state.customTagLoading = false;
        state.customTagData = action.payload;
      })
      .addCase(getCustomTagDatas.rejected, (state, action) => {
        state.customTagLoading = false;
      });
    builder
      .addCase(uploadAssetsForContribution.pending, (state) => {
        state.uploadAssetsLoading = true;
      })
      .addCase(uploadAssetsForContribution.fulfilled, (state, action) => {
        state.uploadAssetsLoading = false;
        state.uploadAssetsData = action.payload;

        return state;
      })
      .addCase(uploadAssetsForContribution.rejected, (state, action) => {
        state.uploadAssetsLoading = false;
      });

    builder
      .addCase(updateKeywordsContribute.pending, (state) => {
        state.keyWordUpdateLoading = true;
      })
      .addCase(updateKeywordsContribute.fulfilled, (state, action) => {
        state.keyWordUpdateLoading = false;
      })
      .addCase(updateKeywordsContribute.rejected, (state, action) => {
        state.keyWordUpdateLoading = false;
      });
    //updateContributeDetailsApi
    builder
      .addCase(updateContributeDetailsApi.pending, (state) => {
        state.customTagLoading = true;
      })
      .addCase(updateContributeDetailsApi.fulfilled, (state, action) => {
        state.customTagLoading = false;
        state.customTagData = action.payload;
      })
      .addCase(updateContributeDetailsApi.rejected, (state, action) => {
        state.customTagLoading = false;
      });
    //updateContributeLocationApi
    builder
      .addCase(updateContributeLocationApi.pending, (state) => {
        state.customTagLoading = true;
      })
      .addCase(updateContributeLocationApi.fulfilled, (state, action) => {
        state.customTagLoading = false;
        state.customTagData = action.payload;
      })
      .addCase(updateContributeLocationApi.rejected, (state, action) => {
        state.customTagLoading = false;
      });
    //getAssetFileProperties
    builder
      .addCase(getAssetFileProperties.pending, (state) => {
        state.customTagLoading = true;
      })
      .addCase(getAssetFileProperties.fulfilled, (state, action) => {
        state.customTagLoading = false;
        state.assetFileProperties = action.payload;
      })
      .addCase(getAssetFileProperties.rejected, (state, action) => {
        state.customTagLoading = false;
      });
    //getAssetAiTags
    builder
      .addCase(getAssetAiTags.pending, (state) => {
        state.aiKeyWordsLoading = true;
      })
      .addCase(getAssetAiTags.fulfilled, (state, action) => {
        state.aiKeyWordsLoading = false;
        state.assetAiTags = action.payload;
      })
      .addCase(getAssetAiTags.rejected, (state, action) => {
        state.aiKeyWordsLoading = false;
      });
    //getAsssetAiDescription
    builder
      .addCase(getAsssetAiDescription.pending, (state) => {
        state.aiKeyWordsLoading = true;
      })
      .addCase(getAsssetAiDescription.fulfilled, (state, action) => {
        state.aiKeyWordsLoading = false;
        state.assetAiDescription = action.payload;
      })
      .addCase(getAsssetAiDescription.rejected, (state, action) => {
        state.aiKeyWordsLoading = false;
      });
  },
});
export const {
  updateProgress,
  temporaryUploadingFiles,
  updateTemporaryFiles,
  removeTemporaryFiles,
} = contributionSlice.actions;
export const contributerDataState = (state) => {
  return state?.contributionSlice;
};
export const assetFileProperties = (state) => {
  return state?.contributionSlice?.assetFileProperties;
};
export const assetAiTags = (state) => {
  return state?.contributionSlice?.assetAiTags;
};
export const aiKeyWordsLoading = (state) =>
  state?.contributionSlice?.aiKeyWordsLoading;
export const assetAiDescription = (state) =>
  state?.contributionSlice?.assetAiDescription;

export default contributionSlice.reducer;
