import React, { useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import { styled } from "@mui/system";
import InfoIcon from "components/Icons/InfoIcon/InfoIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import CrossIcon from "components/Icons/CrossIcon/CrossIcon";

export const snackTypeObj = { info: "info", error: "error" };

const SnackBarStyles = styled("div")(({ theme }) => ({
  background: theme.palette.white.main,
  padding: "16px",
  boxShadow: "0px 4px 15px 0px #00000012",
  borderRadius: "8px",
  width: "371px",
  maxWidth: "371px",
  "&.error": {
    border: "1px solid red",
    ".snackHeader": {
      cursor: "pointer",
      "svg.infoIcon": {
        path: { stroke: "red" },
      },
      "span.headingText": {
        color: "red",
      },
    },
  },
  ".snackHeader": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "svg.infoIcon": {
      path: { stroke: theme.palette.blackColor.main },
    },
    ".headingText ": {
      margin: "0 auto 0 6px",
    },
  },
  ".snackBody": {
    ".rejectedAssetList": {
      marginTop: "8px",
      marginBottom: "8px",
      ".assetName": {
        span: {
          color: theme.palette.gray_600.main,
          wordBreak: "break-word",
        },
      },
    },
    ".seeAllLess": {
      background: "transparent",
      border: "0",
      color: theme.palette.primaryColor.main,
      cursor: "pointer",
      height: "auto",
      marginBottom: "16px",
      paddingLeft: 0,
    },
  },
}));

const SnackbarComp = ({
  vertical, //optional default->bottom
  horizontal, //optional default->right
  autoHideDuration, //optional
  snackOpen,
  snackHeading,
  snackType,
  snackBody,
  onClose,
}) => {
  const [open, setOpen] = useState(snackOpen);

  useEffect(() => {
    setOpen(snackOpen);
  }, [snackOpen]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Snackbar
      open={open}
      anchorOrigin={{
        vertical: vertical ? vertical : "bottom",
        horizontal: horizontal ? horizontal : "right",
      }}
      onClose={handleClose}
      autoHideDuration={autoHideDuration}
      sx={{
        "&.MuiSnackbar-root": { bottom: "96px" },
      }}
    >
      <SnackBarStyles
        className={snackType === snackTypeObj.error ? "error" : "info"}
      >
        <div className="snackHeader">
          <InfoIcon className={"infoIcon"} />
          {typeof snackHeading === "string" ? (
            <TextTag
              className={"headingText"}
              variant={textVariants._14px.medium}
            >
              {snackHeading}
            </TextTag>
          ) : (
            snackHeading
          )}
          <CrossIcon onClick={handleClose} className={"crossIcon"} />
        </div>
        <div className="snackBody">
          {typeof snackBody === "string" ? (
            <TextTag
              className={"headingText"}
              variant={textVariants._12px.regular}
            >
              {snackBody}
            </TextTag>
          ) : (
            snackBody
          )}
        </div>
      </SnackBarStyles>
    </Snackbar>
  );
};

export default SnackbarComp;
