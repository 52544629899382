import { styled } from "@mui/system";

export const SearchSimilarCompStyle = styled("div")(({ theme }) => ({
  ".searchSimilarPopupMain": {
    width: "100%",
    maxWidth: "600px",
    background: "#FFFFFF",
    padding: "1.5rem 0",
    borderRadius: "0.5rem",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "25px",
    svg: {
      cursor: "pointer",
    },
    "button.createAlbum": {
      svg: {
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
    },

    ".uploadIconBlock": {
      background: "#f2f2f2",
      flexDirection: "column",
      gap: "25px",
      textAlign: "center",
      height: "300px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "2px dashed gray",
      borderRadius: "8px",
      width: "600px",
      "&.dragtrue": {
        borderColor: theme.palette.primaryColor.main,
      },
    },
  },
  "span.errorShow": {
    display: "block",
    textAlign: "center",
    margin: "0",
  },
}));
