import React, {
  isValidElement,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { t } from "i18next";
import UploadImageSvg from "components/Icons/UploadImageIcon/uploadImageSvg";
import Layout from "Layout/AdminLayout/Layout";
import TextTag, { textVariants } from "components/Typography/TextTag";
import {
  deleteAssetDraftCollection,
  getAllContributeAlbums,
  getAllDigitalAssetsInCollectionDraft,
  getAssetFileProperties,
  uploadAssetsForContribution,
} from "../ContributePage/features/contributionAction";
import { useQuery } from "utils/queryString";
import {
  contributerDataState,
  removeTemporaryFiles,
  temporaryUploadingFiles,
} from "../ContributePage/features/contributionSlice";
import staticDocImg from "../../../Assets/doctemplogo.jpg";
import staticVideoImg from "../../../Assets/videoskelton.avif";
import DeleteAssetPopup from "./components/Popups/DeleteAssetPopup";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import NotFoundImage from "../UserPages/home/HomeComponents/FindLookingForSection/images/imageNotFound.png";
import RightUploadSection from "./components/RightUploadSection/RightUploadSection";
import AlbumPopper, {
  AddAlbumPopup,
  DeleteAlbumPopup,
} from "./components/Popups/AlbumPopper";
import {
  assetsData,
  filteredAlbums,
  // publishAssetLoading,
} from "./features/uploadScreenSlice";
import { saveButtonIds } from "./components/UploadSaveButton";
import CancelProcessPopup from "./components/Popups/CancelProcessPopup";
import AddToAlbumPopper from "./components/Popups/AddToAlbumPopper";
import {
  FileDisplayComponent,
  FileUploadComponent,
  UploadFailedComponent,
} from "./components/FileUploadComponents";
import SnackbarComp from "components/SnackbarComp/SnackbarComp";
import { AssetsNotUploadedSnackBody } from "./components/SnackCompBodies";
import TitleCreateAlbumButton from "./components/TitleCreateAlbumButton";
import { publishAssetUsingAssetId } from "./features/uploadScreenAction";
import SchedulePopup from "./components/SchedulePopup";
import UploadScreenStyle from "./UploadScreenStyle";
import { routeConfigs } from "utils/routeConfig";
import { capitalizeWords } from "helper/helper";
import UploadFooter from "./components/UploadFooter";
import { BackdropLoader } from "components/Loader/Loader";
import PublishConfirmPopup from "./components/Popups/PublishConfirmPopup";

let previousValues = 0;
const intitalValues = [<UploadImageSvg />];

const initialSnackStates = {
  // initial states for various snackbars
  rejectedSnacks: false,
  addToAlbumSnack: false,
  assetDeleted: false,
  newAlbumCreated: false,
  albumDeleted: false,
  errorSnack: {
    status: false,
    message: "",
  },
};

const UploadScreen = () => {
  const id = useQuery().get("id");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const albumDropdownRef = useRef(null);
  const addToAlbumRef = useRef(null);

  const filteredAlbumsData = useSelector(filteredAlbums);
  // const publishLoading = useSelector(publishAssetLoading);

  const {
    assetCollectionDraft,
    uploadingAssetProgress,
    uploadingTemporaryFiles,
  } = useSelector(contributerDataState);

  const { allAlbumsData } = useSelector(contributerDataState);
  const assetMetaDatas = useSelector(assetsData);
  const uploadedAssets =
    assetCollectionDraft?.digitalAssetCollection?.[0]?.assets;
  const albumDataArr = allAlbumsData?.collection_albums?.[0]?.album_data;

  const [files, setFiles] = useState();
  const [checkedAssetIds, setCheckedAssetIds] = useState([]);
  const [publishConfirmPopup, setPublishConfirmPopup] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [albumDropdown, setAlbumDropdown] = useState(false);
  const [addAlbumPopup, setAddAlbumPopup] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [deleteAlbum, setDeleteAlbum] = useState(false);
  const [addToAlbumPopper, setAddToAlbumPopper] = useState(false);
  const [toggleSnack, setToggleSnack] = useState(initialSnackStates);
  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  const [scheduleDateTime, setScheduleDateTime] = useState("");
  const [publishLoader, setPublishLoader] = useState(false);
  const [{ collectionName, assetsLength }, setCollectionNameAndLength] =
    useState({
      collectionName: "",
      assetsLength: 0,
    });
  useEffect(() => {
    checkedAssetIds?.length === 1 &&
      dispatch(getAssetFileProperties(checkedAssetIds[0]));
  }, [checkedAssetIds, dispatch]);
  const footerButtonDisabled = !checkedAssetIds || checkedAssetIds?.length <= 0;
  const rejectedFiles = files?.filter((x) => x?.status === "rejected");

  const allFilterAssetIds = useMemo(() => {
    //to filter the assets with the selected albums from top
    if (albumDataArr?.length <= 0 || filteredAlbumsData?.length <= 0) return [];

    const filteredAlbumsAllData = albumDataArr?.filter((x) =>
      filteredAlbumsData?.includes(x?._id),
    );

    const allFilteredAssetIds = new Set();

    filteredAlbumsAllData.forEach((albumData) => {
      if (albumData?.asset_ids) {
        albumData.asset_ids.forEach((assetId) => {
          allFilteredAssetIds.add(assetId);
        });
      }
    });

    return [...allFilteredAssetIds];
  }, [albumDataArr, filteredAlbumsData]);

  useLayoutEffect(() => {
    dispatch(getAllDigitalAssetsInCollectionDraft(id));
    dispatch(getAllContributeAlbums(id));
  }, [dispatch, id]);

  useEffect(() => {
    //use effect to actually upload asset
    const { image_base_url, image_container_name, digitalAssetCollection } =
      assetCollectionDraft;

    const assetsData1 = digitalAssetCollection?.[0]?.assets || [];

    const assetsData =
      allFilterAssetIds?.length <= 0 && filteredAlbumsData?.length <= 0
        ? assetsData1
        : assetsData1?.filter((x) => allFilterAssetIds?.includes(x?._id));

    setCollectionNameAndLength({
      collectionName:
        digitalAssetCollection?.[0]?.name ||
        `#${digitalAssetCollection?.[0]?.unique_id}`,
      assetsLength: assetsData?.length,
    });

    const uploadingFiles = [...uploadingTemporaryFiles].reverse();
    const assetsServerUrl = `${image_base_url}/${image_container_name}`;
    const assetsFileSlugUrls =
      assetsData
        ?.map((asset) => {
          const thumbnailUrl = asset?.thumbnail
            ? `${assetsServerUrl}/${asset?.thumbnail}`
            : asset?.fileType?.startsWith("application")
            ? staticDocImg
            : asset?.fileType?.startsWith("video")
            ? staticVideoImg
            : NotFoundImage;

          return {
            id: asset?._id,
            name: asset?.file_name,
            fileType: asset?.fileType,
            thumbnailUrl,
            //  assetUrl: `${assetsServerUrl}/${asset?.file_slug}`,
          };
        })
        ?.reverse() || [];

    const concatedData = [
      ...intitalValues,
      ...uploadingFiles,
      ...assetsFileSlugUrls,
    ];

    setFiles(concatedData);

    //checking for rejected values to open error snack
    const rejectedFiles = concatedData?.filter((x) => x?.status === "rejected");

    if (rejectedFiles?.length > previousValues) {
      //only open error snack after uploading assets
      setToggleSnack((prev) => ({
        ...prev,
        rejectedSnacks: rejectedFiles?.length > 0,
      }));
    }

    return () => (previousValues = rejectedFiles?.length);
  }, [
    uploadingTemporaryFiles,
    assetCollectionDraft,
    allFilterAssetIds,
    filteredAlbumsData?.length,
    uploadingAssetProgress,
  ]);

  const uploadAssetsHandler = async (files) => {
    //run this function after user uploades the asset(s)
    const temporaryFiles = await files?.reduce((result, file) => {
      const uuid = crypto.randomUUID();
      const thumbnailUrl = file?.type.startsWith("application")
        ? staticDocImg
        : file?.type?.startsWith("video")
        ? staticVideoImg
        : URL.createObjectURL(file);

      result.push({
        randomId: uuid,
        name: file?.name,
        fileType: file?.type,
        thumbnailUrl,
      });
      return result;
    }, []);

    const defaultfilesLoading = await temporaryFiles?.reduce((result, file) => {
      result[file.randomId] = 0;
      return result;
    }, {});

    dispatch(temporaryUploadingFiles([temporaryFiles, defaultfilesLoading]));

    try {
      files?.forEach((file, index) => {
        const formData = new FormData();
        formData.append("type", "collection");
        formData.append("file", file);
        const randomId = temporaryFiles[index]?.randomId;
        formData.append("description", "description");
        formData.append("name", file?.name);
        formData.append("state", "draft");
        // selectedAlbum?._id && formData.append("album_id", selectedAlbum?._id);
        dispatch(
          uploadAssetsForContribution({
            collection_id: id,
            formData,
            key: randomId,
          }),
        );
      });
    } catch (e) {
      console.warn(e);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: uploadAssetsHandler,
  });

  const handleCheckboxClick = (e, file) => {
    // Prevent event propagation to stop triggering div click when checkbox is clicked
    e.stopPropagation();

    // If the file is invalid or rejected, return
    if (isValidElement(file) || file?.status === "rejected") return;

    // Toggle the selection of the current asset
    setCheckedAssetIds((prevCheckedAssetIds) => {
      const index = prevCheckedAssetIds.indexOf(file.id);
      return index > -1
        ? prevCheckedAssetIds.filter((id) => id !== file.id)
        : [...prevCheckedAssetIds, file.id];
    });
  };

  const handleAssetBlockClick = (e, file) => {
    // When checkbox is clicked
    // Prevent div click from happening if the checkbox was clicked
    if (e.target.type === "checkbox") return;

    // If the file is invalid or rejected, return
    if (isValidElement(file) || file?.status === "rejected") return;

    // Only select the clicked asset
    setCheckedAssetIds([file.id]);
  };

  const handleAssetDelete = async () => {
    // for deleting uploaded assets
    if (checkedAssetIds.length > 0) {
      const body = { ids: checkedAssetIds, noToast: true }; //send noToast true if you wnat to send no toast
      dispatch(deleteAssetDraftCollection(body)).then(() => {
        setDeleteOpen(false);
        setCheckedAssetIds([]);
        setToggleSnack((prev) => ({ ...prev, assetDeleted: true }));
      });
    }
  };

  const handleTitleClick = () => {
    // open album filter dropdown on title click
    setAlbumDropdown(true);
    dispatch(getAllContributeAlbums(id));
  };

  const onButtonClick = async (buttonTitle) => {
    const processAssets = async (scheduleTime) => {
      // create formdata and append all asset data
      const erroredAssetIds = [];
      setPublishLoader(true);
      for (const asset of uploadedAssets) {
        const formData = new FormData();
        formData.append("state", "complete");
        formData.append("type", "collection");

        // Optionally add schedule time
        if (scheduleTime) formData.append("schedule_time", scheduleTime);

        // Process asset metadata if available
        const assetObj = assetMetaDatas?.[asset?._id];
        if (assetObj) {
          const ai_keywords = assetObj?.keyWordArr
            ?.filter((x) => x?.custom_tag_id === "0")
            .map((y) => y?.name);
          const custom_tag_ids = assetObj?.keyWordArr
            ?.filter((x) => x?.custom_tag_id !== "0")
            .map((y) => y?._id);

          // Append metadata to formData
          if (assetObj?.credit_line)
            formData.append("credit_line", assetObj.credit_line);
          if (assetObj?.user_terms)
            formData.append("user_terms", assetObj.user_terms);
          if (assetObj?.copyright_status)
            formData.append("copyright_status", assetObj.copyright_status);
          if (assetObj?.photographer)
            formData.append("photographer", assetObj.photographer);
          if (assetObj?.ai_desc) formData.append("ai_desc", assetObj.ai_desc);
          if (ai_keywords?.length > 0)
            formData.append("ai_keywords", JSON.stringify(ai_keywords));
          if (custom_tag_ids?.length > 0)
            formData.append("custom_tag_ids", JSON.stringify(custom_tag_ids));
        }

        const data = {
          asset_id: asset?._id,
          body: formData,
        };

        // Dispatch the publish action
        await dispatch(publishAssetUsingAssetId(data)).then((res) => {
          if (res?.error) {
            erroredAssetIds.push({ [asset._id]: res?.error?.message });
          }
        });
      }

      return erroredAssetIds;
    };

    if (
      buttonTitle.id === saveButtonIds.publish &&
      uploadedAssets?.length > 0
    ) {
      const erroredAssetIds = await processAssets();
      setPublishLoader(false);
      if (erroredAssetIds.length === 0) {
        // All assets published successfully
        navigate(`${routeConfigs.contributePage}?id=${id}`);
      }
      dispatch(getAllDigitalAssetsInCollectionDraft(id));
    } else if (
      buttonTitle.id === saveButtonIds.draft &&
      uploadedAssets?.length > 0
    ) {
      if (openSchedulePopup && scheduleDateTime && scheduleDateTime !== "") {
        const erroredAssetIds = await processAssets(scheduleDateTime);
        setPublishLoader(false);
        if (erroredAssetIds.length === 0) {
          // All assets published successfully
          navigate(`${routeConfigs.contributePage}?id=${id}`);
        }
        dispatch(getAllDigitalAssetsInCollectionDraft(id));
      } else {
        setOpenSchedulePopup(true);
      }
    }
  };

  return (
    <Layout hideFooter={true}>
      <UploadScreenStyle filesLength={files?.length}>
        <div className="mainBlock">
          <div className="uploadScreen ScreenOne">
            <div className="leftUploadSection">
              <div className="uploadAssetHeading">
                <HeadingTag variant={headingVariants.h3}>
                  {t("uploadAssets")}
                </HeadingTag>
                {assetsLength > 0 && (
                  <div className="assetNum">
                    <TextTag variant={textVariants._12px.medium}>
                      {assetsLength}
                    </TextTag>
                  </div>
                )}
              </div>
              <div className="uploadAssetButton">
                <TitleCreateAlbumButton
                  handleTitleClick={handleTitleClick}
                  albumDropdownRef={albumDropdownRef}
                  collectionName={collectionName}
                  filteredAlbumsData={filteredAlbumsData}
                  albumDropdown={albumDropdown}
                  disabled={!uploadedAssets || uploadedAssets?.length <= 0}
                />
              </div>
              <section
                className={`fileUploadBox ${
                  files?.length > 1 ? "afterUploadAssets" : ""
                }`}
              >
                <div className="mainScroll">
                  {files?.map((file, index) => {
                    return (
                      <div
                        className={`displayFileBox ${
                          index === 0 ? "uploadDropFiles" : ""
                        } ${file?.randomId ? "uploadInProgress" : ""} ${
                          checkedAssetIds?.includes(file.id) ? "selected" : ""
                        }`}
                        key={file.id}
                        onClick={(e) => handleAssetBlockClick(e, file)} // Handle div click
                      >
                        {isValidElement(file) ? (
                          <FileUploadComponent
                            file={file}
                            index={index}
                            getRootProps={getRootProps}
                            getInputProps={getInputProps}
                          />
                        ) : file?.status === "rejected" ? (
                          <UploadFailedComponent
                            file={file}
                            handleDelete={(randomId) =>
                              dispatch(removeTemporaryFiles(randomId))
                            }
                          />
                        ) : (
                          <FileDisplayComponent
                            albumDataArr={albumDataArr}
                            file={file}
                            checkedAssetIds={checkedAssetIds}
                            uploadingAssetProgress={uploadingAssetProgress}
                            handleCheckboxClick={handleCheckboxClick}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </section>
            </div>
            <div className="rightUploadSection">
              <RightUploadSection
                checkedAssetIds={checkedAssetIds}
                assetsLength={assetsLength}
                uploadedAssets={uploadedAssets}
                files={files}
              />
            </div>
          </div>
        </div>
        <UploadFooter
          id={id}
          addToAlbumRef={addToAlbumRef}
          setAddToAlbumPopper={setAddToAlbumPopper}
          footerButtonDisabled={footerButtonDisabled}
          addToAlbumPopper={addToAlbumPopper}
          setDeleteOpen={setDeleteOpen}
          setCancelPopup={setCancelPopup}
          assetsLength={assetsLength}
          onButtonClick={onButtonClick}
          setPublishConfirmPopup={setPublishConfirmPopup}
        />
        {/* Delete asset confirmation popup */}
        {deleteOpen && (
          <DeleteAssetPopup
            popupTitle={`${
              checkedAssetIds?.length === 1
                ? `${capitalizeWords(t("deleteSingleAsset"))} ${
                    files?.filter((x) => checkedAssetIds?.includes(x?.id))?.[0]
                      ?.name
                  }`
                : capitalizeWords(t("deleteMultiAsset"))
            }`}
            setPopupOpen={setDeleteOpen}
            onYesClick={handleAssetDelete}
          />
        )}
        {/* Filter by album dropdown */}
        {albumDropdown && (
          <AlbumPopper
            albumDropdown={albumDropdown}
            albumDropdownRef={albumDropdownRef}
            setAlbumDropdown={setAlbumDropdown}
            setAddAlbumPopup={setAddAlbumPopup}
            setDeleteAlbum={setDeleteAlbum}
            setCheckedAssetIds={setCheckedAssetIds}
          />
        )}
        {/* Add to album popup */}
        {addAlbumPopup && (
          <AddAlbumPopup
            setAddAlbumPopup={setAddAlbumPopup}
            collection_id={id}
            setToggleSnack={setToggleSnack}
          />
        )}
        {/* cancel process confirmation popup */}
        {cancelPopup && (
          <CancelProcessPopup
            setCancelPopup={setCancelPopup}
            uploadedAssets={uploadedAssets}
          />
        )}
        {/* publish all assets confirmation popup */}
        {publishConfirmPopup && (
          <PublishConfirmPopup
            setPublishConfirmPopup={setPublishConfirmPopup}
            onButtonClick={onButtonClick}
          />
        )}
        {/* Delete album confirmation popup */}
        {deleteAlbum && (
          <DeleteAlbumPopup
            setDeleteAlbum={setDeleteAlbum}
            deleteAlbum={deleteAlbum}
            collection_id={id}
            setToggleSnack={setToggleSnack}
          />
        )}
        {/* Add to album dropdown */}
        {addToAlbumPopper && (
          <AddToAlbumPopper
            addToAlbumPopper={addToAlbumPopper}
            setAddToAlbumPopper={setAddToAlbumPopper}
            addToAlbumRef={addToAlbumRef}
            setAddAlbumPopup={setAddAlbumPopup}
            checkedAssetIds={checkedAssetIds}
            collection_id={id}
            setToggleSnack={setToggleSnack}
          />
        )}
        {/* open schedule publish asset popup */}
        {openSchedulePopup && (
          <SchedulePopup
            setOpenSchedulePopup={setOpenSchedulePopup}
            setScheduleDateTime={setScheduleDateTime}
            scheduleDateTime={scheduleDateTime}
            onButtonClick={onButtonClick}
          />
        )}
        {/* show snackbar component */}
        {/* duplicate asset snackbar comp */}
        <SnackbarComp
          snackOpen={toggleSnack?.rejectedSnacks}
          snackHeading={t("assetNotUploaded")}
          snackBody={
            <AssetsNotUploadedSnackBody
              reason={t("duplicateFile")}
              rejectedFiles={rejectedFiles}
              handleClose={() =>
                setToggleSnack((prev) => ({ ...prev, rejectedSnacks: false }))
              }
            />
          }
          snackType={"error"}
          onClose={() =>
            setToggleSnack((prev) => ({ ...prev, rejectedSnacks: false }))
          }
        />
        {/* asset added to album snackbar */}
        <SnackbarComp
          snackOpen={toggleSnack?.addToAlbumSnack}
          snackHeading={t("albumUpdated")}
          snackBody={t("albumHaveUpdated")}
          snackType={"info"}
          onClose={() =>
            setToggleSnack((prev) => ({ ...prev, addToAlbumSnack: false }))
          }
        />
        {/* asset deleted */}
        <SnackbarComp
          snackOpen={toggleSnack?.assetDeleted}
          snackHeading={t("assetDeleted")}
          snackBody={t("selectedAssetDeleted")}
          snackType={"error"}
          onClose={() =>
            setToggleSnack((prev) => ({ ...prev, assetDeleted: false }))
          }
        />
        {/* New album created */}
        <SnackbarComp
          snackOpen={toggleSnack?.newAlbumCreated}
          snackHeading={t("albumCreated")}
          snackType={"info"}
          onClose={() =>
            setToggleSnack((prev) => ({ ...prev, newAlbumCreated: false }))
          }
        />
        {/* album deleted */}
        <SnackbarComp
          snackOpen={toggleSnack?.albumDeleted}
          snackHeading={t("albumDeleted")}
          snackType={"error"}
          onClose={() =>
            setToggleSnack((prev) => ({ ...prev, albumDeleted: false }))
          }
        />
        {/* snackbar for other errors */}
        <SnackbarComp
          snackOpen={toggleSnack.errorSnack.status}
          snackHeading={toggleSnack.errorSnack.message}
          snackType={"error"}
          onClose={() =>
            setToggleSnack((prev) => ({
              ...prev,
              errorSnack: { status: false, message: "" },
            }))
          }
        />
        <BackdropLoader open={publishLoader} />
      </UploadScreenStyle>
    </Layout>
  );
};

export default UploadScreen;
