import { Backdrop, CircularProgress } from "@mui/material";
import { LoaderStyle } from "./LoaderStyle";

const Loader = ({ status = true, size }) => {
  if (status) {
    return (
      <LoaderStyle>
        <CircularProgress size={size} />
      </LoaderStyle>
    );
  }
};
export { Loader };

export const BackdropLoader = ({ open }) => {
  return (
    <LoaderStyle className="backdropMain">
      <Backdrop className="backdrop" open={open}>
        <Loader />
      </Backdrop>
    </LoaderStyle>
  );
};
