import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { ClickAwayListener } from "@mui/material";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import ContributePopupHeader from "components/ContributePopup/ContributePopupHeader/ContributePopupHeader";
import { cancelCollectionAssetUpload } from "../../features/uploadScreenAction";
import { cancelLoading } from "../../features/uploadScreenSlice";
import { routeConfigs } from "utils/routeConfig";
import { useQuery } from "utils/queryString";
import { PopupFooterStyles } from "components/ContributePopup/ContributePopupFooter/ContributePopupFooter";
import { ContributePopupStyles } from "components/ContributePopup/contributePopupStyles";
import { capitalizeWords } from "helper/helper";

const CancelProcessPopup = ({ setCancelPopup, uploadedAssets }) => {
  const id = useQuery().get("id");
  const dispatch = useDispatch();
  const loading = useSelector(cancelLoading);
  const navigate = useNavigate();
  const popupTitle = t("cancelAssetUploadText");
  const ids = uploadedAssets?.map((data) => data?._id);

  const handlePopupClose = () => {
    setCancelPopup(false);
  };

  const handleCancelProcess = () => {
    if (!ids || ids?.length <= 0) {
      navigate(`${routeConfigs.contributePage}?id=${id}`);
      return;
    }
    const body = {
      ids,
    };
    dispatch(cancelCollectionAssetUpload(body)).then((res) => {
      if (res?.error) return;
      navigate(`${routeConfigs.contributePage}?id=${id}`);
      handlePopupClose();
    });
  };

  return (
    <ContributePopupStyles className="cancelUploadProcess">
      <ClickAwayListener onClickAway={handlePopupClose}>
        <div className="contributePopupMain">
          <ContributePopupHeader
            handlePopupClose={handlePopupClose}
            popupTitle={capitalizeWords(popupTitle)}
          />
          <PopupFooterStyles className="addAlbunFooter">
            <MainButton
              onClick={handlePopupClose}
              variant={buttonVariants.grey}
              title={t("doNotCancel")}
              disabled={loading}
            />
            <MainButton
              onClick={handleCancelProcess}
              variant={buttonVariants.blue}
              title={t("yesCancel")}
              className="deleteBtn"
              disabled={loading}
            />
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

export default CancelProcessPopup;
