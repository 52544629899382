import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const getAllFieldCategoryApi = createAsyncThunk(
  "fieldsManagementCategory/getAllFieldCategoryApi",
  async (assetCollectionId) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.getFieldCategory}/${assetCollectionId}?limit=999999`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getFieldCategoryApi = createAsyncThunk(
  "fieldsManagement/getFieldCategoryApi",
  async (paramData) => {
    try {
      let apiUrl;
      if (paramData?.collectionId && (!paramData?.limit || !paramData?.page)) {
        apiUrl = `${apiRoutes.getFieldCategory}/${paramData?.collectionId}?sortOrder=asc&sortBy=order_index&limit=999999`;
      } else {
        const {
          sortBy,
          sortOrder,
          limit,
          page,
          filtersQueryString,
          collectionId,
        } = paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.getFieldCategory}/${collectionId}?filters=${encodedQueryString}&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const addFieldCategoryApi = createAsyncThunk(
  "fieldsManagementCategory/addFieldCategoryApi",
  async ({ body, assetCollectionId }) => {
    try {
      const response = await performApiRequest(() =>
        axiosConfig.post(
          `${apiRoutes.addFieldCategory}/${assetCollectionId}`,
          body,
        ),
      );
      return response.data;
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteFieldCategoryApi = createAsyncThunk(
  "fieldsManagementCategory/deleteFieldCategoryApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.deleteFieldCategory}`, data?.body),
      );
      return dispatch(getFieldCategoryApi(data.dataState));
    } catch (error) {
      return { success: false, error: error.response?.data || error.message };
    }
  },
);

export const updateFieldCategoryApi = createAsyncThunk(
  "fieldsManagementCategory/updateFieldCategoryApi",
  async ({ id, body }) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.updateFieldCategory}/${id}`, body),
      );
    } catch (error) {
      return { success: false, error: error.response?.data || error.message };
    }
  },
);

export const manageCategoryOrderingIndexAction=createAsyncThunk(
  "fieldsManagementCategory/manageCategoryOrderingIndexAction",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.updateCategoryIndex, body),
      );
    } catch (error) {
      return { success: false, error: error.response?.data || error.message };
    }
  },
);