import { styled } from "@mui/system";

export const LoaderStyle = styled("div")(({ theme }) => ({
  textAlign: "center",
  margin: "16px",
  "&.backdropMain": {
    ".backdrop": {
      zIndex: 11,
    },
  },
}));
