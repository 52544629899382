import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { styled } from "@mui/system";
import { FormControl, MenuItem, Select } from "@mui/material";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "&.contributeFormControl": {
    width: "100%",
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  "&.contributeSelect": {
    fontFamily: "poppins",
    fontSize: "12px",
    color: theme.palette.blackColor.main,
    fontWeight: "500",
    border: `1px solid ${theme.palette.gray_500.main}`,
    height: "40px",
    borderRadius: "8px",
    padding: "0px 16px",
    cursor: "pointer",
    ".MuiSelect-select": {
      padding: "0",
      height: "100%",
      display: "flex",
      alignItems: "center",

      "+ input": {
        "+ .selectIcon": {
          svg: {
            width: "16px",
            height: "16px",
            path: {
              stroke: theme.palette.blackColor.main,
            },
          },
        },
      },
      svg: {
        width: "16px",
        height: "16px",
      },
      "&[aria-expanded='true']": {
        "+ input": {
          "+ .selectIcon": {
            svg: {
              transform: "rotateX(180deg)",
            },
          },
        },
      },
    },
    ".selectIcon ": {
      pointerEvents: "none",
      position: "absolute",
      right: "16px",
      top: "10px",
    },
    fieldset: {
      display: "none",
    },

    ".contributeSelectPaperClass": {
      boxShadow: "0px 4px 15px 0px #00000012",
      borderRadius: "8px",
      border: "0",
      padding: "16px",
      width: "auto",
      "&::-webkit-scrollbar-thumb": {
        background: "#E9E9E9",
      },
      ul: {
        li: {
          backgroundColor: theme.palette.whiteColor.main,
          fontFamily: "poppins",
          fontSize: "14px",
          lineHeight: "21px",
          color: theme.palette.blackColor.main,
          fontWeight: "500",
          padding: "0",
          "&:not(:last-child)": {
            marginBottom: "16px",
          },
          "&:hover": {
            color: theme.palette.primaryColor.main,
          },
        },
      },
    },
    "&.selectDefaultValue": {
      borderColor: theme.palette.gray_400.main,
      ".MuiOutlinedInput-input": {
        color: theme.palette.gray_400.main,
      },
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme, showSelectInDropdown }) => ({
  "&.defaultSelect": {
    display: `${showSelectInDropdown ? "block" : "none"}`,
  },
}));

const SelectComp = (props) => {
  const {
    selectData,
    selectName,
    selectValue,
    onSelectChange,
    defaultValue,
    showSelectInDropdown,
  } = props;

  const [value, setValue] = useState(" ");

  useEffect(() => {
    if (defaultValue && defaultValue !== "") {
      setValue(defaultValue);
    } else {
      setValue(" ");
    }
  }, [defaultValue]);

  const handleChange = (e) => {
    setValue(e.target.value);
    const selectedObj = selectData?.filter(
      (a) => a?.[selectValue] === e.target.value,
    )?.[0];
    onSelectChange(selectedObj);
  };

  return (
    <StyledFormControl
      variant="outlined"
      className={"contributeFormControl"}
      size="small"
    >
      <StyledSelect
        className={
          value === " "
            ? "contributeSelect selectDefaultValue"
            : "contributeSelect"
        }
        onChange={handleChange}
        value={value}
        defaultValue={" "}
        IconComponent={() => <ArrowDownIcon className={"selectIcon"} />}
        MenuProps={{
          disablePortal: true,
          onClick: (e) => {
            e.preventDefault();
          },
          classes: { paper: "contributeSelectPaperClass" },
        }}
        sx={{
          "& .MuiPaper-root": {
            margin: "8px 0px",
          },
        }}
      >
        <StyledMenuItem
          showSelectInDropdown={showSelectInDropdown}
          className="defaultSelect"
          value={" "}
        >
          {t("select")}
        </StyledMenuItem>
        {selectData?.map((val, index) => {
          return (
            <StyledMenuItem
              key={index + 1}
              value={val?.[selectValue]}
              className="selectMenuItem"
            >
              {val?.[selectName]}
            </StyledMenuItem>
          );
        })}
      </StyledSelect>
    </StyledFormControl>
  );
};

export default SelectComp;
