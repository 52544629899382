import { createSlice } from "@reduxjs/toolkit";
import {
  addFieldCategoryApi,
  deleteFieldCategoryApi,
  getAllFieldCategoryApi,
  getFieldCategoryApi,
  manageCategoryOrderingIndexAction,
  updateFieldCategoryApi,
} from "./manageCategoryAction";

const initialState = {
  fieldCategories: [],
  fieldCategoriesLoading: false,
  error: null,
  allFieldCategory: [],
  categoryIndexLoading:false,
};

const fieldCategorySlice = createSlice({
  name: "fieldCategorySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle fetching field categories
    builder
      .addCase(getFieldCategoryApi.pending, (state) => {
        state.fieldCategoriesLoading = true;
        state.error = null;
      })
      .addCase(getFieldCategoryApi.fulfilled, (state, action) => {
        state.fieldCategoriesLoading = false;
        state.fieldCategories = action.payload;
      })
      .addCase(getFieldCategoryApi.rejected, (state, action) => {
        state.fieldCategoriesLoading = false;
        state.error = action.error.message;
      });
    //getAllFieldCategoryApi
    builder
      .addCase(getAllFieldCategoryApi.pending, (state) => {
        state.fieldCategoriesLoading = true;
        state.error = null;
      })
      .addCase(getAllFieldCategoryApi.fulfilled, (state, action) => {
        state.fieldCategoriesLoading = false;
        state.allFieldCategory = action.payload.data;
      })
      .addCase(getAllFieldCategoryApi.rejected, (state, action) => {
        state.fieldCategoriesLoading = false;
        state.error = action.error.message;
      });
    // Handle adding a new field category
    builder
      .addCase(addFieldCategoryApi.pending, (state) => {
        state.fieldCategoriesLoading = true;
        state.error = null;
      })
      .addCase(addFieldCategoryApi.fulfilled, (state, action) => {
        state.fieldCategoriesLoading = false;
      })
      .addCase(addFieldCategoryApi.rejected, (state, action) => {
        state.fieldCategoriesLoading = false;
        state.error = action.error.message;
      });
    builder
      .addCase(deleteFieldCategoryApi.pending, (state) => {
        state.fieldCategoriesLoading = true;
        state.error = null;
      })
      .addCase(deleteFieldCategoryApi.fulfilled, (state, action) => {
        state.fieldCategoriesLoading = false;
      })
      .addCase(deleteFieldCategoryApi.rejected, (state, action) => {
        state.fieldCategoriesLoading = false;
        state.error = action.error.message;
      });
    // Handle updating a field category
    builder
      .addCase(updateFieldCategoryApi.pending, (state) => {
        state.fieldCategoriesLoading = true;
        state.error = null;
      })
      .addCase(updateFieldCategoryApi.fulfilled, (state, action) => {
        state.fieldCategoriesLoading = false;
      })
      .addCase(updateFieldCategoryApi.rejected, (state, action) => {
        state.fieldCategoriesLoading = false;
        state.error = action.error.message;
      });
      builder
      .addCase(manageCategoryOrderingIndexAction.pending, (state) => {
        state.categoryIndexLoading = true;
        state.error = null;
      })
      .addCase(manageCategoryOrderingIndexAction.fulfilled, (state, action) => {
        state.categoryIndexLoading = false;
      })
      .addCase(manageCategoryOrderingIndexAction.rejected, (state, action) => {
        state.categoryIndexLoading = false;
        state.error = action.error.message;
      });
  },
});

export const selectFieldCategories = (state) => state.fieldCategorySlice;
export const fieldCategories = (state) =>
  state.fieldCategorySlice.fieldCategories;
export const allFieldCategory = (state) =>
  state.fieldCategorySlice.allFieldCategory;

export default fieldCategorySlice.reducer;
