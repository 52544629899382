import { Tab, Tabs } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledFeTabs = styled("div")(({ theme }) => ({
  "&.tabsButtonBlock": {
    maxWidth: "100%",
    // paddingBottom: '8px',
    borderBottom: `1px solid ${theme.palette.gray_250.main}`,
    marginBottom: "32px",
    ".MuiTabs-root": {
      minHeight: "unset",
    },

    button: {
      paddingBottom: "8px!important",
      padding: "0 24px",
      // fontWeight: "500!important",
      color: theme.palette.gray_600.main,
      minHeight: "unset",
      fontFamily: "poppins",
      lineHeight: "normal",
      height: "24px",
      fontWeight: "500!important",
      fontSize: "14px!important",
      position: "relative",
      span: {
        display: "none",
      },
      "&:first-child": {
        paddingLeft: "0",
        "&.Mui-selected": {
          color: theme.palette.primaryColor.main,
          position: "relative",
          "&:after": {
            left: "0",
            maxWidth: "calc(100% - 23px)",
          },
        },
      },

      "&:not(:first-child)": {
        // borderRight: `1px solid ${theme.palette.gray_300.main}`,
        "&:before": {
          content: "''",
          position: "absolute",
          width: "1px",
          height: "21px",
          backgroundColor: theme.palette.gray_250.main,
          left: "0",
        },
      },
      "&.Mui-selected": {
        color: theme.palette.primaryColor.main,
        position: "relative",
        "&:after": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "2px",
          background: theme.palette.primaryColor.main,
          bottom: "0",
          maxWidth: "calc(100% - 48px)",
        },
      },
    },
    "&.showcaseTabs": {
      borderBottom: `1px solid ${theme.palette.gray_250.main}`,
      button: {
        color: theme.palette.gray_800.main,
        "&.Mui-selected": {
          color: theme.palette.primaryColor.main,
        },
      },
    },
  },
  "&.disabled": {
    pointerEvents: "none",
    button: {
      color: theme.palette.gray_300.main,
      "&.Mui-selected": {
        color: theme.palette.gray_300.main,
        "&:after": {
          background: "transparent",
        },
      },
      "&:first-child": {
        "&.Mui-selected": {
          color: theme.palette.gray_300.main,
          "&:after": {
            background: "transparent",
          },
        },
      },
    },
  },
}));

const FeTabs = ({
  tabValue,
  handleTabChange,
  disabled,
  tabsDataArray,
  className,
  sx,
}) => {
  return (
    <StyledFeTabs
      className={`tabsButtonBlock ${className ? className : ""} ${
        disabled ? "disabled" : ""
      }`}
    >
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        className={disabled ? "disabled" : ""}
        scrollButtons="auto"
        variant="scrollable"
        sx={sx}
      >
        {tabsDataArray?.length > 0 &&
          tabsDataArray.map((data, index) => (
            <Tab key={index} label={data?.label} value={data?.value} />
          ))}
      </Tabs>
    </StyledFeTabs>
  );
};

export default FeTabs;
