import React from "react";

const FileTickIcon = (props) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.75 4.6987C1.75 4.0453 1.75 3.7186 1.87716 3.46904C1.98901 3.24952 2.16749 3.07104 2.38701 2.95919C2.63657 2.83203 2.96327 2.83203 3.61667 2.83203H5.64347C5.92882 2.83203 6.07151 2.83203 6.20579 2.86427C6.32479 2.89284 6.4386 2.93998 6.54302 3.00395C6.66073 3.0761 6.76165 3.17699 6.96342 3.37877L7.03657 3.45196C7.23835 3.65374 7.33927 3.75463 7.45698 3.82678C7.5614 3.89075 7.67521 3.93789 7.79421 3.96646C7.92849 3.9987 8.07117 3.9987 8.35654 3.9987H10.3833C11.0367 3.9987 11.3635 3.9987 11.613 4.12586C11.8325 4.23771 12.011 4.41618 12.1228 4.63571C12.25 4.88527 12.25 5.21197 12.25 5.86536V10.2987C12.25 10.9521 12.25 11.2788 12.1228 11.5284C12.011 11.7479 11.8325 11.9264 11.613 12.0382C11.3635 12.1654 11.0367 12.1654 10.3833 12.1654H3.61667C2.96327 12.1654 2.63657 12.1654 2.38701 12.0382C2.16749 11.9264 1.98901 11.7479 1.87716 11.5284C1.75 11.2788 1.75 10.9521 1.75 10.2987V4.6987Z"
        stroke="#BDC0C4"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.25 8.21426L6.32692 9.2513L8.75 6.91797"
        stroke="#BDC0C4"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FileTickIcon;
