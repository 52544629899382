import { apiRoutes } from "utils/apiRoutes";
import ToastHelper from "./toastHelper";

const performApiRequest = async (apiFunc, successMessage, showToast = true) => {
  try {
    //this function is used to peform api requests in dispatch action.

    //run the api
    const response = await apiFunc();

    //get the response data
    const data = response.data;

    if (data?.message && response?.config?.method !== "get") {
      //show success message toast only if it is not  a get request
      showToast && ToastHelper("success", data?.message);
    }

    // return the data from api to use further
    return data;
  } catch (error) {
    if (error.config.method === "get" || !showToast) {
      //only show console if error in get api
      console.error(error.response.data.message);
    } else {
      //show toast message if error in any ohter api method except get

      //list of api routes where toast shouldn't come
      const noToastRoutes = [
        apiRoutes.adminLogin,
        apiRoutes.adminForgotPassword,
        apiRoutes.adminResetPassword,
        apiRoutes.userRegister,
      ];

      //getting the path of the api
      const apiPath = error?.config?.url;

      //if no toast route found return
      if (!noToastRoutes?.includes(apiPath)) {
        ToastHelper(
          "error",
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.response?.data?.errors?.[0]?.msg,
        );
      }
    }
    //throw response error back if need to handle further
    throw error.response;
  }
};

export default performApiRequest;
