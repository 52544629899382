import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";

export const fileTypeSchema = Yup.object().shape({
  description: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterDescription)
    .max(250, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    ),
});
