import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Grid, TextField, Box } from "@mui/material";
import {
  selectAllAssetType,
  assetTypeFieldValueArray,
  allAssetTypes,
} from "./features/typeSlice";
import {
  addAssetType,
  adminUpdateAssetTypeIndexApi,
  countActiveAssetTypeApi,
  getActiveAssetType,
  getAllAssetType,
  getAssetType,
  getAssetTypeFields,
  updateAssetType,
  updateAssetTypeStatus,
  updateTooltipDescription,
} from "./features/typeAction";
import CustomButton from "components/Button/CustomButton";
import ToastHelper from "helper/toastHelper";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import ToggleSwitch from "pages/Components/ToggleSwitch/ToggleSwitch";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import StatusAndReassignPopup from "./components/StatusAndReassignPopup";
import { FieldManagementStyle } from "pages/Admin-Pages/fields-management/fieldManagementStyle";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import { FaEdit } from "react-icons/fa";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import AddEditFileTypePopup from "./components/AddEditFileTypePopup";

export default function AssetType() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const typeData = useSelector(selectAllAssetType);
  const fieldValueData = useSelector(assetTypeFieldValueArray); //filter
  const allMainTableData = useSelector(allAssetTypes);
  const inputRef = useRef(null);
  const gridRef = useRef();
  console.log("typeData", typeData);

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("linked_categories"),
    {
      field: "rowDrag",
      headerName: "",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxDragColumn",
      maxWidth: "110",
      suppressNavigable: true,
      lockPosition: "left",
      foreverShow: true,
    },
    {
      field: "name",
      headerName: t("fileType"),
      headerClass: "leftAlign",
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            // onClick={(e) => {
            //   commonFilterOnClick(e, filterClickProps);
            // }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            {/* <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button> */}
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: true,
        editFunction: editType,
      },
      cellClass: "editableCell",
      editable: true,
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "createdAt",
          dispatchName: "createdAt",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            // onClick={(e) => {
            //   commonFilterOnClick(e, filterClickProps);
            // }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            {/* <button>
              <ShowHeaderIcon
                columnName={"createdAt"}
                sortBy={"createdAt"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button> */}
          </th>
        );
      },
      cellRenderer: ({ data }) =>
        ToolTipWrapperComponent(dayjs(data?.createdAt).format("DD-MM-YYYY")),
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            // onClick={(e) => {
            //   commonFilterOnClick(e, filterClickProps);
            // }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            {/* <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button> */}
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                setOpenedFor("REASSIGN");
                setCurrentRowData(props?.data);
                handleSwitchChange(
                  props?.data?._id, //id
                  props?.value, //value
                  props?.data?.linked_categories, // count
                  false, //no reassing happening
                  true, //open reassign popup
                  props,
                );
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableDeleteIcon
            onClick={() => {
              setOpenedFor("DELETE");
              dispatch(countActiveAssetTypeApi()).then((response) => {
                if (
                  response?.payload?.total_asset_type <= 1 &&
                  props?.data?.enabled
                ) {
                  //if only one record is enabled and the record we are deleting is also enabled
                  //means we are deleting last enabled record then show toast
                  ToastHelper(
                    "error",
                    validationMessages.oneTypeShouldBeEnabled,
                  );
                } else {
                  if (props?.data?.linked_categories > 0) {
                    setOpen(true);
                  } else {
                    setOpenDelete(true);
                  }
                }
              });
              setCurrentRowData(props.data);
              setRowsToBeDeleted([props?.data?._id]);
              resetErrorStates();
            }}
          />
        </Grid>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState();

  const [typeName, setTypeName] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState();
  const [alphaNumErrorAdd, setAlphaNumErrorAdd] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter

  const [open, setOpen] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [selectValue, setSelectValue] = useState(" ");
  const [currentRowData, setCurrentRowData] = useState(null);
  const [openedFor, setOpenedFor] = useState("");
  const [openChangeTitle, setOpenChangeTitle] = useState(false);

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "asc", //probable values: asc, desc
    sortBy: "displayIndex", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  const handleEditClick = (tooltip) => {
    setOpenChangeTitle(true);
  };

  useEffect(() => {
    dispatch(getAllAssetType());
    dispatch(getAssetType());
  }, [dispatch, open, openDelete]);

  // const handleUpdateTooltip = async (description) => {
  //   const payload = {
  //     moduleName: "filetype",
  //     description,
  //     id: tooltipId,
  //   };

  //   const response = await dispatch(updateTooltipDescription(payload));

  //   console.log(response);
  //   if (updateTooltipDescription.fulfilled.match(response)) {
  //     setOpenChangeTitle(false);

  //     await dispatch(getAssetType());
  //   } else {
  //     console.error("Error updating tooltip description:", response.error);
  //   }
  // };

  useEffect(() => {
    setIsLoading(true);
    resetErrorStates();
    dispatch(getAssetType(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    setPreviousColumnFilterData(typeData?.applied_filters);
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = typeData?.[commonKey]),
    );

    setRowData(
      typeData?.asset_type?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        name: tableDataObj?.name,
        createdAt: tableDataObj?.createdAt,
        enabled: tableDataObj?.enabled,
        action: "",
        other_table_data: commonDataObj,
        linked_categories: tableDataObj?.linked_categories,
      })),
    );
  }, [typeData]);
  const handleSwitchChange = useCallback(
    async (
      entryId, //id
      switchValue, //value true or false
      count, //number of child entries, enter 0 if you do not want to open reassign popup
      newFileTypeId, //new entry for reassign, only enter when required
      shouldPopupOpen = false,
      props, //props from grid, will use datastate if not available
    ) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }
        resetErrorStates();
        setSelectedRows([]);
        setRowsToBeDeleted([]);
        if (switchValue && count > 0 && shouldPopupOpen) {
          //when deactivating if there are child elements open reassign popup
          //also only open the popup if there is more than one active
          dispatch(countActiveAssetTypeApi())
            .then((response) => {
              if (response?.payload?.total_asset_type > 1) {
                setOpen(true);
              } else {
                ToastHelper("error", validationMessages.oneTypeShouldBeEnabled);
              }
            })
            .catch((e) => console.error(e));
          return;
        }

        //updating the ui
        //by toggling switchvalue and setting count
        rowNode.setDataValue("enabled", !switchValue);
        rowNode.setDataValue("linked_categories", count);

        let modifiedDataState = dataState;

        if (props?.data?.other_table_data) {
          const other_data = props?.data?.other_table_data;
          modifiedDataState = modifyDataState(other_data);
        }

        const dataBody = {
          body: {
            assetTypeId: entryId,
            enabled: !switchValue,
          },
          dataState: modifiedDataState,
        };

        if (newFileTypeId) {
          //this means we are also reassigning new business unit while updating the status
          dataBody.body.new_file_type = newFileTypeId;
        }

        //running the update status api
        dispatch(updateAssetTypeStatus(dataBody)).then((resp) => {
          if (resp?.error) {
            //reverting if error occurs
            dispatch(getAssetType(modifiedDataState));
            return "error";
          } else if (newFileTypeId) {
            dispatch(getAssetType(modifiedDataState));
          }
        });
      });
    },
    //eslint-disable-next-line
    [],
  );

  const resetErrorStates = () => {
    setAlphaNumErrorAdd("");
  };

  const settingNewAssetType = (e) => {
    const value = e.target.value;
    const alphaNumericRegex = validationRegex.alphanumeric;
    if (inputRef.current !== null) {
      inputRef.current.value = value;
      setTypeName(e.target.value);
    }
    if (value.length > 50) {
      setAlphaNumErrorAdd(validationMessages.tooLong);
    } else if (!alphaNumericRegex.test(value)) {
      setAlphaNumErrorAdd(validationMessages?.alphanumericOnly);
    } else if (!validationRegex?.checkForDoubleSpaces.test(value)) {
      setAlphaNumErrorAdd(validationMessages?.noConsecutiveDoubleSpaces);
    } else {
      resetErrorStates();
    }
  };

  function editType(data) {
    const body = {
      assetype_id: data?.dataObj?._id || data?.assetype_id,
      name: data?.updatedValue || data?.name?.trim(),
    };
    if (body?.name === "") {
      ToastHelper("error", "Type name is required!");
      return false;
    }

    let modifiedDataState = dataState;

    if (data?.dataObj?.other_table_data) {
      const other_data = data?.dataObj?.other_table_data;
      modifiedDataState = modifyDataState(other_data);
    }

    const dataBody = {
      body,
      dataState: modifiedDataState,
    };

    dispatch(updateAssetType(dataBody)).then((res) => {
      dispatch(getAssetType(modifiedDataState));
      if (res?.error) {
        return;
      }
      resetErrorStates();
    });
  }

  function addNewAssetType() {
    const trimmedAsset = !!typeName && typeName.trim();
    if (!!trimmedAsset && typeName.trim() !== "") {
      if (trimmedAsset.length > 50) {
        setAlphaNumErrorAdd(validationMessages.tooLong);
      } else if (!validationRegex.alphanumeric.test(trimmedAsset)) {
        setAlphaNumErrorAdd(validationMessages?.alphanumericOnly);
      } else if (!validationRegex?.checkForDoubleSpaces.test(trimmedAsset)) {
        setAlphaNumErrorAdd(validationMessages?.noConsecutiveDoubleSpaces);
      } else {
        const body = { name: trimmedAsset };
        dispatch(addAssetType(body)).then((res) => {
          if (!res?.error) {
            setDataState({
              ...dataState,
              search: "",
              limit: 20,
              page: 1,
              sortOrder: "asc",
              sortBy: "displayIndex",
              column_name: "",
              column_values: "",
              filtersQueryString: [],
            });
            inputRef.current.value = "";
            setTypeName("");
            resetErrorStates();
          }
        });
      }
    } else {
      setAlphaNumErrorAdd(validationMessages.pleaseEnterAssetType);
    }
  }

  const onRowDragEnd = useCallback(
    (e) => {
      const dataBody = {
        body: {
          oldIndex:
            parseInt(e.node.id) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
          newIndex:
            parseInt(e.node.rowIndex) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
        },
        dataState,
      };
      dispatch(adminUpdateAssetTypeIndexApi(dataBody)).then(() => {
        dispatch(getActiveAssetType());
      });
    },
    [dataState, dispatch],
  );

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getAssetTypeFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const icons = useMemo(() => {
    return {
      rowDrag: `<img src ="./logos/menu.svg")} style="height: 46px; width: 26px;padding-right: 2px"/>`,
    };
  }, []);

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
    onRowDragEnd,
    rowDragManaged: true,
    icons,
  };

  const extraBulkDeleteProps = {
    //props required for extra bulk delete functionality
    //if allowBulkDeleteConditions is false then no need to send other props at all
    allowBulkDeleteConditions: true,
    //if true bulk delete will not check for extra conditions
    childCountExtension: "linked_categories", //no child entries
    setOpenedFor,
    getActiveEntries: countActiveAssetTypeApi,
    activeTableEntriesExtension: "total_asset_type",
    lastRecordValidationMessage: validationMessages.oneTypeShouldBeEnabled,
    getAllEntries: getAllAssetType,
    allEntriesExtension: "asset_type",
    setOpen,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("fileType")} />
      <Box className="tooltipTextEdit">
        <p>
          {typeData?.tooltipDescription?.description ||
            "No tooltip description available."}
        </p>
        <TableEditIcon onClick={handleEditClick} />
      </Box>
      <Box className="tabsLine" />
      <Box container spacing={4} className="userReq-table">
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType ">
                <Box className="leftSideInputs">
                  <Grid container spacing={4} className={`addNew`}>
                    <Box className={"addNewDiv"}>
                      <TextField
                        placeholder={t("enterFileType")}
                        size="small"
                        className={"addNewText"}
                        variant="outlined"
                        onChange={settingNewAssetType}
                        onKeyDown={(e) => {
                          if (e.code === "Enter" || e.code === "NumpadEnter")
                            addNewAssetType();
                        }}
                        inputRef={inputRef}
                      />
                    </Box>
                    <Box className={"addNewDiv1"}>
                      <CustomButton
                        onClick={() => addNewAssetType()}
                        variant="outlined"
                      >
                        {t("addFileType")}
                      </CustomButton>
                    </Box>
                    <span className="errorClass">{alphaNumErrorAdd}</span>
                  </Grid>
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={setSelectedRows}
                  />
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={typeData?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
              {...extraBulkDeleteProps}
            />
          </Box>
        </Box>
      </Box>
      <StatusAndReassignPopup
        open={open}
        showReassign={showReassign}
        setShowReassign={setShowReassign}
        resetErrorStates={resetErrorStates}
        allMainTableData={allMainTableData}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        currentRowData={currentRowData}
        setOpen={setOpen}
        dataState={dataState}
        setDataState={setDataState}
        rowsToBeDeleted={rowsToBeDeleted}
        regionData1={typeData}
        openedFor={openedFor}
        setOpenDelete={setOpenDelete}
        openDelete={openDelete}
        setSelectedRows={setSelectedRows}
        handleSwitchChange={handleSwitchChange}
        gridRef={gridRef}
      />
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
      {openChangeTitle && (
        <AddEditFileTypePopup
          openChangeTitle={openChangeTitle}
          setOpenChangeTitle={setOpenChangeTitle}
          dataState={dataState}
          desc={typeData?.tooltipDescription?.description}
          id={typeData?.tooltipDescription?._id}
          moduleName="filetype"
        />
      )}
    </FieldManagementStyle>
  );
}
