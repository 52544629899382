import { createSlice } from "@reduxjs/toolkit";
import {
  getLookupTableTabs,
  addLookupList,
  deleteLookupList,
  getLookupTableStatusCount,
  getLookupDataById,
  updateLookupEntryApi,
  updateLookupStatus,
  getLookupField,
  getActiveLookupData,
} from "./lookupAction";

const initialState = {
  lookupTableData: [],
  statusCount: null,
  status: "idle",
  lookupTableDataWithId: [],
  lookupFieldData: [],
  activeLookupData: [],
};

const lookupSlice = createSlice({
  name: "lookupTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLookupTableTabs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLookupTableTabs.fulfilled, (state, action) => {
        state.status = "idle";
        state.lookupTableData = action.payload;
      });
    builder
      .addCase(addLookupList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addLookupList.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(deleteLookupList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteLookupList.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(getLookupTableStatusCount.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLookupTableStatusCount.fulfilled, (state, action) => {
        state.status = "idle";
        state.statusCount = action.payload;
      });
    //getLookupDataById
    builder
      .addCase(getLookupDataById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLookupDataById.fulfilled, (state, action) => {
        state.status = "idle";
        state.lookupTableDataWithId = action.payload;
      });

    //updateLookupEntryApi
    builder
      .addCase(updateLookupEntryApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateLookupEntryApi.fulfilled, (state) => {
        state.status = "idle";
      });
    //updateLookupStatus
    builder
      .addCase(updateLookupStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateLookupStatus.fulfilled, (state) => {
        state.status = "idle";
      });
    //getLookupField
    builder
      .addCase(getLookupField.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getLookupField.fulfilled, (state, action) => {
        state.status = "idle";
        state.lookupFieldData = action.payload;
      });
    //getActiveLookupData
    builder
      .addCase(getActiveLookupData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getActiveLookupData.fulfilled, (state, action) => {
        state.status = "idle";
        state.activeLookupData = action.payload;
      });
  },
});

export const allLookupTableData = (state) => state.lookupTable.lookupTableData;
export const activeLookupData = (state) => state.lookupTable.activeLookupData;
export const lookupTableStatusCount = (state) => state.lookupTable.statusCount;
export const lookupTableDataWithId = (state) =>
  state.lookupTable.lookupTableDataWithId;

export const lookupFieldData = (state) => state.lookupTable.lookupFieldData;

export default lookupSlice.reducer;
