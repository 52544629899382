import React from "react";

const FilePlusIcon = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 4.8013C2 4.05456 2 3.6812 2.14533 3.39598C2.27315 3.1451 2.47713 2.94112 2.72801 2.8133C3.01323 2.66797 3.3866 2.66797 4.13333 2.66797H6.44968C6.7758 2.66797 6.93887 2.66797 7.09233 2.70481C7.22833 2.73747 7.3584 2.79134 7.47773 2.86445C7.61227 2.9469 7.7276 3.0622 7.9582 3.29281L8.0418 3.37646C8.2724 3.60707 8.38773 3.72237 8.52227 3.80482C8.6416 3.87793 8.77167 3.9318 8.90767 3.96446C9.06113 4.0013 9.2242 4.0013 9.55033 4.0013H11.8667C12.6134 4.0013 12.9868 4.0013 13.272 4.14663C13.5229 4.27446 13.7269 4.47843 13.8547 4.72932C14 5.01453 14 5.3879 14 6.13464V11.2013C14 11.948 14 12.3214 13.8547 12.6066C13.7269 12.8575 13.5229 13.0615 13.272 13.1893C12.9868 13.3346 12.6134 13.3346 11.8667 13.3346H4.13333C3.38659 13.3346 3.01323 13.3346 2.72801 13.1893C2.47713 13.0615 2.27315 12.8575 2.14533 12.6066C2 12.3214 2 11.948 2 11.2013V4.8013Z"
        stroke="#0056B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 8.66797H10M8 10.668V6.66797"
        stroke="#0056B8"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FilePlusIcon;
