import React from "react";

const LoopIcon = (props) => {
  return (
    <svg
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 1.16797L13 3.16797M13 3.16797L11 5.16797M13 3.16797H3.66667C2.19391 3.16797 1 4.36188 1 5.83464M3 11.8346L1 9.83463M1 9.83463L3 7.83464M1 9.83463H10.3333C11.8061 9.83463 13 8.6407 13 7.16797"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default LoopIcon;
