import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HeroSearch from "./Components/HeroSearch/HeroSearch";
import HeroTrendingSearches from "./Components/HeroTrendingSearches/HeroTrendingSearches";
import {
  bannerLoading,
  getBannerTitleFromSelector,
  getFeBanner,
} from "pages/Admin-Pages/banners/features/bannerSlice";
import {
  feGetBannerApi,
  getBannerTitleAction,
} from "pages/Admin-Pages/banners/features/bannerAction";
import bannerimage from "./bannerImg.png";
import HeroSectionStyles from "./HeroSectionStyles";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { fixHeroSearch, scrollingUp } from "Layout/features/layoutSlice";
gsap.registerPlugin(ScrollTrigger);

export const searchFlagType = {
  all: "ALL",
  type: "TYPE",
  cat: "CAT",
};

const HeroSection = () => {
  const [openSearchType, setOpenSearchType] = useState(false);
  const dispatch = useDispatch();
  const getFeBannerData = useSelector(getFeBanner);
  const feBannerLoading = useSelector(bannerLoading);

  const imageBaseSrc = `${getFeBannerData?.image_base_url}/${getFeBannerData?.image_container_name}`;
  const scrollRef = useRef();
  const headingRef = useRef();
  const [bannerSrc, setBannerSrc] = useState("");
  const heroSectionRef = useRef(null);
  const { data: bannerData } = useSelector(getBannerTitleFromSelector) || {};

  // const [headingHeight, setHeadingHeight] = useState(0);
  // const [dataValue, setDataValue] = useState(null);

  useEffect(() => {
    dispatch(getBannerTitleAction());
    let lastScrollTop = window.scrollY || window.pageYOffset;

    const handleScroll = () => {
      const top = headingRef.current?.getBoundingClientRect().top;
      if (top > -150) {
        dispatch(fixHeroSearch(false));
      } else {
        dispatch(fixHeroSearch(true));
      }

      const st = window.scrollY || window.pageYOffset;

      if (top <= -150) {
        if (st > lastScrollTop) {
          dispatch(scrollingUp(false));
        } else if (st < lastScrollTop) {
          dispatch(scrollingUp(true));
        }
      } else {
        dispatch(scrollingUp(false));
      }

      lastScrollTop = st;
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(feGetBannerApi(controller.signal));
    return () => {
      controller.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    if (getFeBannerData?.banners_images?.length > 0) {
      const randomBannerIndex = Math.floor(
        Math.random() * getFeBannerData?.banners_images?.length,
      );
      const imageSrc = `${imageBaseSrc}/${getFeBannerData?.banners_images[randomBannerIndex]}`;
      if (imageSrc) {
        setBannerSrc(imageSrc);
      } else {
        setBannerSrc(bannerimage);
      }
    } else {
      setBannerSrc(bannerimage);
    }
  }, [getFeBannerData, imageBaseSrc]);

  // const divObserver=(entries)=>{
  //   const [entry]=entries;
  //  !entry.isIntersecting && setOpenSearchType(false);
  // }
  // const divObserverOptions={
  //   root:null,
  //   rootMargin:"0px",
  //   threshold:0.1
  // }
  // useEffect(()=>{
  //   const observer=new IntersectionObserver(divObserver,divObserverOptions);
  //    if(heroSectionRef.current) observer.observe(heroSectionRef.current);
  // return()=>{
  //   if(heroSectionRef.current) observer.unobserve(heroSectionRef.current);
  // }
  // },[heroSectionRef,divObserverOptions])

  // const updateDataValue = () => {
  //   const data = heroSectionRef?.current?.getBoundingClientRect()?.height;
  //   const headingHeightz = headingRef?.current?.getBoundingClientRect()?.height;
  //   const top = data / 2;
  //   setDataValue(top);
  //   setHeadingHeight(headingHeightz);
  // };

  // useEffect(() => {
  //   // Run on initial render
  //   updateDataValue();

  //   // Set up event listener for window resize
  //   window.addEventListener("resize", updateDataValue);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("resize", updateDataValue);
  //   };
  // }, []);

  // useEffect(() => {
  //   imageCardSwap(".float-div");

  //   return () => {
  //     gsap.killTweensOf(".float-div");
  //   };
  //   //eslint-disable-next-line
  // }, [dataValue]);

  // function imageCardSwap(trigger) {
  //   const timeline = gsap.timeline({
  //     scrollTrigger: {
  //       trigger: ".heroSectionMain",
  //       start: "top top",
  //       end: "95%",
  //       scrub: "none",
  //       ease: "none",
  //       onUpdate: (self) => {
  //         const progress = self.progress;
  //         if (progress >= 0 && progress < 1) {
  //           const element = document.querySelector(".float-div");
  //           if (element) {
  //             element.classList.remove("full-width-search");
  //           }
  //         } else {
  //           const element = document.querySelector(".float-div");
  //           if (element) {
  //             element.classList.add("full-width-search");
  //           }
  //         }
  //       },
  //     },
  //   });
  //   timeline.fromTo(
  //     trigger,
  //     { top: dataValue + 80 + 30 + (headingHeight > 130 ? 40 : 0) },
  //     {
  //       top: 106,
  //     },
  //   );
  // }

  return (
    <HeroSectionStyles bannerImage={!feBannerLoading && bannerSrc}>
      {/* <div className="float-div" style={{}}>
        <HeroSearch />
      </div> */}
      <div
        className="heroSectionMain"
        id="homeHeroSection"
        ref={heroSectionRef}
      >
        <div className="aboveHeroImage">
          <div className="heroHeadingContent">
            <div className="heroHeading" ref={headingRef}>
              <HeadingTag variant={headingVariants.h1}>
                {bannerData?.title}
              </HeadingTag>
            </div>
            <div children="heroContent" className="fileOptions" ref={scrollRef}>
              <TextTag variant={textVariants._16px.regular}>
                {bannerData?.description}
              </TextTag>
            </div>
          </div>
          <HeroSearch
            openSearchType={openSearchType}
            setOpenSearchType={setOpenSearchType}
          />
          <HeroTrendingSearches />
        </div>
      </div>
    </HeroSectionStyles>
  );
};

export default HeroSection;
