import React from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import FilePlusIcon from "components/Icons/FileIcon/FilePlusIcon";
import TrashIconOutline from "components/Icons/Trash/TrashIconOutline";
import TextTag, { textVariants } from "components/Typography/TextTag";
import UploadSaveButton from "./UploadSaveButton";
import { getAllContributeAlbums } from "pages/User-Pages/ContributePage/features/contributionAction";

const UploadFooter = ({
  id,
  addToAlbumRef,
  setAddToAlbumPopper,
  footerButtonDisabled,
  addToAlbumPopper,
  setDeleteOpen,
  setCancelPopup,
  assetsLength,
  onButtonClick,
  setPublishConfirmPopup,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="uploadScreenFooter">
      <div
        className={`uploadFooterLeft ${footerButtonDisabled ? "disabled" : ""}`}
      >
        <button
          className={`addToAlbum ${addToAlbumPopper ? "addToAlbumPopper" : ""}`}
          onClick={() => {
            dispatch(getAllContributeAlbums(id));
            setAddToAlbumPopper(true);
          }}
          ref={addToAlbumRef}
          disabled={footerButtonDisabled}
        >
          <FilePlusIcon />
          <TextTag variant={textVariants._14px.semiBold}>
            {t("addToAlbum")}
          </TextTag>
        </button>
        <button
          className="deleteButton"
          onClick={() => setDeleteOpen(true)}
          disabled={footerButtonDisabled}
        >
          <TrashIconOutline />
          <TextTag variant={textVariants._14px.semiBold}>{t("delete")}</TextTag>
        </button>
      </div>
      <div className="uploadFooterRight">
        <MainButton
          title={t("cancel")}
          variant={buttonVariants.grey}
          onClick={() => setCancelPopup(true)}
        />
        <UploadSaveButton
          disableSave={assetsLength <= 0}
          onButtonClick={onButtonClick}
          setPublishConfirmPopup={setPublishConfirmPopup}
        />
      </div>
    </div>
  );
};

export default UploadFooter;
