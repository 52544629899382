import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import SelectComp from "components/Select/SelectComp";
import ContributePopTextAreaComp from "components/Textfield/ContributePopTextAreaComp";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import AIDescriptionPopup from "./AIDescriptionPopup";
import ButtonOutlineIcon from "components/Button/ButtonOutlineIcon";
import AIGenerateIcon from "components/Icons/AIGenerateIcon/AIGenerateIcon";
import {
  getActiveLookupData,
  getLookupTableTabs,
} from "pages/Admin-Pages/aui-form-fields/LookupList/components/features/lookupAction";
import { allLookupTableData } from "pages/Admin-Pages/aui-form-fields/LookupList/components/features/lookupSlice";
import {
  assetsData,
  changeAssetsFinalData,
} from "pages/User-Pages/UploadScreen/features/uploadScreenSlice";
import ContributePopSectionHeader from "components/ContributePopup/ContributePopupBody/components/ContributePopSectionHeader/ContributePopSectionHeader";
import ContributePopTextfieldComp from "components/Textfield/ContributePopTextfieldComp";
import DetailStyle from "pages/User-Pages/ContributePage/Components/DetailForm/DetailsStyle";

const MetaDataSection = ({ checkedAssetIds, imageTypes, fileExtension }) => {
  const dispatch = useDispatch();
  const allFields = useSelector(allLookupTableData).data;
  const allAssetsData = useSelector(assetsData);

  const [openPopup, setOpenPopup] = useState(false);
  const [lookupData, setLookupData] = useState([
    { type: "user_terms", data: [] },
    { type: "copyright_status", data: [] },
    { type: "photographer", data: [] },
  ]);

  useEffect(() => {
    dispatch(getLookupTableTabs());
  }, [dispatch]);

  useEffect(() => {
    //fetch lookuplist flow will change
    //this is just to get things up and running
    if (!allFields || allFields?.length <= 0) return;

    allFields.slice(0, 3).forEach((field, index) => {
      if (field?._id) {
        dispatch(getActiveLookupData(field._id)).then((res) => {
          setLookupData((prevData) =>
            prevData.map((item, idx) =>
              idx === index
                ? { ...item, data: res?.payload?.data, name: field?.name }
                : item,
            ),
          );
        });
      }
    });
  }, [allFields, dispatch]);

  const onSelectChange = (e, fieldData) => {
    dispatch(
      changeAssetsFinalData({
        ...allAssetsData,
        [checkedAssetIds[0]]: {
          ...allAssetsData[checkedAssetIds[0]],
          [fieldData.type]: e?._id,
        },
      }),
    );
  };

  return (
    <DetailStyle className="uploadScreenMetadata">
      <div className="fieldsEditMain">
        <HeadingTag variant={headingVariants.h3}>
          {t("editableData")}
        </HeadingTag>
        {lookupData?.map((fieldData, index) => {
          return (
            <div className="editFieldsData" key={index}>
              <ContributePopSectionHeader
                sectionHeading={t(fieldData?.name)}
                isRequired={false}
                id={fieldData?.name}
              />
              <SelectComp
                selectData={fieldData?.data}
                selectName={"name"}
                selectValue={"_id"}
                showSelectInDropdown={
                  fieldData?.data?.length > 0 ? false : true
                }
                onSelectChange={(e) => {
                  onSelectChange(e, fieldData);
                }}
                defaultValue={
                  allAssetsData?.[checkedAssetIds[0]]?.[fieldData?.type]
                }
              />
            </div>
          );
        })}
        <div className="editFieldsData">
          <ContributePopSectionHeader
            sectionHeading={t("creditLine")}
            isRequired={false}
            id={t("creditLine")}
          />
          <ContributePopTextfieldComp
            placeholder={t("enter")}
            onChange={(e) => {
              dispatch(
                changeAssetsFinalData({
                  ...allAssetsData,
                  [checkedAssetIds[0]]: {
                    ...allAssetsData[checkedAssetIds[0]],
                    credit_line: e.target.value,
                  },
                }),
              );
            }}
            value={allAssetsData?.[checkedAssetIds[0]]?.credit_line || ""}
          />
        </div>
        <div className="editFieldsData descriptionSection">
          <div className="descriptionField">
            <ContributePopSectionHeader
              sectionHeading={t("description")}
              isRequired={false}
              id={t("description")}
            />
            {imageTypes.includes(fileExtension) && (
              <ButtonOutlineIcon
                iconComp={<AIGenerateIcon />}
                title={t("aiAssetDescription")}
                className={"shortOutlineButton"}
                onClick={() => {
                  setOpenPopup(true);
                }}
              />
            )}
          </div>
          <ContributePopTextAreaComp
            value={allAssetsData?.[checkedAssetIds[0]]?.ai_desc || ""}
            onChange={(e) => {
              dispatch(
                changeAssetsFinalData({
                  ...allAssetsData,
                  [checkedAssetIds[0]]: {
                    ...allAssetsData[checkedAssetIds[0]],
                    ai_desc: e.target.value,
                  },
                }),
              );
            }}
          />
        </div>
        {openPopup && (
          <AIDescriptionPopup
            assetId={checkedAssetIds[0]}
            setOpenPopup={setOpenPopup}
          />
        )}
      </div>
    </DetailStyle>
  );
};

export default MetaDataSection;
