import React from "react";

const DangerIcon = (props) => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 11.8349H8.00667M8 7.1682V9.83486M4.27473 14.5015H11.7253C12.9131 14.5015 13.5069 14.5015 13.8553 14.2517C14.1593 14.0338 14.3577 13.6982 14.4022 13.3268C14.4532 12.9012 14.167 12.3808 13.5945 11.3401L9.86927 4.56683C9.2598 3.45873 8.95507 2.90468 8.5524 2.72138C8.20147 2.56162 7.79853 2.56162 7.4476 2.72138C7.04493 2.90468 6.7402 3.45873 6.13076 4.56683L2.40547 11.3401C1.83306 12.3808 1.54685 12.9012 1.59785 13.3268C1.64234 13.6982 1.84077 14.0338 2.14473 14.2517C2.49307 14.5015 3.08695 14.5015 4.27473 14.5015Z"
        stroke="#FF5230"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DangerIcon;
