import React from "react";
import { t } from "i18next";
import ContributePopupHeader from "components/ContributePopup/ContributePopupHeader/ContributePopupHeader";
import ScheduleAssetBody from "components/ContributePopup/ContributePopupBody/components/ScheduleAssetBody/ScheduleAssetBody";
import MainButton, {
  buttonVariants,
  MainButtonWithChildren,
} from "components/Button/MainButton";
import ClockIcon from "components/Icons/ClockIcon/ClockIcon";
import { ContributePopupStyles } from "components/ContributePopup/contributePopupStyles";
import { PopupBodyStyles } from "components/ContributePopup/ContributePopupBody/ContributePopupBody";
import { PopupFooterStyles } from "components/ContributePopup/ContributePopupFooter/ContributePopupFooter";
import { saveButtonIds } from "./UploadSaveButton";
import { ClickAwayListener } from "@mui/material";

const SchedulePopup = ({
  setOpenSchedulePopup,
  scheduleDateTime,
  setScheduleDateTime,
  onButtonClick,
}) => {
  const handleClose = () => {
    setOpenSchedulePopup(false);
  };

  return (
    <ContributePopupStyles>
      <ClickAwayListener onClickAway={handleClose}>
        <div className="contributePopupMain scheduleAssetsPublish">
          <ContributePopupHeader
            handlePopupClose={handleClose}
            popupTitle={t("scheduleAssetPublish")}
            showBackButton={false}
            setShowBackButton={false}
          />
          <PopupBodyStyles>
            <div className="scheduleAssetMain">
              <ScheduleAssetBody setScheduleDateTime={setScheduleDateTime} />
            </div>
          </PopupBodyStyles>
          <PopupFooterStyles>
            <MainButton
              title={t("cancel")}
              variant={buttonVariants.grey}
              onClick={(e) => {
                e.preventDefault();
                handleClose(e);
              }}
              className={"popupCancelButton"}
            />
            <div className="createProjectBtn">
              <MainButtonWithChildren
                disabled={!scheduleDateTime || scheduleDateTime === ""}
                variant={buttonVariants.blue}
                onClick={() =>
                  onButtonClick({
                    id: saveButtonIds.draft,
                  })
                }
              >
                <ClockIcon />
                &nbsp;<p>{t("scheduleCollectionPublish")}</p>
              </MainButtonWithChildren>
            </div>
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

export default SchedulePopup;
