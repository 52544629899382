import React from "react";
import { ClickAwayListener } from "@mui/material";
import { styled } from "@mui/system";
// import CrossIcon from "components/Icons/CrossIcon/CrossIcon";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";

const ContributePopToolStyle = styled("div")(({ theme }) => ({
  position: "absolute",
  right: "calc(-100% - -48px)",
  background: theme.palette.whiteColor.main,
  width: "100%",
  minWidth: "312px",
  borderRadius: "8px",
  padding: "24px",
  maxWidth: "312px",
  top: "calc(50% - 50px)",
  ".contributePopToolMain": {
    " .contributePopToolHeader": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      marginBottom: "16px",
      h4: {
        margin: "0 ",
      },
    },
  },
}));

const ContributePopupTooltip = ({
  content,
  heading,
  setIsOpen,
  tooltipClass,
}) => {
  return (
    <ContributePopToolStyle className={tooltipClass}>
      <ClickAwayListener onClickAway={() => setIsOpen(false)}>
        <div className="contributePopToolMain">
          <div className="contributePopToolHeader">
            <HeadingTag variant={headingVariants.h4}>{heading}</HeadingTag>
            {/* <CrossIcon onClick={() => setIsOpen(false)} /> */}
          </div>
          <div className="contributePopToolBody">
            <TextTag variant={textVariants._14px.regular}>{content}</TextTag>
          </div>
        </div>
      </ClickAwayListener>
    </ContributePopToolStyle>
  );
};

export default ContributePopupTooltip;
