import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import {
  Box,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import Sorting from "utils/sorting";
import { getAllAssetCategoryApi } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategoryAction";
import { allAssetCategory } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";
import { getAllAssetSubCategories } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategoryAction";
import { allAssetSubCategories } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategorySlice";
import { CsvPopupStyle } from "pages/Admin-Pages/user-administration/Components/CsvPopup/CsvPopupStyle";
import { QuickThumbsSchema } from "utils/validation/validation-schemas/categorySchema";
import { adminAddQuickThumb } from "../features/quickThumbAction";

const AddcatSubCat = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const assetCategoryData = useSelector(allAssetCategory);
  const assetSubCatData = useSelector(allAssetSubCategories);

  useEffect(() => {
    dispatch(getAllAssetCategoryApi());
    dispatch(getAllAssetSubCategories());
  }, [dispatch]);

  const initialValues = {
    type: " ",
    category_id: " ",
    subcategory_id: " ",
  };

  const handleSubmit = (values) => {
    const body = {};
    if (values.type === "category") {
      body.id = values.category_id;
      body.type = "category";
    } else if (values.type === "subCategory") {
      body.id = values.subcategory_id;
      body.type = "sub-category";
    }
    dispatch(adminAddQuickThumb(body));
    setOpen(false);
  };

  const formik = useFormik({
    validationSchema: QuickThumbsSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  return (
    <DialogCommon
      fullwidth={true}
      open={open}
      handleClose={() => setOpen(false)}
      title={t("addCatSubCat")}
      maxWidth={"md"}
    >
      <CsvPopupStyle>
        <Box className="Csv_main addEditCategory">
          <Box className="upload_Csv commanCsvBlock">
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box className="request-common">
                  <div className="categoryImageName">
                    <p>{t("categorySubCategory")}</p>
                    <Box className="addNewDivSelect">
                      <FormControl
                        variant="outlined"
                        className={"formControl"}
                        size="small"
                      >
                        <Select
                          className="permission-role-select"
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldValue("category_id", " ");
                            formik.setFieldValue("subcategory_id", " ");
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values?.type}
                          name={"type"}
                          defaultValue={" "}
                        >
                          <MenuItem value={" "}>{t("select")}</MenuItem>
                          <MenuItem value={"category"}>
                            {t("category")}
                          </MenuItem>
                          <MenuItem value={"subCategory"}>
                            {t("subCategory")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <span className="errorClass">
                        {formik.errors.type &&
                          formik.touched.type &&
                          formik.errors.type}
                      </span>
                    </Box>
                  </div>
                  {formik?.values?.type.trim() ? (
                    formik?.values?.type === "category" ? (
                      <Box className="categoryImageName">
                        <p> {t("category")}</p>
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            className="permission-role-select"
                            name="category_id"
                            value={formik?.values?.category_id || " "}
                            onChange={formik.handleChange}
                            defaultValue={" "}
                          >
                            <MenuItem value={" "}>
                              {t("selectCategory")}
                            </MenuItem>
                            {Sorting(
                              assetCategoryData?.asset_type,
                              "name",
                            )?.map((val, index) => {
                              return (
                                val?.enabled && (
                                  <MenuItem key={index + 1} value={val?._id}>
                                    {val?.name}
                                  </MenuItem>
                                )
                              );
                            })}
                          </Select>
                        </FormControl>
                        <span className="errorClass">
                          {formik.errors.category_id &&
                            formik.touched.category_id &&
                            formik.errors.category_id}
                        </span>
                      </Box>
                    ) : (
                      <Box className="categoryImageName">
                        <p> {t("subCategory")}</p>
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            className="permission-role-select"
                            name="subcategory_id"
                            value={formik?.values?.subcategory_id || " "}
                            onChange={formik.handleChange}
                            defaultValue={" "}
                          >
                            <MenuItem value={" "}>
                              {t("selectSubCategory")}
                            </MenuItem>
                            {Sorting(
                              assetSubCatData?.AssetSubCategory,
                              "name",
                            )?.map((val, index) => {
                              const catname = val?.category_data?.[0]?.name;
                              return (
                                val?.enabled && (
                                  <MenuItem key={index + 1} value={val?._id}>
                                    {catname} - {val?.name}
                                  </MenuItem>
                                )
                              );
                            })}
                          </Select>
                        </FormControl>
                        <span className="errorClass">
                          {formik.errors.subcategory_id &&
                            formik.touched.subcategory_id &&
                            formik.errors.subcategory_id}
                        </span>
                      </Box>
                    )
                  ) : (
                    ""
                  )}
                </Box>
                <DialogActions className="popup-button-outer">
                  <StyledButton
                    variant={"contained"}
                    type="submit"
                    //   disabled={updateLoading}
                  >
                    {t("save")}
                  </StyledButton>
                  <CustomButton
                    variant={"contained"}
                    onClick={() => setOpen(false)}
                    //  disabled={updateLoading}
                  >
                    {t("cancel")}
                  </CustomButton>
                </DialogActions>
              </form>
            </FormikProvider>
          </Box>
        </Box>
      </CsvPopupStyle>
    </DialogCommon>
  );
};

export default AddcatSubCat;
