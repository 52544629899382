import React from "react";

const CopyIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path
        d="M4 4H3.6C3.03995 4 2.75992 4 2.54601 4.10899C2.35785 4.20486 2.20486 4.35785 2.10899 4.54601C2 4.75992 2 5.03995 2 5.6V8.4C2 8.96005 2 9.2401 2.10899 9.454C2.20486 9.64215 2.35785 9.79515 2.54601 9.891C2.75992 10 3.03995 10 3.6 10H6.4C6.96005 10 7.2401 10 7.454 9.891C7.64215 9.79515 7.79515 9.64215 7.891 9.454C8 9.2401 8 8.96005 8 8.4V8M5.6 8H8.4C8.96005 8 9.2401 8 9.454 7.891C9.64215 7.79515 9.79515 7.64215 9.891 7.454C10 7.2401 10 6.96005 10 6.4V3.6C10 3.03995 10 2.75992 9.891 2.54601C9.79515 2.35785 9.64215 2.20486 9.454 2.10899C9.2401 2 8.96005 2 8.4 2H5.6C5.03995 2 4.75992 2 4.54601 2.10899C4.35785 2.20486 4.20486 2.35785 4.10899 2.54601C4 2.75992 4 3.03994 4 3.6V6.4C4 6.96005 4 7.2401 4.10899 7.454C4.20486 7.64215 4.35785 7.79515 4.54601 7.891C4.75992 8 5.03995 8 5.6 8Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
