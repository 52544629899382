import { styled } from "@mui/system";
import React from "react";

export const headingVariants = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
};

const commonStyles = ({ theme }) => ({
  fontFamily: "poppins",
  color: theme.palette.blackColor.main,
  letterSpacing: "-0.01em",
});

const HeadingTag = (props) => {
  const { children, variant } = props;

  const HeadingStyles = styled(variant)(({ theme, variant }) => {
    return {
      ...commonStyles({ theme }),
      ...(variant === headingVariants.h1 && {
        fontWeight: "700",
        fontSize: "3.5rem",
        lineHeight: "4.48rem",
      }),
      ...(variant === headingVariants.h2 && {
        fontWeight: "500",
        fontSize: "2rem",
        lineHeight: "2.56rem",
      }),
      ...(variant === headingVariants.h3 && {
        fontWeight: "500",
        fontSize: "1.5rem",
        lineHeight: "1.92rem",
      }),
      ...(variant === headingVariants.h4 && {
        fontWeight: "500",
        fontSize: "1.25rem",
        lineHeight: "1.6rem",
      }),
      ...(variant === "default" && {
        fontWeight: "700",
        fontSize: "3.5rem",
        lineHeight: "4.48rem",
        letterSpacing: "-0.01em",
      }),
    };
  });
  return <HeadingStyles {...props}>{children}</HeadingStyles>;
};

export default HeadingTag;
