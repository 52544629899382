import { t } from "i18next";
import { styled } from "@mui/system";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import dayjs from "dayjs";

const FilePropertiesSectionStyles = styled("div")(({ theme }) => ({}));

const keysExcluded = ["UploadedOn", "UploadedBy"];

const FilePropertiesSection = ({metadata}) => {

 

 
  return (
    <FilePropertiesSectionStyles>
      <HeadingTag variant={headingVariants.h3}>
        {t("fileProperties")}
      </HeadingTag>
      <div className="fileProperties">
        <div className="fileSection">
          <div className="sectionHeading">
            <TextTag variant={textVariants._14px.medium}>
              {t("fileProperties")}
            </TextTag>
          </div>
          {metadata &&
            Object.entries(metadata)?.map((data, index) => {
              return (
                !keysExcluded?.includes(data[0]) && (
                  <div className="sectionRow" key={index}>
                    <TextTag
                      className="rowHeading"
                      variant={textVariants._14px.medium}
                    >
                      {data[0]}:
                    </TextTag>
                    <TextTag variant={textVariants._14px.regular}>
                      {data[1]}
                    </TextTag>
                  </div>
                )
              );
            })}
        </div>
        <div className="fileSection">
          <div className="sectionHeading">
            <TextTag variant={textVariants._14px.medium}>
              {t("uploadInfo")}
            </TextTag>
          </div>
          <div className="sectionRow">
            <TextTag className="rowHeading" variant={textVariants._14px.medium}>
              {t("uploadedOn")}:
            </TextTag>
            <TextTag variant={textVariants._14px.regular}>
              {dayjs(metadata?.UploadedOn).format("DD/MM/YYYY")}
            </TextTag>
          </div>
          <div className="sectionRow">
            <TextTag className="rowHeading" variant={textVariants._14px.medium}>
              {t("uploadedBy")}:
            </TextTag>
            <TextTag variant={textVariants._14px.regular}>
              {metadata?.UploadedBy}
            </TextTag>
          </div>
        </div>
      </div>
    </FilePropertiesSectionStyles>
  );
};

export default FilePropertiesSection;
