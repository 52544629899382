import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Box } from "@mui/material";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import CustomButton from "components/Button/CustomButton";
import CommonGrid from "components/Grid/CommonGrid";
import ChangeThumbnailTitlePopup from "./Components/ChangeThumbnailTitlePopup";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import {
  quickThumbData,
  selectQuickThumbnailData,
} from "./features/quickThumbSlice";
import {
  deleteQuickThumb,
  dragDropQuickThumb,
  getQuickThumb,
  getQuickThumbnailApi,
} from "./features/quickThumbAction";
import {
  AgViewColumn,
  hiddenAgGridColumn,
} from "utils/datatable/agTableOptions";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import AddcatSubCat from "./Components/AddcatSubCat";
import CommonDeletePopup from "pages/Components/CommonDeletePopup/CommonDeletePopup";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";

const QuickThumbs = () => {
  const gridRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data1 = useSelector(quickThumbData);
  const data = useSelector(selectQuickThumbnailData);

  const tableDataArray = data1.fields;

  const imageData = {
    image_base_url: data1?.image_base_url,
    image_category_sub_folder_path: data1?.image_category_sub_folder_path,
    image_container_name: data1?.image_container_name,
    image_sub_category_sub_folder_path:
      data1?.image_sub_category_sub_folder_path,
  };

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "rowDrag",
      headerName: "",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxDragColumn",
      maxWidth: "110",
      suppressNavigable: true,
      lockPosition: "left",
      foreverShow: true,
    },
    {
      field: "image",
      headerName: t("image"),
      headerClass: "fieldCenter",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellClass: "category_center largeThumb",
      headerComponentParams: {
        applied_filters_data: [],
      },
      minWidth: 380,
      cellRenderer: (params) => {
        const url = `${params?.data?.partial_image_url}/${params?.value}`;
        return (
          <span key={params?.value} className="center thumbImage dblock">
            <img
              src={!params?.value ? "./logos/assetCategoryDefault.png" : url}
              alt="categoryImage"
            />
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: t("name"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            id={params?.column?.colId}
          >
            {params?.displayName}{" "}
          </th>
        );
      },
      cellClass: "category_cell",
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "type",
      headerName: t("type"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            id={params?.column?.colId}
          >
            {params?.displayName}{" "}
          </th>
        );
      },
      cellClass: "category_cell",
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (params) => {
        return (
          <Grid container className="actionIcons">
            <TableDeleteIcon
              onClick={() => {
                gridRef.current.api.deselectAll();
                setCurrentRowData(params.data);
                setRowsToBeDeleted([params?.data?._id]);
                setOpenDelete(true);
              }}
            />
          </Grid>
        );
      },
    },
  ];

  const [selectedRows, setSelectedRows] = useState();
  const [openChangeTitle, setOpenChangeTitle] = useState(false);
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [currentRowData, setCurrentRowData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [rowData, setRowData] = useState(data);
  const [settingType, setSettingType] = useState();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dataState, setDataState] = useState({
    search: "",
    limit: 20,
    page: 1,
    sortOrder: "asc",
    sortBy: "display_index",
    column_name: "",
    column_values: "",
    latest_action: "",
    filtersQueryString: [],
  });

  useEffect(() => {
    dispatch(getQuickThumbnailApi());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getQuickThumb(dataState)).then((res) => {
      setRowsToBeDeleted(false);
      setSelectedRows(false);
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    const partial_image_url = `${imageData?.image_base_url}/${imageData?.image_container_name}`;
    setRowData(
      tableDataArray?.map((thumbData) => {
        const obj = {
          _id: thumbData?._id,
          type: thumbData?.type,
          partial_image_url,
        };

        if (thumbData?.category_data?.[0]) {
          obj.name = thumbData?.category_data?.[0]?.name;
          obj.image = thumbData?.category_data?.[0]?.category_image;
        }
        if (thumbData?.sub_category_data?.[0]) {
          obj.name = thumbData?.sub_category_data?.[0]?.name;
          obj.image = thumbData?.sub_category_data?.[0]?.sub_category_image;
        }
        return obj;
      }),
    );

    setIsLoading(false);
  }, [tableDataArray]);

  const onRowDragEnd = useCallback(
    (e) => {
      const dataBody = {
        body: {
          old_index:
            parseInt(e.node.id) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
          new_index:
            parseInt(e.node.rowIndex) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
        },
        dataState,
      };
      dispatch(dragDropQuickThumb(dataBody));
    },
    [dataState, dispatch],
  );

  const icons = useMemo(() => {
    return {
      rowDrag: `<img src ="./logos/menu.svg")} style="height: 46px; width: 26px;padding-right: 2px"/>`,
    };
  }, []);

  const handleDelete = () => {
    const body = {
      ids: currentRowData?._id ? [currentRowData?._id] : rowsToBeDeleted,
    };
    const dataBody = { body, dataState };

    const shouldPageChange = deletePageChangeChecker(
      data1?.currentPage,
      data1?.totalDocuments,
      rowsToBeDeleted?.length,
      data1?.limit,
    );

    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(deleteQuickThumb(dataBody)).then((res) => {
      if (res.error) {
        return;
      }
      setOpenDelete(false);
      setSelectedRows([]);
      if (shouldPageChange) setDataState(dataBody.dataState);
    });
  };

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const commonGridObj = {
    ref: gridRef,
    rowData: rowData?.length > 0 ? rowData : [],
    columnDefs: colDefs,
    onRowDragEnd,
    rowDragManaged: true,
    icons,
  };

  return (
    <FieldManagementStyle>
      <Box className={"titleButton"}>
        <PageTitle title={data?.title || t("noTitleAvailable")} />
        <Box className={"addNewDiv1"}>
          <CustomButton
            onClick={() => setOpenChangeTitle(true)}
            variant="outlined"
          >
            {t("changeSectionDetails")}
          </CustomButton>
        </Box>
      </Box>
      {openChangeTitle && (
        <ChangeThumbnailTitlePopup
          openChangeTitle={openChangeTitle}
          setOpenChangeTitle={setOpenChangeTitle}
          data={data}
        />
      )}
      <Grid container spacing={4} className={"userReq-table "}>
        <Grid item xs={12} className="bannerTable">
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType ">
                <Box className="leftSideInputs">
                  <Box className={"addNewDiv1"}>
                    <CustomButton
                      onClick={() => setOpen(true)}
                      variant="outlined"
                    >
                      {t("add")}
                    </CustomButton>
                  </Box>
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={setSelectedRows}
                  />
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              rowHeight={200}
              totalPages={data1?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Grid>
      </Grid>
      {open && <AddcatSubCat open={open} setOpen={setOpen} />}
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        setRowsToBeDeleted={setRowsToBeDeleted}
        handleDelete={handleDelete}
        multiDeleteText={t("deleteSelectedCategorySubCategory")}
        singleDeleteText={t("deleteSelectedCategorySubCategory")}
        gridRef={gridRef}
      />
    </FieldManagementStyle>
  );
};

export default QuickThumbs;
