import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import { Link } from "react-router-dom";
import AvatarComp from "components/AvatarComp/AvatarComp";
import HeaderNotificationMenu from "./components/HeaderNotificationMenu";
import {
  dataStateNotifications,
  isScrollingUp,
} from "Layout/features/layoutSlice";
import {
  deleteNotificationApi,
  getAllNotificationsApi,
  readAllNotificationsApi,
} from "Layout/features/layoutAction";
import TcdamBlueText from "components/Logos/TcdamBlueText";
import HeaderNewStyles from "./HeaderNewStyles";
import { routeConfigs } from "utils/routeConfig";
import HeaderHoverMenu from "./components/HeaderHoverMenu/HeaderHoverMenu";
import { getActiveAssetType } from "pages/Admin-Pages/asset-administration/AssetType/features/typeAction";
import { allActiveAssets } from "pages/Admin-Pages/asset-administration/AssetType/features/typeSlice";

export const headerMenuObj = {
  type: "type",
  collection: "collection",
  more: "more",
};

const HeaderNew = ({ setisOpenMenu, setOpenContributePopup }) => {
  const dispatch = useDispatch();
  const tickingRef = useRef(false);

  const { notifications } = useSelector(dataStateNotifications);
  const upScrolling = useSelector(isScrollingUp);
  const allTypes = useSelector(allActiveAssets)?.data;
  const notificationsDataArray = notifications?.notifications;
  const [notificationsMenu, setNotificationsMenu] = useState(null);
  const [headerFixed, setHeaderFixed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [activeTypeIndex, setActiveTypeIndex] = useState();
  const [filteredUnreadNotications, setFilteredUnreadNotifications] = useState(
    [],
  );

  useEffect(() => {
    dispatch(getActiveAssetType());
  }, [dispatch]);

  useEffect(() => {
    if (window.location.pathname.includes(routeConfigs.adminLayout)) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    dispatch(getAllNotificationsApi(controller.signal));
    return () => {
      controller.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    const filteredData = filterUnreadNotifications(notificationsDataArray);
    setFilteredUnreadNotifications(filteredData);
  }, [notificationsDataArray]);

  const handleScroll = useCallback(() => {
    if (!tickingRef.current) {
      window.requestAnimationFrame(() => {
        const position = window.scrollY;
        setHeaderFixed(position >= 100);
        tickingRef.current = false;
      });
      tickingRef.current = true;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const filterUnreadNotifications = (allNotifications) => {
    return allNotifications?.filter((notificationData) => {
      return !notificationData?.is_read;
    });
  };

  const handleAvatarClick = () => {
    setisOpenMenu(true);
  };

  function deleteNotification(id) {
    const body = { ids: [] };
    body.ids = id
      ? [id]
      : notificationsDataArray?.map((notificationData) => {
          return notificationData?._id;
        });
    dispatch(deleteNotificationApi(body));
  }

  function readAllNotifications() {
    //mark all notifications as read
    dispatch(readAllNotificationsApi());
  }

  return (
    <HeaderNewStyles
      className={`${isAdmin ? "adminHeader" : "homepageHeader"}  ${
        headerFixed ? "headerSticky" : ""
      } ${upScrolling ? "upScrolling" : ""}`}
    >
      <div className="headerMain">
        <Link to={routeConfigs.userHome} className="headerLogo">
          <TcdamBlueText />
        </Link>
        <div className="headerMiddle">
          <div className="headerCollections">
            <HeaderHoverMenu
              itemName={t("collections")}
              menuType={headerMenuObj?.collection}
            />
          </div>
          <div className="headerTypes">
            {allTypes?.map((headerTypeData, index) => {
              if (index < 3) {
                return (
                  <HeaderHoverMenu
                    key={index}
                    itemName={headerTypeData?.name}
                    headerTypeData={headerTypeData}
                    menuType={headerMenuObj?.type}
                    index={index}
                    setActiveTypeIndex={setActiveTypeIndex}
                  />
                );
              }
              return null;
            })}
            {allTypes?.length > 3 && (
              <HeaderHoverMenu
                itemName={t("more")}
                menuType={headerMenuObj?.more}
                allTypes={allTypes}
                headerTypeData={allTypes[activeTypeIndex]}
                activeTypeIndex={activeTypeIndex}
                setActiveTypeIndex={setActiveTypeIndex}
              />
            )}
          </div>
        </div>
        <div className="headerRight">
          {/* <div className="notificationButton">
            <div
              aria-haspopup="true"
              color="inherit"
              aria-controls="notifications-menu"
              onClick={(e) => {
                setNotificationsMenu(e.currentTarget);
              }}
              className="headerMenuButton"
              size="large"
            >
              <Badge
                badgeContent={
                  filteredUnreadNotications?.length > 0
                    ? filteredUnreadNotications?.length
                    : null
                }
                color="warning"
                overlap="rectangular"
              >
                <NotificationsNone className="headerIcon" />
              </Badge>
            </div>
          </div> */}
          <div className="contributeHeader">
            <HeaderHoverMenu
              itemName={t("contribute")}
              headerTypeData={{ noDropdown: true }}
              onClick={() => setOpenContributePopup(true)}
            />
          </div>
          <div className="headerUserAvatar">
            <AvatarComp
              extraClass={"headerAvatar"}
              onClick={handleAvatarClick}
            />
          </div>
        </div>
        <HeaderNotificationMenu
          notificationsMenu={notificationsMenu}
          setNotificationsMenu={setNotificationsMenu}
          notificationsDataArray={notificationsDataArray}
          deleteNotification={deleteNotification}
          filteredUnreadNotications={filteredUnreadNotications}
          readAllNotifications={readAllNotifications}
        />
      </div>
    </HeaderNewStyles>
  );
};

export default HeaderNew;
