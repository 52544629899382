import tinycolor from "tinycolor2";

const primary = "#041E42"; /*#041E42 / #0054b6- Current */
const secondary = "#FF5C93";
const borderColor = "#e1e1e1";
const white = "#ffffff";
const black = "#4A4A4A";
const darkBlack = "#000000";
const lightBlue = "#f6f7ff";
const dimGray = "#707070";
const error = "#e02b27";
const lightGray = "#dddddd";
const dimLightGray = "#fafafa";
const dimGray_Color = "#eaedf4";

const success = "#3CD4A0";
const info = "#3BAE86F9";

const lightenRate = 7.5;
const darkenRate = 15;

// const boxShadow = "0 0 0 #000, 0 0 12px 0px rgb(0 0 0 / 21%)";
// below line is causing error in field management page
// const boxShadow = "0 0 0 #000, 0 0 12px 0px rgb(0 0 0 / 21%)";

const boxShadow = "";

const transparent = "rgb(4 30 66 / 0%)";

// FrontEndPages Theme color
const blueColor = "#0056B8";
const primaryColor = "#0056B8";
// const secondaryColor = "#0056B8";
const blackColor = "#212A36";
const gray_800 = "#353D48";
const gray_700 = "#4E555E";
const gray_600 = "#676D75";
const gray_500 = "#81868C";
const gray_400 = "#BDC0C4";
const gray_300 = "#CCCED1";
const gray_250 = "#E9E9E9";
const gray_200 = "#F5F5F6";
const graySpaceCadet = "#172B4D";
const whiteColor = "#FFFFFF";
const darkBlueColor = "#0A4992";

// System
const successColor = "#21D184";
const errorColor = "#F04848";
const linkColor = "#3870FF";

const defaultTheme = {
  palette: {
    // DesignStyleTheme
    primaryColor: {
      main: primaryColor,
      light: tinycolor(primaryColor).lighten(primaryColor).toHexString(),
      dark: tinycolor(primaryColor).darken(primaryColor).toHexString(),
    },
    // secondaryColor: {
    //   main: secondaryColor,
    //   light: tinycolor(secondaryColor).lighten(secondaryColor).toHexString(),
    //   dark: tinycolor(secondaryColor).darken(secondaryColor).toHexString(),
    // },
    blackColor: {
      main: blackColor,
      light: tinycolor(blackColor).lighten(blackColor).toHexString(),
      dark: tinycolor(blackColor).darken(blackColor).toHexString(),
    },
    gray_800: {
      main: gray_800,
      light: tinycolor(gray_800).lighten(gray_800).toHexString(),
      dark: tinycolor(gray_800).darken(gray_800).toHexString(),
    },
    gray_700: {
      main: gray_700,
      light: tinycolor(gray_700).lighten(gray_700).toHexString(),
      dark: tinycolor(gray_700).darken(gray_700).toHexString(),
    },
    gray_600: {
      main: gray_600,
      light: tinycolor(gray_600).lighten(gray_600).toHexString(),
      dark: tinycolor(gray_600).darken(gray_600).toHexString(),
    },
    gray_500: {
      main: gray_500,
      light: tinycolor(gray_500).lighten(gray_500).toHexString(),
      dark: tinycolor(gray_500).darken(gray_500).toHexString(),
    },
    gray_400: {
      main: gray_400,
      light: tinycolor(gray_400).lighten(gray_400).toHexString(),
      dark: tinycolor(gray_400).darken(gray_400).toHexString(),
    },
    gray_300: {
      main: gray_300,
      light: tinycolor(gray_300).lighten(gray_300).toHexString(),
      dark: tinycolor(gray_300).darken(gray_300).toHexString(),
    },
    gray_250: {
      main: gray_250,
      light: tinycolor(gray_250).lighten(gray_250).toHexString(),
      dark: tinycolor(gray_250).darken(gray_250).toHexString(),
    },
    gray_200: {
      main: gray_200,
      light: tinycolor(gray_200).lighten(gray_200).toHexString(),
      dark: tinycolor(gray_200).darken(gray_200).toHexString(),
    },
    graySpaceCadet: {
      main: graySpaceCadet,
      light: tinycolor(graySpaceCadet).lighten(graySpaceCadet).toHexString(),
      dark: tinycolor(graySpaceCadet).darken(graySpaceCadet).toHexString(),
    },
    whiteColor: {
      main: whiteColor,
      light: tinycolor(whiteColor).lighten(whiteColor).toHexString(),
      dark: tinycolor(whiteColor).darken(whiteColor).toHexString(),
    },
    darkBlueColor: {
      main: darkBlueColor,
      light: tinycolor(darkBlueColor).lighten(darkBlueColor).toHexString(),
      dark: tinycolor(darkBlueColor).darken(darkBlueColor).toHexString(),
    },
    successColor: {
      main: successColor,
      light: tinycolor(successColor).lighten(successColor).toHexString(),
      dark: tinycolor(successColor).darken(successColor).toHexString(),
    },
    errorColor: {
      main: errorColor,
      light: tinycolor(errorColor).lighten(errorColor).toHexString(),
      dark: tinycolor(errorColor).darken(errorColor).toHexString(),
    },
    linkColor: {
      main: linkColor,
      light: tinycolor(linkColor).lighten(linkColor).toHexString(),
      dark: tinycolor(linkColor).darken(linkColor).toHexString(),
    },

    // DesignStyleTheme

    blue_Color: {
      main: blueColor,
      light: tinycolor(blueColor).lighten(blueColor).toHexString(),
      dark: tinycolor(blueColor).darken(blueColor).toHexString(),
    },

    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    darkBlack: {
      main: darkBlack,
      light: tinycolor(darkBlack).lighten(darkBlack).toHexString(),
      dark: tinycolor(darkBlack).darken(darkBlack).toHexString(),
      contrastText: "#FFFFFF",
    },
    dimGray_Color: {
      main: dimGray_Color,
      light: tinycolor(dimGray_Color).lighten(dimGray_Color).toHexString(),
      dark: tinycolor(dimGray_Color).darken(dimGray_Color).toHexString(),
      contrastText: "#FFFFFF",
    },
    dimLightGray: {
      main: dimLightGray,
      light: tinycolor(dimLightGray).lighten(lightenRate).toHexString(),
      dark: tinycolor(dimLightGray).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    lightGray: {
      main: lightGray,
      light: tinycolor(lightGray).lighten(lightenRate).toHexString(),
      dark: tinycolor(lightGray).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    dimGray: {
      main: dimGray,
      light: tinycolor(dimGray).lighten(lightenRate).toHexString(),
      dark: tinycolor(dimGray).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    error: {
      main: error,
      light: tinycolor(error).lighten(lightenRate).toHexString(),
      dark: tinycolor(error).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    borderColor: {
      main: borderColor,
      light: tinycolor(borderColor).lighten(lightenRate).toHexString(),
      dark: tinycolor(borderColor).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#fff",
      light: "#F3F5FF",
    },
    white: {
      main: white,
      light: tinycolor(white).lighten(lightenRate).toHexString(),
      dark: tinycolor(white).darken(darkenRate).toHexString(),
    },
    black: {
      main: black,
      light: tinycolor(black).lighten(lightenRate).toHexString(),
      dark: tinycolor(black).darken(darkenRate).toHexString(),
    },
    lightBlue: {
      main: lightBlue,
      light: tinycolor(lightBlue).lighten(lightBlue).toHexString(),
      dark: tinycolor(lightBlue).darken(lightBlue).toHexString(),
    },
    boxShadow: {
      main: boxShadow,
      light: tinycolor(boxShadow).lighten(boxShadow).toHexString(),
      dark: tinycolor(boxShadow).darken(boxShadow).toHexString(),
    },
    transparent: {
      main: transparent,
      light: tinycolor(transparent).lighten(transparent).toHexString(),
      dark: tinycolor(transparent).darken(transparent).toHexString(),
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },

  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
        paddingLeft: 24,
        "&:has(.datatables-noprint)": {
          textAlign: "-webkit-center",
        },
        paddingTop: 0,
        paddingBottom: 0,
      },

      body: {
        fontSize: "0.95rem",
      },
    },
    MuiTablePagination: {
      root: {
        "& button#pagination-back": {
          border: "1px solid #ddd",
          padding: " 5px",
        },
        "& button#pagination-next": {
          border: "1px solid #ddd",
          padding: "5px",
          marginLeft: "8px",
        },
      },
    },
    MuiPaper: {
      root: {
        "&:has(.popup-main)": {
          borderRadius: "0px",
        },
      },
    },
    MUIDataTableHeadCell: {
      root: {
        fontWeight: 700,
        fontSize: "16px",
      },
      sortAction: {
        fontWeight: 700,
        fontSize: "17px",
        display: "flex",
        alignItems: "center",
        "& svg": {
          color: "#ffffff !important",
        },
      },
      sortActive: {
        color: "#ffffff",
      },
      data: {
        color: "#ffffff",
      },
    },
    MuiTableSortLabel: {
      root: {
        width: "1rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10,
      },
    },
    MUIDataTableBodyCell: {
      root: {
        // maxWidth: "150px",
        // "& input[type='text']": {
        // }
      },
    },
    MUIDataTablePagination: {
      root: {},
    },
    MuiSwitch: {
      root: {
        width: "50px",
        height: "23px",
        padding: "0px",
      },
      switchBase: {
        color: "#818181",
        padding: "1px",
        "&$checked": {
          "& + $track": {
            backgroundColor: "#37cb90",
            "&:after": {
              content: "'ON'",
              left: "6px",
            },
          },
        },
      },
      thumb: {
        color: "white",
        width: "20px",
        height: "20px",
        margin: "1px",
      },
      track: {
        borderRadius: "15px",
        backgroundColor: "#707070",
        opacity: "1 !important",
        "&:after, &:before": {
          color: "white",
          fontSize: "11px",
          position: "absolute",
          top: "6px",
          fontWeight: "600",
        },
        "&:before": {
          right: "6px",
        },
      },
    },
  },
};

export default defaultTheme;
