import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Box, Grid } from "@mui/material";
import {
  deleteVideoApi,
  getVideoColumnField,
  getVideoContentApi,
  getVideoModuleData,
  updateVideoStatusApi,
} from "./features/videoModuleAction";
import {
  getVideoModule,
  videoModuleFieldValues,
} from "./features/videoModuleSlice";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import TableViewIcon from "components/Grid/TableComponents/TableIcons/TableViewIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import ToggleSwitch from "pages/Components/ToggleSwitch/ToggleSwitch";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import CustomButton from "components/Button/CustomButton";
import ChangeTitlePopUp from "./Components/ChangeTitlePopup/ChangeTitlePopUp";
import CustomComponentFilter, {
  getFieldsDataHelper,
  ShowHeaderIcon,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import CommonDeletePopup from "pages/Components/CommonDeletePopup/CommonDeletePopup";
import AddVideoPopUp from "./Components/AddVideoPopUp/AddVideoPopUp";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";

const VideoModule = () => {
  const dispatch = useDispatch();
  const { videoModuleData, videoContent, deleteLoading } =
    useSelector(getVideoModule);
  const { t } = useTranslation();
  const gridRef = useRef();
  const fieldValueData = useSelector(videoModuleFieldValues)?.video_data; //filter

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "name",
      headerName: t("videoName"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole statusColumn"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "category_cell",
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "author",
      headerName: t("author"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "user_data.first_name",
          dispatchName: "user_data.first_name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole statusColumn"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"user_data.first_name"}
                sortBy={"user_data.first_name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "category_cell",
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "createdAt",
          dispatchName: "createdAt",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole statusColumn"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"createdAt"}
                sortBy={"createdAt"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "category_cell",
      cellRenderer: ({ data }) =>
        ToolTipWrapperComponent(dayjs(data?.createdAt).format("DD-MM-YYYY")),
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole statusColumn"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                setCurrentRowData(props?.data);
                handleSwitchChange(props);
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (params) => {
        const url = `${params?.data?.partial_image_url}/${params?.data?.url}`;
        return (
          <Grid container className="actionIcons">
            <TableViewIcon
              onClick={() => {
                window.open(url, "_blank");
              }}
            />
            <TableDeleteIcon
              onClick={() => {
                gridRef.current.api.deselectAll();
                setCurrentRowData(params?.data);
                setOpenDelete(true);
                setRowsToBeDeleted([params?.data?._id]);
              }}
            />
          </Grid>
        );
      },
    },
  ];

  const [rowData, setRowData] = useState([]);
  const [openChangeTitle, setOpenChangeTitle] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [settingType, setSettingType] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [openDelete, setOpenDelete] = useState(false);
  const [currentRowData, setCurrentRowData] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter

  const [dataState, setDataState] = useState({
    search: "",
    limit: 20,
    page: 1,
    sortOrder: "desc",
    sortBy: "createdAt",
    column_name: "",
    column_values: "",
    latest_action: "",
    filtersQueryString: [],
  });

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    const partial_image_url = `${videoModuleData?.image_base_url}/${videoModuleData?.image_container_name}`;

    if (videoModuleData?.data) {
      setRowData(
        videoModuleData?.data?.map((tableDataObj) => ({
          _id: tableDataObj?._id,
          enabled: tableDataObj?.enabled,
          name: tableDataObj?.name,
          url:
            tableDataObj?.type === "published"
              ? tableDataObj?.asset_data?.[0]?.file_slug
              : tableDataObj?.file_slug,
          fileName: tableDataObj?.file_name,
          partial_image_url,
          author: `${tableDataObj?.user_data?.[0]?.first_name} ${tableDataObj?.user_data?.[0]?.last_name}`,
          createdAt:
            tableDataObj?.type === "published"
              ? tableDataObj?.asset_data?.[0]?.createdAt
              : tableDataObj?.createdAt,
        })),
      );
    } else {
      setRowData([]);
    }
  }, [videoModuleData]);

  useEffect(() => {
    dispatch(getVideoModuleData(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res,
        setSettingType,
        colDefs,
        setColDefs,
        dataState,
      };
      agSetColumnIndexes(paramsObj);
    });
    dispatch(getVideoContentApi()).then((res) => {
      if (res?.error) {
        return;
      }
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  const handleSwitchChange = useCallback(async (props) => {
    gridRef.current.api.forEachNode((rowNode) => {
      if (rowNode?.data?._id !== props?.data?._id) {
        rowNode.setDataValue("enabled", false);
        return false;
      }
      //updating the ui
      //by toggling switchvalue
      rowNode.setDataValue("enabled", !props?.value);

      const body = {
        id: props?.data?._id,
        enabled: !props?.value,
      };

      let modifiedDataState = dataState;

      if (props?.data?.other_table_data) {
        const other_data = props?.data?.other_table_data;
        modifiedDataState = modifyDataState(other_data);
      }

      dispatch(updateVideoStatusApi(body)).then((resp) => {
        if (resp.error) {
          //reverting the change in case of api error
          dispatch(getVideoModuleData(modifiedDataState));
          return "error";
        }
      });
    });
    //eslint-disable-next-line
  }, []);

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function handleDelete() {
    const body = {
      ids:
        rowsToBeDeleted?.length > 0 ? rowsToBeDeleted : [currentRowData?._id],
    };

    dispatch(deleteVideoApi(body)).then((res) => {
      if (res?.error) {
        return;
      }
      dispatch(getVideoModuleData(dataState));
    });

    setOpenDelete(false);
    setRowsToBeDeleted([]);
    setSelectedRows([]);
    setCurrentRowData();
    gridRef.current.api.deselectAll();
  }

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getVideoColumnField,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  return (
    <FieldManagementStyle>
      <Box className={"titleButton"}>
        <PageTitle title={videoContent?.video_content?.name} />
        <Box className={"addNewDiv1"}>
          <CustomButton
            onClick={() => {
              //    setPopUpMode(t("changeSectionTitle"));
              setOpenChangeTitle(true);
            }}
            variant="outlined"
          >
            {t("changeSectionDetails")}
          </CustomButton>
        </Box>
      </Box>
      <Box className="tabsLine" />
      <Grid container spacing={4} className={"userReq-table "}>
        <Grid item xs={12} className="bannerTable">
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType ">
                <Box className="leftSideInputs">
                  <Grid container spacing={4} className={`addNew`}>
                    <Box className={"addNewDiv1"}>
                      <CustomButton
                        onClick={() => setAddOpen(true)}
                        variant="outlined"
                      >
                        {t("add")}
                      </CustomButton>
                    </Box>
                  </Grid>
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={setSelectedRows}
                  />
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              //    isLoading={dataLoading}
              totalPages={videoModuleData?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={videoModuleData?.currentPage}
            />
          </Box>
        </Grid>
      </Grid>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("videoMultipleDeleteConfirmationText")}
        singleDeleteText={t("videoDeleteConfirmationText")}
        loading={deleteLoading}
        gridRef={gridRef}
      />
      {addOpen && (
        <AddVideoPopUp
          addOpen={addOpen}
          setAddOpen={setAddOpen}
          dataState={dataState}
        />
      )}
      {openChangeTitle && (
        <ChangeTitlePopUp
          openChangeTitle={openChangeTitle}
          setOpenChangeTitle={setOpenChangeTitle}
          videoContent={videoContent?.video_content}
          dataState={dataState}
        />
      )}
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
};

export default VideoModule;
