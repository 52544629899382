import { styled } from "@mui/system";

export const FooterStyle = styled("div")(({ theme }) => ({
  zIndex: "0",
  position: "relative",
  backgroundColor: theme.palette.white.main,
  borderTop: "1px solid rgba(233, 233, 233, 1)",

  ".footerUpper": {
    fontFamily: "Poppins",
    display: "flex",
    padding: "64px 64px 0",
    maxWidth: "1440px",
    margin: "auto",
    gap: "15px",
    "@media only screen and (width >= 1920px)": {
      // maxWidth: "calc(1920px - 128px)",
    },
    "@media screen and (max-width:1280px)": {
      // padding: "64px 15px 0",
    },
    "@media screen and (max-width:1024px)": {
      flexDirection: "column",
    },
    "@media screen and (max-width:992px)": {
      padding: "64px 15px 0",
    },
    "> .upperSectionInner ": {
      paddingLeft: "0",
      ".footerTcdamLogo": {
        marginBottom: "40px",
      },

      "@media screen and (max-width:1280px)": {
        paddingLeft: "0",
      },
    },
    " .upperSectionInner": {
      flex: "0 0 100%",
      maxWidth: "calc(100% / 6)",
      padding: "0 0px",
      "@media screen and (max-width:1024px)": {
        maxWidth: "100%",
      },
      ".footerTcdamLogo": { img: { width: "100%", maxWidth: "110px" } },
    },
    ".footerLinks": {
      flex: 1,
      display: "flex",
      justifyContent: "flex-end",
      gap: "15px",
      " .upperSectionInner": {
        maxWidth: "calc(100% / 5)",
        "&:last-child": {
          maxWidth: "max-content",
        },
        "@media screen and (max-width:1024px)": {
          maxWidth: "calc(100% / 3)",
        },

        "@media screen and (width < 767px)": {
          maxWidth: "100%",
        },
        "&:nth-of-type(-n+3)": {
          marginBottom: "25px",
        },
      },
      "@media screen and (max-width:1024px)": {
        justifyContent: "flex-start",
        marginTop: "50px",
        flexWrap: "wrap",
      },
    },
    ".upperSectionHeading": {
      marginBottom: "24px",
      color: theme.palette.blackColor.main,
      textTransform: "capitalize",
      span: {
        wordWrap: "break-word",
      },
    },
    ".upperSectionLinks": {
      a: {
        display: "block",
        textDecoration: "none",
        color: theme.palette.blackColor.main,
        span: {
          wordBreak: "break-word",
        },

        "&:not(:last-child)": { marginBottom: "12px" },

        "@media screen and (max-width:1280px)": {
          fontSize: "13px",
        },
        "&:hover": {
          color: theme.palette.primaryColor.main,
        },
      },
    },
    ".upperSectionInner": {
      ".footerMatrics": {
        ".footerMatricsSection": {
          ".footerMatricsHeading": {
            color: "#676D75",
            "@media screen and (max-width:1280px)": {
              fontSize: "13px",
            },
          },
          ".footerMatricsFigure": {
            h3: {
              margin: "8px 0 12px 0",
            },
          },
          "&:first-of-type": {
            marginBottom: "12px",
          },
        },
      },
    },
  },
  ".bottomfooterLower": {
    borderTop: "1px solid #F5F5F6",
    marginTop: "64px",
    ".footerLower": {
      fontFamily: "Poppins",
      display: "flex",
      justifyContent: "space-between",
      padding: "24px 64px",
      maxWidth: "1440px",
      margin: "auto",
      "@media only screen and (width >= 1920px)": {
        // maxWidth: "calc(1920px - 128px)",
      },

      "@media screen and (max-width:1280px)": {
        // padding: "24px 15px",
      },
      "@media screen and (max-width:992px)": {
        padding: "24px 15px",
        flexDirection: "column",
      },

      ".lowerLeftSection": {
        span: {
          display: "block",
          maxWidth: "304px",
          color: theme.palette.gray_500.main,
        },
      },
      ".lowerRightSection": {
        display: "flex",
        gap: "15px",
        a: {
          color: theme.palette.gray_700.main,
          textDecoration: "none",
          "&:hover": {
            color: theme.palette.primaryColor.main,
          },
        },
      },
    },
  },
}));
