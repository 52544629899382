import { styled } from "@mui/system";

const UploadScreenStyle = styled("div")(({ theme, filesLength }) => ({
  minHeight: "calc(100vh - 80px)",
  padding: "0 64px 64px",
  ".mainBlock": {
    width: "100%",
    // marginTop: "80px",
    paddingTop: "48px",
    ".uploadScreen": {
      maxWidth: "100%",
      margin: "auto",
      width: "100%",
      padding: "0px",
      display: "flex",
      justifyContent: "space-between",
      gap: "15px",
      "@media screen and (width >= 1920px)": {
        maxWidth: "calc(1920px - 128px)",
      },

      ".leftUploadSection": {
        flex: "0 0 48%",
        maxWidth: "calc(50% - 32px)",
        ".uploadAssetHeading": {
          display: "flex",
          // alignItems: "center",
          gap: "8px",
          h3: {
            color: theme.palette.blackColor.main,
            marginTop: "0px",
            marginBottom: "16px",
          },
          ".assetNum": {
            marginTop: "2px",
            width: "32px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `1px solid ${theme.palette.gray_300.main}`,
            borderRadius: "30px",
            span: {
              color: theme.palette.gray_600.main,
            },
          },
        },
        ".uploadAssetButton": {
          marginBottom: "33px",
        },

        ".fileUploadBox": {
          display: "flex",
          flexWrap: "wrap",
          width: "100%",
          ".mainScroll": {
            width: "100%",
          },
          ".displayFileBox": {
            "&.uploadDropFiles": {
              height: "24.167vw",
              maxHeight: "464px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `2px dashed${theme.palette.gray_300.main}`,
              borderRadius: "8px",
              backgroundColor: theme.palette.gray_200.main,
              minHeight: "350px",
              ".skeletonImage": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "24px",
                width: "100%",
                // height: "100%",
                ".uploadIcong": {
                  svg: {
                    height: "140px",
                    width: "140px",
                    path: { stroke: theme.palette.gray_400.main },
                  },
                },
                h4: {
                  margin: "0",
                },
              },
            },
          },
          "&.afterUploadAssets": {
            maxHeight: "calc(100vh - 350px)",
            overflowX: "scroll",
            paddingLeft: "10px",
            marginLeft: "-15px",
            direction: "rtl",
            "::-webkit-scrollbar-thumb": {
              background: theme.palette.gray_250.main,
              cursor: "pointer",
            },
            "::-webkit-scrollbar": {
              width: "4px",
            },
            ".mainScroll": {
              width: "100%",
              gap: "16px",
              display: "flex",
              flexWrap: "wrap",
              direction: "ltr",
            },
            ".uploadDropFiles": {
              height: "auto",
              minHeight: "unset",
              ".skeletonImage": {
                gap: "8px",
                padding: "16px",
                ".uploadIcong": {
                  svg: {
                    width: "24px",
                    height: "24px",
                  },
                },
                h4: {
                  fontSize: "12px",
                  minHeight: "36px",
                  maxWidth: "114px",
                  textAlign: "center",
                  lineHeight: "18px",
                },
                button: {
                  padding: "7px 8px",
                  height: "32px",
                  fontSize: "12px!important",
                },
              },
            },
            ".displayFileBox": {
              flex: "0 0 33%",
              maxWidth: "calc(100% / 3 - 11px)",
              position: "relative",
              "@media screen and (width <= 1366px)": {
                flex: "0 0 50%",
                maxWidth: "calc(100% / 2 - 11px)",
              },
              "&:not(.uploadDropFiles)": {
                border: `1px solid ${theme.palette.gray_200.main}`,
                borderRadius: "8px",
                position: "relative",
                overflow: "hidden",
                // height: "9.722vw",
                // overflow: "hidden",
                // "&:hover": {
                //   "&:after": {
                //     opacity: '1',
                //     zIndex: '0',
                //     background: 'linear-gradient(0deg, rgb(16 20 25 / 80%) 0%, rgb(0 0 0 / 9%) 100%)'
                //   }
                // }
                ".failed-contribution-uploads": {
                  // minHeight: '9.722vw',
                  height: "9.722vw",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  padding: "16px",
                  maxHeight: "192px",
                  ".assetNotUploadedTop": {
                    textAlign: "center",
                    marginBottom: "8px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    justifyContent: "center",
                  },
                  span: {
                    textAlign: "center",
                    "&.failed-asset-message": {
                      color: theme.palette.errorColor.main,
                    },
                    "&.failed-asset-name": {
                      marginBottom: "1.014vw",
                      color: theme.palette.blackColor.main,
                      wordBreak: "break-word",
                    },
                  },
                  button: {
                    "&.remove-asset-btn": {
                      width: "100%",
                      maxWidth: "100%",
                      padding: "0",
                      minHeight: "32px",
                      backgroundColor: theme.palette.errorColor.main,
                      borderColor: theme.palette.errorColor.main,
                      justifyContent: "center",
                      span: {
                        svg: {
                          path: {
                            stroke: theme.palette.whiteColor.main,
                          },
                        },
                        color: theme.palette.whiteColor.main,
                      },
                    },
                  },
                },
                "&:has(.failed-contribution-uploads)": {
                  borderColor: theme.palette.errorColor.main,
                },
                "&.selected": {
                  borderColor: theme.palette.primaryColor.main,
                },
              },
              ".afterUploadImages": {
                position: "relative",
                "input[type = checkbox]": {
                  position: "absolute",
                  top: "16px",
                  left: "16px",
                  zIndex: "1",
                },
                ".uploadImg": {
                  position: "relative",
                  overflow: "hidden",
                  cursor: "pointer",
                  marginBottom: "1px", // to stop flickering on hover
                  "@media screen and (width <= 1366px)": {
                    height: "10.514vw",
                    maxHeight: "140px",
                  },
                  ".uploadAImage": {
                    paddingTop: "53%" /* before it was 60%*/,
                    position: "relative",
                    img: {
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "top",
                      // maxHeight: '150px',
                      position: "absolute",
                      top: "0",
                    },
                  },
                  ".assetHoverSection": {
                    position: "absolute",
                    bottom: "-100%",
                    padding: "0 17px",
                    transition: "bottom 0.2s linear",
                    zIndex: "1",
                    span: {
                      color: theme.palette.whiteColor.main,
                      wordBreak: "break-word",
                    },
                  },

                  "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: "0",
                    left: "0",
                    background:
                      "linear-gradient(0deg, rgba(0,0,0,0.4682247899159664) 0%, rgba(0,0,0,0) 49%)",
                    zIndex: "0",
                    WebkitTransition: "all 0.2s linear",
                    transition: "all 0.2s linear",
                    // borderRadius: '10px'
                  },
                  "&:hover": {
                    ".assetHoverSection": {
                      bottom: "16px",
                    },
                  },
                },
              },
              ".assetLowerSection": {
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px",
                gap: "16px",
                alignItems: "center",
                position: "relative",
                ".assetLowerLeft": {
                  display: "flex",
                  gap: "16px",
                  svg: {
                    path: {
                      stroke: theme.palette.gray_600.main,
                    },
                    "&:hover": {
                      path: { stroke: theme.palette.primaryColor.main },
                    },
                    "&.disabled": {
                      path: {
                        stroke: theme.palette.gray_400.main,
                      },
                    },
                  },
                },
                svg: {
                  minWidth: "16px",
                  minHeight: "16px",
                },
              },
            },
          },
        },
      },

      ".rightUploadSection": {
        flex: "0 0 58%",
        maxWidth: "calc(50% - 32px)",
        position: "relative",
        zIndex: "1",
        ".assetsTAbsData": {
          height: "27.604vw",
          maxHeight: "530px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: `1px solid ${theme.palette.gray_200.main}`,
          borderRadius: "8px",
          "&.startUploadingFiles": {
            ".innerAssetsDataBlock": {
              width: "100%",
              maxWidth: "390px",
              textAlign: "center",
            },
            span: {
              "&.noAssetsUploaded": {
                color: theme.palette.gray_600.main,
                fontSize: "16px",
                fontWeight: "400",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "25px",
                lineHeight: "24px",
              },
            },
          },
          "&.showSelectedData": {
            display: "block",
            border: "0px solid red",
            height: "auto",
            maxHeight: "calc(100vh - 280px)",
            overflowY: "scroll",
            marginRight: "-15px",
            paddingRight: "10px",
            borderRadius: "0px",
            "::-webkit-scrollbar-thumb": {
              background: theme.palette.gray_250.main,
              cursor: "pointer",
            },
            "::-webkit-scrollbar": {
              width: "4px",
            },
            ".innerAssetsDataBlock": {
              width: "100%",
              maxWidth: "100%",
              textAlign: "left",
              h3: {
                color: theme.palette.blackColor.main,
              },
              ".uploadScreen ": {
                flexDirection: "column",
                ".titleAndButton": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  ".headingButtons": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "16px",
                    button: {
                      maxHeight: "24px",
                    },
                  },
                },
                ".keyWordLeftSec": {
                  " .appliedTagsTop": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    color: theme.palette.blackColor.main,
                    ".clearTags": {
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                    },
                    ".numberText": {
                      color: theme.palette.gray_600.main,
                    },
                  },
                  ".keyWordWordsClass": {
                    border: "1px solid #E9E9E9",
                    padding: "16px",
                    borderRadius: "8px",
                    marginTop: "16px",
                    ul: {
                      margin: "10px 0 0",
                      li: {
                        padding: "2px 16px",
                      },
                    },
                  },
                },
              },
              ".fieldsEditMain": {
                button: {
                  "&.shortOutlineButton": {
                    "&:hover": {
                      span: {
                        color: theme.palette.primaryColor.main,
                      },
                    },
                  },
                },
                ".editFieldsData": {
                  span: {
                    color: theme.palette.gray_800.main,
                  },
                  ".descriptionField": {
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                    button: {
                      padding: "8px 16px",
                      maxHeight: "24px",
                      span: {
                        maxWidth: "max-content",
                        fontSize: "12px",
                      },
                    },
                  },
                },
              },
              ".fileProperties": {
                ".fileSection": {
                  ".sectionHeading": {
                    marginBottom: "16px",
                    span: {
                      fontSize: "14px",
                      fontWeight: "500",
                      color: theme.palette.blackColor.main,
                    },
                  },
                  ".sectionRow": {
                    display: "flex",
                    marginBottom: "8px",
                    lineHeight: "30px",
                    gap: "15px",
                    color: theme.palette.gray_800.main,
                    ".rowHeading": {
                      color: theme.palette.gray_600.main,
                      width: "16%",
                    },
                  },
                  "&:not(:last-child)": {
                    marginBottom: "32px",
                  },
                },
              },
            },
          },
        },
      },
    },
  },

  ".uploadScreenFooter": {
    position: "fixed",
    zIndex: "9",
    bottom: "0",
    width: "100%",
    left: "0",
    height: "5rem",
    padding: "8px 40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.whiteColor.main,

    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07)",
    ".uploadFooterLeft ": {
      display: "flex",
      alignItems: "center",
      gap: "32px",
      button: {
        cursor: "pointer",
        background: "transparent",
        border: "0",
        display: "flex",
        alignItems: "center",
        gap: "8px",
        span: {
          fontWeight: "400",
        },
        "&.addToAlbum": {
          color: theme.palette.gray_600.main,
          svg: { path: { stroke: theme.palette.gray_600.main } },
          "&:hover,&.addToAlbumPopper": {
            color: theme.palette.primaryColor.main,
            svg: { path: { stroke: theme.palette.primaryColor.main } },
          },
        },
        "&.deleteButton": {
          span: {
            color: theme.palette.errorColor.main,
          },
          svg: {
            path: {
              stroke: theme.palette.errorColor.main,
            },
          },
        },
        "&:disabled": {
          color: theme.palette.gray_300.main,
          span: {
            color: theme.palette.gray_300.main,
          },
          svg: {
            path: {
              stroke: theme.palette.gray_300.main,
            },
          },
        },
      },
    },
    ".uploadFooterRight": {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      ".contributeScreenSave": {
        maxWidth: "237px",
      },
      button: {
        height: "32px",
      },
    },
    "+ .MuiPopper-root": {
      zIndex: "99",
    },
  },

  // ".displayFileBox": {
  //   width: "300px",
  //   border: "1px solid black",
  //   borderRadius: "5px",
  //   margin: "2px",
  //   padding: "5px",
  // },
  // ".uploadImg": {
  //   "& img": {
  //     width: "252px",
  //     height: "193px",
  //     margin: "5px",
  //     objectFit: "contain",
  //   },
  // },

  ".assetLowerRight": {
    div: {
      width: "12px",
      height: "12px",
      border: "1.5px solid",
      borderRadius: "50%",
      "&.greenProgressCircle": {
        borderColor: "green",
      },
      "&.blueProgressCircle": {
        borderColor: "blue",
      },
    },
  },
}));

export default UploadScreenStyle;
