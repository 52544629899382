import { createSlice } from "@reduxjs/toolkit";
import {
  adminAddQuickThumb,
  deleteQuickThumb,
  getQuickThumb,
  getQuickThumbnailApi,
  updateQuickThumbnailApi,
} from "./quickThumbAction";

const initialState = {
  quickThumbnail: {},
  loading: false,
  error: null,
  quickThumbData: [],
};

const thumbnailSlice = createSlice({
  name: "thumbnailModule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle getQuickThumbnailApi actions
    builder
      .addCase(getQuickThumbnailApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getQuickThumbnailApi.fulfilled, (state, action) => {
        state.loading = false;
        state.quickThumbnail = action.payload.data;
        state.error = null;
      })
      .addCase(getQuickThumbnailApi.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.error || "Failed to fetch quick thumbnail";
      });

    // Handle updateQuickThumbnailApi actions
    builder
      .addCase(updateQuickThumbnailApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateQuickThumbnailApi.fulfilled, (state, action) => {
        state.loading = false;
        state.quickThumbnail = action.payload;
        state.error = null;
      })
      .addCase(updateQuickThumbnailApi.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.error || "Failed to update quick thumbnail";
      });

    // Handle adminAddQuickThumb actions
    builder
      .addCase(adminAddQuickThumb.pending, (state) => {
        state.loading = true;
      })
      .addCase(adminAddQuickThumb.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(adminAddQuickThumb.rejected, (state) => {
        state.loading = false;
      });

    //getQuickThumb
    builder
      .addCase(getQuickThumb.pending, (state) => {
        state.loading = true;
      })
      .addCase(getQuickThumb.fulfilled, (state, action) => {
        state.quickThumbData = action.payload;
        state.loading = false;
      })
      .addCase(getQuickThumb.rejected, (state) => {
        state.loading = false;
      });
    //deleteQuickThumb
    builder
      .addCase(deleteQuickThumb.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteQuickThumb.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteQuickThumb.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Selectors

export const selectQuickThumbnailData = (state) =>
  state?.thumbnailModule?.quickThumbnail;

export const quickThumbData = (state) => state?.thumbnailModule?.quickThumbData;

export default thumbnailSlice.reducer;
